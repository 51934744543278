import { Button } from "../../../../../components/ui/button";
import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";

export default function CompleteOrder({ orderId }: { orderId: string }) {
  const { toast } = useToast();
  const utils = trpc.useUtils();
  const { mutate: markAsShipped } = trpc.order.markAsShipped.useMutation({
    onError(error) {
      toast({
        description: error.message,
        title: "Fill and finish completion error",
        variant: "destructive",
      });
    },
    onSuccess() {
      toast({
        title: "Fill and finish completed",
        variant: "success",
      });
      utils.order.read.invalidate(orderId);
    },
  });

  const handleCompleteOrder = () => {
    markAsShipped(orderId);
  };

  return (
    <Button onClick={handleCompleteOrder} variant={"outline"}>
      Mark as shipped
    </Button>
  );
}
