import {
  WellErrorSeverity,
  WellErrorType,
  Normalization,
  ModificationStatusToLabel,
  OligoStatus,
} from "@console/shared";
import {
  AlertTriangle,
  Ban,
  LucideCircleSlash2,
  Trash2Icon,
  XCircle,
} from "lucide-react";
import { Link } from "react-router-dom";

import { PurityFlagToName } from "./constants";
import { Display, DISPLAY_PROPERTIES } from "./display";
import { UnusualQuantification, UnusualQuantificationIcon } from "./legends";
import type { PlateFromTRPC, PlateKit, WorkflowWell } from "./types";
import { getColorsFromPurity } from "./useGetWellBackgroundColor";

import { Button } from "../../../../../../components/ui/button";
import { Separator } from "../../../../../../components/ui/separator";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../../../components/ui/tooltip";
import DateTooltip from "../../../../../../components/ui/upload-date-tooltip";
import { trpc } from "../../../../../../config/trpc";
import { cn } from "../../../../../../lib/utils";
import {
  getSequenceLength,
  parseInitiatorDNASequence,
} from "../../../../../../utils/parser";
import { OrganizationRoutes } from "../../../../organization-routes";
import PickConcentration from "../../../assay/components/pick-concentration";
import {
  BasicInput,
  SequenceInput,
  TextWithClipboard,
} from "../../../construct/components/sequence-input";
import ExternalComplexity from "../../../order/components/external-complexity";
import { OligoStatusComponent } from "../../../order/components/oligo-status";
import { DuplicateOligoButton } from "../../../order/oligos/components/oligo-column-components";
import { usePlateContext } from "../../containers/PlateContainer";

const ErrorSeverityToIcon: Record<WellErrorSeverity, JSX.Element> = {
  [WellErrorSeverity.Error]: <XCircle size={24} />,
  [WellErrorSeverity.Warning]: <AlertTriangle size={24} />,
};

const ErrorTypeToLabel: Record<WellErrorType, string> = {
  [WellErrorType.Biosecurity]: "Biosecurity problem",
  [WellErrorType.Click]: "Click problem",
  [WellErrorType.Length]: "Length problem",
  [WellErrorType.UnknownNucleotide]: "Unknown nucleotide",
  [WellErrorType.Purity]: "Estimated purity problem",
};

const SmallColoredDotFromGradient = ({
  gradient,
  position,
}: {
  gradient: string;
  position: number;
}) => {
  return (
    <span
      className="bg-well mr-1 h-2 w-2 rounded-full"
      style={{
        backgroundImage: gradient,
        backgroundPosition: `${position}%`,
      }}
    />
  );
};

const WellErrors = ({
  errors,
}: {
  errors: PlateFromTRPC["wells"][number]["errors"];
}) => {
  if (errors.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-2 rounded-lg bg-red-200 p-2">
      <div className="text-center">
        <p className="font-bold text-red-600">Errors and Warnings</p>
      </div>
      <ul className="text-sm">
        {errors.map((error) => {
          return (
            <li
              className="grid grid-cols-8 items-start gap-1 space-x-2"
              key={error.message}
            >
              <p className="col-span-1">
                {ErrorSeverityToIcon[error.severity]}
              </p>
              <p className="col-span-2 font-medium">
                {ErrorTypeToLabel[error.type]}
              </p>
              <p className="col-span-5">{error.message}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const FailOligoButton = ({
  id,
  size = "14",
}: {
  id: string;
  size?: string | number | undefined;
}) => {
  const { plate } = usePlateContext();
  const utils = trpc.useUtils();
  const { mutate: failOligo, isPending } = trpc.order.liberate.useMutation({
    onSuccess() {
      utils.plate.get.setData(plate.id, (prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          wells: prev.wells.map((well) => {
            if (well.oligoId === id) {
              return {
                ...well,
                oligoStatus: OligoStatus.Failed,
              };
            }
            return well;
          }),
        };
      });
    },
  });

  return (
    <Button
      isLoading={isPending}
      onClick={(e) => {
        failOligo([{ id, liberate: false }]);
        e.preventDefault();
        e.stopPropagation();
      }}
      size={size === "14" ? "xs" : undefined}
      variant={"ghost"}
    >
      <Ban size={size} />
    </Button>
  );
};

const EditingButtons = ({
  isEditable,
  handleDeleteWell,
  handleResetWell,
  index,
}: {
  handleDeleteWell: (index: string) => void;
  handleResetWell: (index: string) => void;
  index: string;
  isEditable: boolean;
}) => {
  if (!isEditable) {
    return null;
  }
  return (
    <>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            onClick={() => {
              handleResetWell(index);
            }}
            variant={"ghost"}
          >
            <LucideCircleSlash2 />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Reset well</TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            onClick={() => {
              handleDeleteWell(index);
            }}
            variant={"ghost"}
          >
            <Trash2Icon />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Remove well</TooltipContent>
      </Tooltip>
    </>
  );
};

export const SmallColoredDot = ({ className }: { className?: string }) => {
  return <span className={cn("mr-1 h-2 w-2 rounded-full", className)} />;
};

const getGradient =
  (well: WorkflowWell, kitProperties: PlateKit) => (display: Display) => {
    return DISPLAY_PROPERTIES[display].getWellColor(
      well,
      kitProperties,
      () => undefined,
    )["useGradient"];
  };

const SidebarQCResultsSection = ({
  well,
  kitProperties,
}: {
  kitProperties: PlateKit;
  well: WorkflowWell;
}) => {
  if (!well.qc) {
    return null;
  }
  const gradientGetter = getGradient(well, kitProperties);
  const {
    volume,
    concentration,
    yield: runningMass,
    initialYield: secondReadYield,
    initialYieldUploadedAt,
    abi,
    ngs,
    op2,
    lcms,
  } = well.qc;
  const { externalComplexity } = well.order ?? {};
  const { name, sequence } = well;
  const useGradientVolume = gradientGetter(Display.qc_volume);
  const useGradientYield = gradientGetter(Display.qc_yield);
  const useGradientInitialYield = gradientGetter(Display.qc_yield_initial);
  const useGradientConcentration = gradientGetter(Display.qc_concentration);

  return (
    <div className="grid grid-cols-2 gap-2 font-medium">
      <p className="font-medium">Sequence name</p>
      <TextWithClipboard text={name} />
      <p className="col-span-1">Length</p>
      <p>{getSequenceLength(sequence)}</p>
      <p className="col-span-1">Complexity score</p>
      {externalComplexity ? (
        <ExternalComplexity
          flag={externalComplexity.flag}
          score={externalComplexity.score}
        />
      ) : (
        "-"
      )}
      <Separator className="col-span-2" />
      <p className="col-span-1">Final yield</p>
      <p className="col-span-1 flex items-center space-x-1">
        {useGradientInitialYield && (
          <SmallColoredDotFromGradient
            gradient={useGradientInitialYield.gradient}
            position={useGradientInitialYield.position}
          />
        )}
        <span>{secondReadYield.toFixed(0)} pmol</span>
        <DateTooltip date={initialYieldUploadedAt} />
      </p>
      <p className="col-span-1 flex flex-row items-center space-x-1">
        Running mass
      </p>
      <p className="col-span-1 flex items-center space-x-1">
        {useGradientYield && (
          <SmallColoredDotFromGradient
            gradient={useGradientYield.gradient}
            position={useGradientYield.position}
          />
        )}
        <span>{runningMass.toFixed(0)} pmol</span>
      </p>
      <p className="col-span-1">Final concentration</p>
      <p className="col-span-1 flex items-center space-x-1">
        {useGradientConcentration && (
          <SmallColoredDotFromGradient
            gradient={useGradientConcentration.gradient}
            position={useGradientConcentration.position}
          />
        )}
        <span>{concentration.toFixed(1)} µM</span>
      </p>
      <p className="col-span-1">Remaining volume</p>
      <p className="col-span-1 flex items-center space-x-1">
        {useGradientVolume && (
          <SmallColoredDotFromGradient
            gradient={useGradientVolume.gradient}
            position={useGradientVolume.position}
          />
        )}
        <span>{Math.floor(volume)} µL</span>
      </p>
      <Separator className="col-span-2" />
      <p className="col-span-1">ABI %n</p>
      <div className="flex flex-row space-x-1">
        <p className="col-span-1">{abi?.n ? `${abi.n.toFixed(0)}%` : "-"}</p>
        {abi && <DateTooltip date={abi?.uploadedAt} />}
      </div>
      <p className="col-span-1">ABI %n-1</p>
      <p className="col-span-1">
        {abi?.nMinus1 ? `${abi.nMinus1.toFixed(0)}%` : "-"}
      </p>
      <p className="col-span-1">ABI %n+1</p>
      <p className="col-span-1">
        {abi?.nPlus1 ? `${abi.nPlus1.toFixed(0)}%` : "-"}
      </p>
      <p className="col-span-1">ABI n-size (bp)</p>
      <p className="col-span-1">{abi?.nSize ?? "-"}</p>
      <p className="col-span-1">ABI comment</p>
      <p className="col-span-1">{abi?.comment ?? "-"}</p>
      <Separator className="col-span-2" />
      <p className="col-span-1">OP2 %n</p>
      <div className="flex flex-row space-x-1">
        <p className="col-span-1">{op2?.n ? `${op2.n.toFixed(0)}%` : "-"}</p>
        {op2 && <DateTooltip date={op2?.uploadedAt} />}
      </div>
      <p className="col-span-1">OP2 %n-1</p>
      <p className="col-span-1">
        {op2?.nMinus1 ? `${op2.nMinus1.toFixed(0)}%` : "-"}
      </p>
      <p className="col-span-1">OP2 %n+1</p>
      <p className="col-span-1">
        {op2?.nPlus1 ? `${op2.nPlus1.toFixed(0)}%` : "-"}
      </p>
      <p className="col-span-1">OP2 n-size (bp)</p>
      <p className="col-span-1">{op2?.nSize ?? "-"}</p>
      <Separator className="col-span-2" />
      <p className="col-span-1">NGS insertions</p>
      <p className="col-span-1">
        {ngs?.insertions ? `${(ngs.insertions * 100).toFixed(4)}%` : "-"}
      </p>
      <p className="col-span-1">NGS substitutions</p>
      <p className="col-span-1">
        {ngs?.substitutions ? `${(ngs.substitutions * 100).toFixed(4)}%` : "-"}
      </p>
      <p className="col-span-1">NGS deletions</p>
      <p className="col-span-1">
        {ngs?.deletionsNoOver5ntGaps
          ? `${(ngs?.deletionsNoOver5ntGaps * 100).toFixed(4)}%`
          : "-"}
      </p>
      <p className="col-span-1">NGS start gap over 5</p>
      <p className="col-span-1">
        {ngs?.startGapOver5 ? `${(ngs.startGapOver5 * 100).toFixed(4)}%` : "-"}
      </p>
      <p className="col-span-1">NGS analysis name</p>
      <p className="col-span-1">{ngs?.analysisName || "-"}</p>
      <p className="col-span-1">NGS error rate</p>
      <div className="flex flex-row space-x-1">
        <p className="col-span-1">
          {ngs?.errorRate ? `${(ngs.errorRate * 100).toFixed(4)}%` : "-"}
        </p>
        {ngs && <DateTooltip date={ngs?.uploadedAt} />}
      </div>
      <Separator className="col-span-2" />
      <p className="col-span-1">LCMS pass</p>
      <div className="flex flex-row space-x-1">
        <p className="col-span-1">
          {lcms?.isPass === undefined ? "-" : lcms?.isPass.toString()}
        </p>
        {lcms && <DateTooltip date={lcms?.uploadedAt} />}
      </div>
      <p className="col-span-1">LCMS conclusion</p>
      <p className="col-span-1">{lcms?.conclusion ?? "-"}</p>
      <p className="col-span-1">LCMS expected mass</p>
      <p className="col-span-1">
        {lcms?.expectedMass ? `${lcms.expectedMass}` : "-"}
      </p>
      <p className="col-span-1">LCMS major peak pass</p>
      <p className="col-span-1">
        {lcms?.majorPeakMass ? `${lcms.majorPeakMass}` : "-"}
      </p>
    </div>
  );
};
const SidebarResultSection = ({
  well,
  kitProperties,
}: {
  kitProperties: PlateKit;
  well: WorkflowWell;
}) => {
  const { result } = well;
  const gradientGetter = getGradient(well, kitProperties);
  const useGradientConcentration = gradientGetter(Display.concentration);
  const useGradientMeasuredConcentration = gradientGetter(
    Display.measuredConcentration,
  );
  const useGradientVolume = gradientGetter(Display.volume);
  const useGradientYield = gradientGetter(Display.yield);
  const useGradientModifications = gradientGetter(Display.modification);

  if (!result) {
    return null;
  }
  const {
    concentrationPostNormalization: concentration,
    yield: yieldValue,
    totalVolume,
    sequenceModificationStatus,
  } = result;

  const unusualQuantification = Boolean(well.result?.yieldError);

  return (
    <>
      <p className="col-span-1">Normalization</p>
      <p>{well.normalization}</p>
      <p className="col-span-1">Concentration (Target)</p>
      <p className="col-span-1 flex items-center space-x-1">
        {well.normalization !== Normalization.None &&
          useGradientConcentration && (
            <SmallColoredDotFromGradient
              gradient={useGradientConcentration.gradient}
              position={useGradientConcentration.position}
            />
          )}
        {well.normalization !== Normalization.None ? (
          <span>{`${well.result?.concentrationDesired.toFixed(1) ?? 0} µM`}</span>
        ) : (
          <span>-</span>
        )}
      </p>
      <p className="col-span-1">Concentration (Actual)</p>
      <p className="col-span-1 flex items-center space-x-1">
        {useGradientMeasuredConcentration && (
          <SmallColoredDotFromGradient
            gradient={useGradientMeasuredConcentration.gradient}
            position={useGradientMeasuredConcentration.position}
          />
        )}
        <span>{concentration.toFixed(1)} µM</span>
      </p>

      <p className="col-span-1">Mods</p>
      <p className="col-span-1 flex items-center space-x-1">
        {useGradientModifications && (
          <SmallColoredDotFromGradient
            gradient={useGradientModifications.gradient}
            position={useGradientModifications.position}
          />
        )}
        <span>{ModificationStatusToLabel[sequenceModificationStatus]}</span>
      </p>

      <p className="col-span-1">Actual volume</p>
      <p className="col-span-1 flex items-center space-x-1">
        {useGradientVolume && (
          <SmallColoredDotFromGradient
            gradient={useGradientVolume.gradient}
            position={useGradientVolume.position}
          />
        )}
        <span>{totalVolume.toFixed(0)} µL</span>
      </p>

      <p className="col-span-1 flex flex-row items-center space-x-1">
        {unusualQuantification && <UnusualQuantificationIcon />}
        <span>Yield</span>
      </p>
      <p className="col-span-1 flex items-center space-x-1">
        {useGradientYield && (
          <SmallColoredDotFromGradient
            gradient={useGradientYield.gradient}
            position={useGradientYield.position}
          />
        )}
        <span>{yieldValue.toFixed(0)} pMol</span>
      </p>
    </>
  );
};

export const PlateSidebarWell = ({
  isEditable,
  well,
  handleChangeWell,
  handleDeleteWell,
  handleResetWell,
  kitProperties,
}: {
  handleChangeWell: (index: string, well: WorkflowWell) => void;
  handleDeleteWell: (index: string) => void;
  handleResetWell: (index: string) => void;
  isEditable: boolean;
  kitProperties: PlateKit;
  well: WorkflowWell;
}) => {
  const defaultConcentration = kitProperties.concentration.min;
  const { isSidebarQC } = usePlateContext();

  const {
    errors,
    index,
    name,
    normalization,
    purityFlag,
    result,
    sequence,
    order,
    oligoId,
  } = well;
  const { sequence: dnaSequence, initiatorSequence } =
    parseInitiatorDNASequence(sequence);
  const hasInitiator = Boolean(initiatorSequence);

  const handleApplySequenceChange = (newSequence: string) => {
    handleChangeWell(index, {
      ...well,
      sequence: hasInitiator
        ? `${initiatorSequence}+${newSequence}`
        : newSequence,
    });
  };

  const handleApplyInitiatorSequenceChange = (newInitiator: string) => {
    handleChangeWell(index, {
      ...well,
      sequence: `${newInitiator}+${dnaSequence}`,
    });
  };

  const handleRemoveInitiatorSequence = () => {
    handleChangeWell(index, {
      ...well,
      sequence: dnaSequence,
    });
  };

  const handleApplyNameChange = (name: string) => {
    handleChangeWell(index, { ...well, name });
  };

  const handleApplyConcentrationChange = (
    normalization: Normalization,
    concentration?: number,
  ) => {
    handleChangeWell(index, {
      ...well,
      expectedConcentration: concentration,
      normalization,
    });
  };

  const { background, text } = getColorsFromPurity(well.purityFlag);
  const unusualQuantification = Boolean(well.result?.yieldError);

  return (
    <div className="flex flex-col space-y-3">
      <div className="flex flex-row justify-between">
        <span className="font-bold">{`Inspector (${index})`}</span>
        <div className="flex flex-row space-x-2">
          <EditingButtons
            handleDeleteWell={handleDeleteWell}
            handleResetWell={handleResetWell}
            index={index}
            isEditable={isEditable}
          />
          {order && (
            <>
              <Tooltip>
                <TooltipTrigger>
                  <DuplicateOligoButton id={oligoId} size={24} />
                </TooltipTrigger>
                <TooltipContent>Replicate oligo in the backlog</TooltipContent>
              </Tooltip>
              <Tooltip>
                <TooltipTrigger>
                  <FailOligoButton id={oligoId} size={24} />
                </TooltipTrigger>
                <TooltipContent>Mark the oligo as failed</TooltipContent>
              </Tooltip>
              <div>{OligoStatusComponent[well.oligoStatus]}</div>
            </>
          )}
        </div>
      </div>
      {isSidebarQC ? (
        <SidebarQCResultsSection kitProperties={kitProperties} well={well} />
      ) : (
        <>
          <div className="font-medium">
            {unusualQuantification && <UnusualQuantification />}
          </div>
          <WellErrors errors={errors} />
          <div className="space-y-1">
            <p className="font-medium">Sequence</p>
            <SequenceInput
              disabled={!isEditable}
              onBlur={() => {}}
              onChange={handleApplySequenceChange}
              value={dnaSequence}
            />
          </div>
          {initiatorSequence && (
            <div className="space-y-1">
              <div className="flex flex-row justify-between">
                <p className="font-medium">Initiator sequence</p>
                <Button
                  onClick={handleRemoveInitiatorSequence}
                  size={"xs"}
                  variant={"outline"}
                >
                  <Trash2Icon size={14} />
                </Button>
              </div>
              <SequenceInput
                disabled={!isEditable}
                onBlur={() => {}}
                onChange={handleApplyInitiatorSequenceChange}
                value={initiatorSequence}
              />
            </div>
          )}
          <div className="space-y-1">
            <p className="font-medium">Sequence name</p>
            <BasicInput
              disabled={!isEditable}
              onBlur={() => {}}
              onChange={handleApplyNameChange}
              value={name}
            />
          </div>
          <div className="grid grid-cols-2 gap-2 font-medium">
            <p className="col-span-1">Length</p>
            <p>{getSequenceLength(well.sequence)}</p>
          </div>
          {order && (
            <div className="grid grid-cols-2 gap-2 font-medium">
              <p className="col-span-1 ">Order</p>
              <Link
                to={OrganizationRoutes.SERVICE_ORDER.replace(
                  ":orderId",
                  order.id,
                )}
              >
                <p className="hover:underline">{order.netsuiteSOId}</p>
              </Link>
            </div>
          )}

          {!result && (
            <PickConcentration
              currentConcentration={
                well.expectedConcentration ?? defaultConcentration
              }
              handleApplyConcentrationChange={handleApplyConcentrationChange}
              isEditable={isEditable}
              kitConcentrationMax={kitProperties.concentration.max}
              kitConcentrationMin={kitProperties.concentration.min}
              normalization={normalization}
            />
          )}

          <div className="grid grid-cols-2 gap-2 font-medium">
            {result && (
              <SidebarResultSection kitProperties={kitProperties} well={well} />
            )}
            <p className="col-span-1">Complexity Score</p>
            <p className="col-span-1 flex items-center space-x-1">
              <SmallColoredDot className={`${background} ${text}`} />
              <span>{PurityFlagToName[purityFlag]}</span>
            </p>
          </div>
        </>
      )}
    </div>
  );
};
