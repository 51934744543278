import { Button } from "../../../../../components/ui/button";
import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";

export default function CompleteFillAndFinish({
  orderId,
}: {
  orderId: string;
}) {
  const { toast } = useToast();
  const utils = trpc.useUtils();
  const { mutate: completeFnF } = trpc.order.fillAndFinish.complete.useMutation(
    {
      onError(error) {
        toast({
          description: error.message,
          title: "Fill and finish completion error",
          variant: "destructive",
        });
      },
      onSuccess() {
        toast({
          title: "Fill and finish completed",
          variant: "success",
        });
        utils.order.read.invalidate(orderId);
      },
    },
  );

  const handleCompleteFillAndFinish = () => {
    completeFnF(orderId);
  };

  return (
    <Button onClick={handleCompleteFillAndFinish} variant={"outline"}>
      Complete fill and finish
    </Button>
  );
}
