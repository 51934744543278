import { RunState, WellErrorSeverity } from "@console/shared";
import { Download } from "lucide-react";
import { Link } from "react-router-dom";

import type { PlateFromTRPC, PlateRun } from "./plate/types";

import { KitLabel } from "../../../../../components/logic/kit-label";
import { Button } from "../../../../../components/ui/button";
import { Separator } from "../../../../../components/ui/separator";
import { trpc } from "../../../../../config/trpc";
import { getSequenceLength } from "../../../../../utils/parser";
import { useDownloadFile } from "../../../../../utils/useDownload";
import { OrganizationRoutes } from "../../../organization-routes";

function getDurationStr(progress: NonNullable<PlateRun["progress"]>): string {
  const totalDurationSec = progress.phases
    .map((p) => p.estimatedRemainingTime.seconds)
    .reduce((partialSum, s) => partialSum + s, 0);
  const hours = Math.floor(totalDurationSec / 3600);
  const mins = Math.round((totalDurationSec - hours * 3600) / 60);
  return (hours > 0 ? `${hours}h ` : "") + (mins > 0 ? `${mins}m` : "");
}

function RunDescription({ run }: { run: PlateRun | null }) {
  const downloadFile = useDownloadFile();
  const { mutate: downloadPrintJobAsJson } =
    trpc.plate.run.downloadPrintJob.useMutation({
      onSuccess(url) {
        downloadFile(url, "print-job.json");
      },
    });

  if (!run) {
    return null;
  }

  const isQueuedOrLoaded = [RunState.Loaded, RunState.Queued].includes(
    run.state,
  );

  return (
    <>
      <Separator />
      <div className="flex flex-row items-center justify-between space-x-4">
        <h3 className="font-bold">Run</h3>
        <div>
          <p className="italic">Instrument</p>
          <Link
            data-testid="instrument-link"
            className="text-blue-500 hover:underline"
            to={OrganizationRoutes.INSTRUMENT.replace(
              ":instrumentId",
              run.instrument.id,
            )}
          >
            {run.instrument.name}
          </Link>
        </div>
        <div>
          <p className="italic">Creator</p>
          <p>{run.createdByName}</p>
        </div>
        <div>
          <p className="italic">Run id</p>
          <p>{run.id}</p>
        </div>
        <div>
          <p className="italic">Sent date</p>
          <p>{new Date(run.createdAt).toLocaleString()}</p>
        </div>
        {isQueuedOrLoaded && (
          <div>
            <p className="italic">Estimated total time</p>
            <p>{run.progress ? getDurationStr(run.progress) : "-"}</p>
          </div>
        )}
        <div>
          <p className="italic">Download print job</p>
          <div className="flex flex-row items-center space-x-2">
            <Button
              className="flex items-center space-x-1 p-1 text-xs"
              onClick={() => {
                downloadFile(run.printJobUrl, "print-job.pb");
              }}
              size="sm"
              variant={"outline"}
            >
              <Download />
              <span>pb</span>
            </Button>
            <Button
              className="flex items-center space-x-1 p-1 text-xs"
              onClick={() => {
                downloadPrintJobAsJson({ id: run.id });
              }}
              size="sm"
              variant={"outline"}
            >
              <Download />
              <span>json</span>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default function PlateDescription({ plate }: { plate: PlateFromTRPC }) {
  const wells = plate.wells;
  const wellLengths = wells.map((well) => getSequenceLength(well.sequence));
  const countOfValidWells = wells.filter(
    (w) =>
      w.errors.filter((e) => e.severity === WellErrorSeverity.Error).length ===
      0,
  ).length;
  const downloadFile = useDownloadFile();

  const handleDownloadSynthesisFile = () => {
    if (!plate?.prototype?.synthesisFile) {
      return;
    }
    downloadFile(plate.prototype.synthesisFile.url, "synthesisFile.xlsx");
  };

  return (
    <div className="ml-4 space-y-2">
      <div className="flew-row flex items-center justify-between space-x-1">
        <h3 className="font-bold">Plate</h3>
        <KitLabel kitId={plate.kit} />
        <Separator className="h-6" orientation="vertical" />
        <div>
          <p className="italic">Valid / Filled wells</p>
          <p>
            <span>{countOfValidWells}</span>
            <span> / </span>
            <span>{plate.wells.length}</span>
          </p>
        </div>
        <Separator className="h-6" orientation="vertical" />
        <div>
          <p>Sizes</p>
          <p>{`${Math.min(...wellLengths)}-${Math.max(...wellLengths)}`}</p>
        </div>
        <Separator className="h-6" orientation="vertical" />
        <div>
          <p className="italic">Cycles</p>
          <p>{plate.cycles}</p>
        </div>
      </div>
      {plate.prototype && (
        <div className="flex flex-row items-center space-x-4">
          <div>
            <p className="italic">Prototype assigned</p>
            <p>{plate.prototype.name}</p>
          </div>
          {plate.prototype?.synthesisFile && (
            <Button
              className="flex items-center space-x-1"
              onClick={handleDownloadSynthesisFile}
              variant={"outline"}
            >
              <p className="italic">Synthesis file</p>
              <Download />
            </Button>
          )}
        </div>
      )}
      <RunDescription run={plate.run} />
    </div>
  );
}
