import { PlateSize } from "@console/shared";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@frontend/components/ui/form";
import { debounce } from "@frontend/utils/debounce";
import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowRight, Save, Trash } from "lucide-react";
import { useCallback, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useForm, useFieldArray } from "react-hook-form";

import { AddPlate } from "./add-plate";
import LeaveWarning from "./leave-warning";
import { WorkflowActions } from "./reducer";
import type { Oligo } from "./types";
import { workflowSchema, type Plate } from "./types";
import { preparePlatesForAPI } from "./utils";
import { useWorkflowBuildContext } from "./workflow-build-context";
import WorkflowGenes from "./workflow-genes";
import WorkflowOligos from "./workflow-oligos";
import { WellWorkflow } from "./workflow-well";

import GenericPlate from "../../../../../components/plate/generic-plate";
import { Button } from "../../../../../components/ui/button";
import { Input } from "../../../../../components/ui/input";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../../../../components/ui/resizable";
import { useToast } from "../../../../../components/ui/use-toast";
import type { AssayTRPC } from "../../../../../config/trpc";
import { trpc } from "../../../../../config/trpc";
import { cn } from "../../../../../lib/utils";
import { useGetKitLabels } from "../../../../admin/organizations/organization/components/useGetEnvironmentKits";
import { useKitIdsToSizeMap } from "../../../../settings/organization-settings/hooks";

export default function WorkflowBuild({ assay }: { assay: AssayTRPC }) {
  const {
    assayId,
    dispatchWorkflowChange,
    isWorkflowChanged,
    kitToAdd,
    oligoToWellsMap,
    setStoredWorkflowPlates,
    workflow,
  } = useWorkflowBuildContext();
  const noRemainingOligos = workflow.oligos.every((o) =>
    oligoToWellsMap.has(o.id),
  );

  const kitLabeler = useGetKitLabels();
  const kitToSize = useKitIdsToSizeMap();
  const { toast } = useToast();

  const form = useForm({
    defaultValues: {
      oligos: [] as Oligo[],
      plates: [] as Plate[],
    },
    mode: "onChange",
    resolver: zodResolver(
      workflowSchema.superRefine(() => {
        form.clearErrors("plates");
      }),
    ),
  });

  const { fields: plates } = useFieldArray({
    control: form.control,
    keyName: "fieldID",
    name: "plates",
  });

  useEffect(() => {
    form.setValue("plates", [...workflow.plates]);
  }, [form, workflow.plates]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangePlateName = useCallback(
    debounce((plateId: string, name: string) => {
      dispatchWorkflowChange({
        payload: {
          name,
          plateId,
        },
        type: WorkflowActions.RENAME_PLATE,
      });
    }, 500),
    [],
  );

  const utils = trpc.useUtils();

  const { mutate: assignOligoToPlates, isPending } =
    trpc.assay.steps.assignOligos.useMutation({
      onError(error) {
        const message = error.data?.zodError ?? error.message;
        toast({
          description: `An error occurred while saving the workflow: ${message}.`,
          title: "Error",
          variant: "destructive",
        });
      },
      onSuccess(_, variables) {
        utils.assay.get.invalidate();
        utils.construct.listInAssay.invalidate({ assayId });
        setStoredWorkflowPlates(variables.plates as Plate[]);
      },
    });

  const storeChanges = (plates: Plate[]) => {
    assignOligoToPlates({
      assayId,
      plates: preparePlatesForAPI(plates),
    });
  };

  return (
    <div className="rounded-lg border p-2">
      <LeaveWarning shouldDisplayWarning={isWorkflowChanged} />
      <h2 className="font-bold">Oligo assignment</h2>
      <ResizablePanelGroup direction="horizontal">
        <DndProvider backend={HTML5Backend}>
          <ResizablePanel className="p-2" defaultSize={45} minSize={20}>
            <div className="flex flex-row justify-between">
              <h3 className="font-bold">Oligos</h3>
              <Button
                disabled={noRemainingOligos}
                onClick={() => {
                  dispatchWorkflowChange({
                    payload: {
                      defaultKit: kitToAdd,
                      defaultSize: kitToSize.get(kitToAdd) ?? PlateSize.S96,
                    },
                    type: WorkflowActions.AUTO_ASSIGN,
                  });
                }}
                variant={"secondary"}
              >
                <span>Assign remaining</span>
                <ArrowRight />
              </Button>
            </div>
            <WorkflowOligos />
            <WorkflowGenes assayId={assayId} />
          </ResizablePanel>
          <ResizableHandle />
          <ResizablePanel
            className="flex flex-col p-2"
            defaultSize={55}
            minSize={30}
          >
            <div className="mb-2 flex flex-row justify-between ">
              <h3 className="font-bold">Plates</h3>
              <Button
                className="w-[160px] space-x-1"
                disabled={assay.archived || !isWorkflowChanged}
                isLoading={isPending}
                onClick={form.handleSubmit((data) => {
                  storeChanges(data.plates);
                })}
              >
                <span>Save workflow</span>
                <Save />
              </Button>
            </div>
            <Form {...form}>
              <form>
                <div className="max-h-[80vh] space-y-4 overflow-scroll ">
                  {plates.map((plate, plateIndex) => {
                    const locked = plate.locked;
                    return (
                      <div
                        className={cn(
                          "flex flex-col space-y-1 rounded-lg border p-1 shadow-md",
                          locked && "opacity-50",
                        )}
                        key={plate.id}
                      >
                        <div className="flex flex-row items-center justify-between space-x-2">
                          <div className="relative grow">
                            <FormField
                              control={form.control}
                              name={`plates.${plateIndex}.name` as const}
                              render={({ field: { onChange, ...rest } }) => (
                                <FormItem>
                                  <FormControl>
                                    <Input
                                      disabled={locked}
                                      {...rest}
                                      onChange={(e) => {
                                        onChange(e);
                                        handleChangePlateName(
                                          plate.id,
                                          e.target.value,
                                        );
                                      }}
                                      placeholder="Plate name required"
                                    />
                                  </FormControl>
                                  <FormMessage className="absolute bottom-0 left-0 pl-3 text-[10px]" />
                                </FormItem>
                              )}
                            />
                          </div>
                          <p className="text-nowrap">{kitLabeler(plate.kit)}</p>
                          <Button
                            disabled={locked}
                            onClick={() => {
                              dispatchWorkflowChange({
                                payload: {
                                  plateIndex,
                                },
                                type: WorkflowActions.REMOVE_PLATE,
                              });
                            }}
                            variant={"outline"}
                          >
                            <Trash />
                          </Button>
                        </div>
                        <GenericPlate
                          size={plate.size}
                          wellRenderer={(index) => {
                            const well = plate.wells.find(
                              (w) => w.index === index,
                            );
                            if (!well) {
                              return null;
                            }

                            return (
                              <WellWorkflow
                                key={index}
                                plateId={plate.id}
                                plateIndex={plateIndex}
                                well={well}
                              />
                            );
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                <AddPlate dispatchWorkflowChange={dispatchWorkflowChange} />
              </form>
            </Form>
          </ResizablePanel>
        </DndProvider>
      </ResizablePanelGroup>
    </div>
  );
}

/*
TODO
build workflow only from here
- handle well failure -> oligos have to be assigned again: create new plate. no workflow deps for now.

DESIGN
Orders
  Order content: oligo plates, genes
  Order oligos.
Gene design = new step. When creating an assay, gene design steps are created in the workflow.
  Click on design to generate oligos of the gene.
  For now, go to construct and click on "generate oligos"
Plates
  Map oligos into plates
  Analysis on each plate
  Retry some wells -> assign oligos to new plates
Customer plate: step for each order plate.


In an assay. Workflow:
(N) Order(s) -> synthesis plates -> analysis -> oligo plates

Change constructs -> they become orders.

*/
