import { PlateStatus, RunState } from "@console/shared";
import { PlayCircle, ListChecksIcon, Trash2Icon, X } from "lucide-react";
import { useState } from "react";

import ChooseSyntaxInstrument from "./choose-syntax-instrument";
import DownloadPlateLayout from "./download-plate-layout";
import GenerateSynthesisFile from "./generate-synthesis-file";
import type { PlateFromTRPC } from "./plate/types";
import UploadSynthesisFile from "./upload-synthesis-file";

import { AlertDialogWrapper } from "../../../../../components/ui/alert-dialog";
import { Button } from "../../../../../components/ui/button";
import { Label } from "../../../../../components/ui/label";
import { RadioGroup, RadioGroupItem } from "../../../../../components/ui/radio";
import { trpc } from "../../../../../config/trpc";
import { useIsServiceEnabled } from "../../../../../containers/user/hooks";
import MarkPlateAsPrinting from "../../order/components/plates/mark-plate-as-printing";
import { useCheckRun } from "../useCheckRun";
import { useDeleteRun } from "../useDeleteRun";
import { useStartRun } from "../useStartJob";

function PickSyntaxOrPrototype({
  shouldDisplayPrototypeButtons,
  setShouldDisplayPrototypeButtons,
}: {
  setShouldDisplayPrototypeButtons: (value: boolean) => void;
  shouldDisplayPrototypeButtons: boolean;
}) {
  return (
    <RadioGroup
      className="gap-1"
      onValueChange={(v) => {
        setShouldDisplayPrototypeButtons(v === "prototype");
      }}
      value={shouldDisplayPrototypeButtons ? "prototype" : "syntax"}
    >
      <div className="flex items-center space-x-1" key={"syntax"}>
        <RadioGroupItem id={"syntax"} value={"syntax"} />
        <Label htmlFor={"syntax"}>{"syntax"}</Label>
      </div>
      <div className="flex items-center space-x-1" key={"prototype"}>
        <RadioGroupItem id={"prototype"} value={"prototype"} />
        <Label htmlFor={"prototype"}>{"prototype"}</Label>
      </div>
    </RadioGroup>
  );
}

export function PlateActions({
  plate,
  uploadDisabled = false,
}: {
  plate: PlateFromTRPC;
  uploadDisabled?: boolean;
}) {
  const isAssignedToPrototype = Boolean(plate.prototype);
  const isRunCreated = Boolean(plate.run);
  const [shouldDisplayPrototypeButtons, setShouldDisplayPrototypeButtons] =
    useState(isAssignedToPrototype);
  const isQueued = isRunCreated && plate.run?.state === RunState.Queued;
  const isLoaded = isRunCreated && plate.run?.state === RunState.Loaded;
  const isPlateChecked = Boolean(plate.run?.canRun);
  const isServiceEnabled = useIsServiceEnabled();
  const { id: plateId, isRunFinished, isRealPlate, status } = plate;
  const isInAssayWorkflow = Boolean(plate.stepId);

  const runId = plate.run?.id;
  const { startRun, isPending: isStartPending } = useStartRun(runId);
  const { checkRun, isPending: isCheckPending } = useCheckRun(runId);
  const utils = trpc.useUtils();
  const { mutate: cancelPlate, isPending: isCancelingPlate } =
    trpc.order.productionPlates.cancel.useMutation({
      onSuccess() {
        utils.plate.get.invalidate(plateId);
      },
    });
  const { mutate: markRunAsFailed, isPending: isFailingPlate } =
    trpc.order.productionPlates.markRunAsFailed.useMutation({
      onSuccess() {
        utils.plate.get.invalidate(plateId);
      },
    });

  const { deleteRun } = useDeleteRun(runId);

  if (status === PlateStatus.Canceled || status === PlateStatus.Failed) {
    return null;
  }

  const isButtonGroupVisible = !isAssignedToPrototype && !isRunCreated;
  const isProductionPlate = !isInAssayWorkflow && isServiceEnabled;

  function getButtonsToDisplay(): React.ReactNode[] {
    const buttons: React.ReactNode[] = [];

    if (isButtonGroupVisible && isProductionPlate) {
      buttons.push(
        <PickSyntaxOrPrototype
          setShouldDisplayPrototypeButtons={setShouldDisplayPrototypeButtons}
          shouldDisplayPrototypeButtons={shouldDisplayPrototypeButtons}
        />,
      );
    }

    if (isProductionPlate) {
      const isBeforeRun = [
        PlateStatus.ToBeAssigned,
        PlateStatus.QueuedOnInstrument,
      ].includes(status);

      if (isBeforeRun) {
        buttons.push(
          <AlertDialogWrapper
            description={"Are you sure you want to cancel this plate ?"}
            onConfirm={() => cancelPlate(plateId)}
            title={"Cancel the plate"}
          >
            <Button
              className="flex flex-row items-center space-x-1"
              disabled={isCancelingPlate}
              isLoading={isCancelingPlate}
              variant={"destructive"}
            >
              <X />
              <span>Cancel plate</span>
            </Button>
          </AlertDialogWrapper>,
        );
      }

      if (isRealPlate) {
        buttons.push(
          <AlertDialogWrapper
            description={"Are you sure you want to mark the plate as failed ?"}
            onConfirm={() => markRunAsFailed(plateId)}
            title={"Mark the plate as failed"}
          >
            <Button
              className="flex flex-row items-center space-x-1"
              disabled={isFailingPlate}
              isLoading={isFailingPlate}
              variant={"destructive"}
            >
              <Trash2Icon />
              <span>Mark as failed</span>
            </Button>
          </AlertDialogWrapper>,
        );
      }

      buttons.push(<DownloadPlateLayout id={plateId} />);

      if (!isRunCreated && shouldDisplayPrototypeButtons) {
        buttons.push(
          <MarkPlateAsPrinting id={plateId} />,
          <UploadSynthesisFile plate={plate} />,
        );
      }
      if (isRunFinished) {
        buttons.push(<UploadSynthesisFile asQC plate={plate} />);
      }
      buttons.push(<GenerateSynthesisFile plate={plate} />);
    }

    if (shouldDisplayPrototypeButtons || isAssignedToPrototype) {
      return buttons;
    }

    buttons.push(
      <ChooseSyntaxInstrument disabled={uploadDisabled} plate={plate} />,
    );

    if (isQueued || isLoaded) {
      if (isPlateChecked) {
        buttons.push(
          <Button
            className="flex flex-row items-center space-x-1"
            disabled={isStartPending}
            isLoading={isStartPending}
            onClick={startRun}
          >
            <PlayCircle />
            <span>Start run</span>
          </Button>,
        );
      } else {
        buttons.push(
          <Button
            className="flex flex-row items-center space-x-1"
            disabled={isCheckPending}
            isLoading={isCheckPending}
            onClick={checkRun}
          >
            <ListChecksIcon />
            <span>Check run</span>
          </Button>,
        );
      }
      buttons.push(
        <Button
          className="flex flex-row items-center space-x-2"
          disabled={isLoaded}
          onClick={deleteRun}
          variant={"outline"}
        >
          <Trash2Icon />
          <span>Remove from queue</span>
        </Button>,
      );
    }

    return buttons;
  }

  return <div className="flex flex-row space-x-2">{getButtonsToDisplay()}</div>;
}
