import { PlateEventType } from "@console/shared";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import type { PlateFromTRPC } from "./plate/types";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../../../components/ui/accordion";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../../components/ui/card";
import { SimpleTable } from "../../../../../components/ui/simple-table";

const PlateEventToLabel: Record<PlateEventType, string> = {
  [PlateEventType.Canceled]: "Canceled",
  [PlateEventType.Created]: "Created",
  [PlateEventType.InstrumentAssigned]: "Assigned to instrument",
  [PlateEventType.LoadOnInstrument]: "Selected on syntax",
  [PlateEventType.LostSyntaxConnection]: "Connexion to Syntax lost",
  [PlateEventType.RemovedFromQueue]: "Removed from syntax queue",
  [PlateEventType.MarkedAsFailed]: "Marked as failed",
  [PlateEventType.RunCompleted]: "Run completed",
  [PlateEventType.Stored]: "Stored",
  [PlateEventType.RunFailed]: "Run failed",
  [PlateEventType.RunStarted]: "Run started",
  [PlateEventType.UnloadFromInstrument]: "Unselected from syntax",
  [PlateEventType.UploadQCQuantificationResults]:
    "Upload QC Quantification Results",
  [PlateEventType.UploadQCABIResults]: "Upload QC ABI Results",
  [PlateEventType.UploadQCOP2Results]: "Upload QC OP2 Results",
  [PlateEventType.UploadQCNGSResults]: "Upload QC NGS Results",
  [PlateEventType.UploadQCLCMSResults]: "Upload QC LCMS Results",
  [PlateEventType.QCPlateCreated]: "QC Plate created",
};

type PlateEvent = NonNullable<PlateFromTRPC["history"]>[number];

const columnHelper = createColumnHelper<PlateEvent>();

const plateEventsColumns = [
  columnHelper.accessor("type", {
    cell: (row) => PlateEventToLabel[row.getValue() as PlateEventType],
    header: "State",
  }),
  columnHelper.accessor("date", {
    cell: (row) => new Date(row.getValue()).toLocaleString(),
    header: "Date",
  }),
  columnHelper.accessor("userName", {
    cell: (row) => row.getValue(),
    header: "User",
  }),
];

export default function PlateHistory({ plate }: { plate: PlateFromTRPC }) {
  const plateHistoryTable = useReactTable({
    columns: plateEventsColumns,
    data: plate.history ?? [],
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Card>
      <Accordion collapsible type="single">
        <AccordionItem value="configuration">
          <CardHeader>
            <AccordionTrigger>
              <CardTitle>History</CardTitle>
            </AccordionTrigger>
          </CardHeader>
          <AccordionContent>
            <CardContent className="max-h-[30vh] overflow-scroll">
              <SimpleTable table={plateHistoryTable} />
            </CardContent>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </Card>
  );
}
