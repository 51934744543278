import { useGetKitLabels } from "../../routes/admin/organizations/organization/components/useGetEnvironmentKits";
import { KitHighFidelity, Kit384, KitStandard } from "../../routes/icons";

type KitEnum =
  | "B5_HPURE_96"
  | "B5_HMASS_96"
  | "B5_HPLEX_384"
  | "B6_HPURE_96_LONG"
  | "B6_HPURE_96_LONG_BULK";
const KIT_LABELS: Record<
  KitEnum | "unknown",
  {
    icon: React.FC<React.SVGProps<SVGElement>>;
  }
> = {
  B5_HMASS_96: {
    icon: KitStandard,
  },
  B5_HPLEX_384: {
    icon: Kit384,
  },
  B5_HPURE_96: {
    icon: KitHighFidelity,
  },
  B6_HPURE_96_LONG: {
    icon: KitHighFidelity,
  },
  B6_HPURE_96_LONG_BULK: {
    icon: KitHighFidelity,
  },
  unknown: {
    icon: KitHighFidelity,
  },
};

export function KitLabel({ kitId }: { kitId: string }) {
  const kitLabeler = useGetKitLabels();
  const kitLabel = KIT_LABELS[kitId as KitEnum] || KIT_LABELS.unknown;
  const KitIcon = kitLabel.icon;
  return (
    <div className="flex flex-row items-center space-x-1">
      <span className="w-full">{kitLabeler(kitId)}</span>
      <KitIcon className="min-w-[120px]" />
    </div>
  );
}
