import { SaveIcon, Upload } from "lucide-react";
import { useState } from "react";

import type { PlateFromTRPC } from "./plate/types";

import { Button } from "../../../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../../components/ui/dialog";
import UploadExcelFile from "../../../../../components/ui/upload-excel-file";
import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";
import { arrayBufferTobase64 } from "../../../../../utils/useDownload";

function useUploadCallback(
  asQC: boolean,
  onSuccess: () => void,
): {
  isPending: boolean;
  mutate: (data: { file: string; fileName: string; plateId: string }) => void;
} {
  const { mutate: uploadSynthesisFile, isPending } =
    trpc.order.productionPlates.uploadSynthesisResults.useMutation({
      onSuccess() {
        onSuccess();
      },
    });
  const { mutate: uploadQCSynthesisResults, isPending: isPendingQC } =
    trpc.order.productionPlates.uploadQCSynthesisResults.useMutation({
      onSuccess() {
        onSuccess();
      },
    });

  if (asQC) {
    return { isPending: isPendingQC, mutate: uploadQCSynthesisResults };
  }
  return { isPending, mutate: uploadSynthesisFile };
}

export default function UploadSynthesisFile({
  plate,
  asQC = false,
}: {
  asQC?: boolean;
  plate: PlateFromTRPC;
}) {
  const { id } = plate;
  const { toast } = useToast();
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const { mutate, isPending } = useUploadCallback(asQC, () => {
    toast({
      description: "The synthesis file was successfully uploaded",
      title: "Synthesis file uploaded",
      variant: "success",
    });
    setOpen(false);
  });

  const handleSubmit = () => {
    if (!file) {
      return;
    }
    const submit = async () => {
      const arrayBuffer = await file.arrayBuffer();
      const base64 = arrayBufferTobase64(arrayBuffer);
      mutate({
        file: base64,
        fileName: file.name,
        plateId: id,
      });
    };

    submit();
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <Button
          className="flex flex-row items-center space-x-1"
          variant={"secondary"}
        >
          <span>Upload synthesis file</span>
          {asQC && <span>for QC</span>}
          <Upload />
        </Button>
      </DialogTrigger>
      <DialogContent className="min-w-[700px]">
        <DialogHeader>
          <DialogTitle>Upload synthesis results</DialogTitle>
        </DialogHeader>
        <UploadExcelFile file={file} setFile={setFile} />
        <div className="flex flex-row justify-end space-x-2">
          <Button disabled={!file} isLoading={isPending} onClick={handleSubmit}>
            <SaveIcon />
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
