import { useEffect, useState } from "react";
import { useQueryParam, StringParam } from "use-query-params";

import { getSelectedWellsFromEdges } from "./selection";

const WellQueryKey = "well";

export function getUrlPointingToWell(url: string, well: string): string {
  const urlObj = new URL(url, window.location.href);
  urlObj.searchParams.set(WellQueryKey, well);
  return urlObj.toString();
}

export const useSelectWell = () => {
  const [wellSelected, setWellSelectedPrm] = useQueryParam(
    WellQueryKey,
    StringParam,
  );
  const setWellSelected = (
    value: string | null | ((old: string | null) => string | null | undefined),
  ) => {
    if (typeof value === "function") {
      setWellSelectedPrm((old) => value(old ?? null) ?? null);
      return;
    }
    setWellSelectedPrm(value ?? null);
  };
  const [wellsSelected, setWellsSelected] = useState<string[] | null>(null);

  const handleSelectWell = (wellIndex: string) => {
    setWellSelected((oldId) => (oldId === wellIndex ? null : wellIndex));
  };

  const handleShiftSelectWell = (wellIndex: string) => {
    if (!wellSelected) {
      handleSelectWell(wellIndex);
      return;
    }
    setWellsSelected(getSelectedWellsFromEdges(wellIndex, wellSelected));
    setWellSelected(null);
  };

  const handleCommandSelectWell = (wellIndex: string) => {
    if (!wellsSelected) {
      const newSelection = wellSelected
        ? wellIndex === wellSelected
          ? []
          : [wellIndex, wellSelected]
        : [wellIndex];
      setWellsSelected(newSelection);
      setWellSelected(null);
      return;
    }
    if (wellsSelected.includes(wellIndex)) {
      setWellsSelected(wellsSelected.filter((w) => w !== wellIndex));
      return;
    }
    setWellsSelected([...wellsSelected, wellIndex]);
  };

  const handleClickOnWell = (
    ev: React.MouseEvent<HTMLDivElement>,
    wellIndex: string,
  ) => {
    if (ev.shiftKey) {
      handleShiftSelectWell(wellIndex);
      return;
    }
    if (ev.metaKey || ev.ctrlKey) {
      handleCommandSelectWell(wellIndex);
      return;
    }
    handleSelectWell(wellIndex);
  };

  useEffect(() => {
    if (wellSelected && wellsSelected) {
      setWellsSelected(null);
    }
  }, [wellSelected, wellsSelected]);

  return {
    handleClickOnWell,
    setWellSelected,
    wellSelected,
    wellsSelected,
  };
};
