import type { CellContext, ColumnDef } from "@tanstack/react-table";
import { Info, Trash2 } from "lucide-react";
import type { ReactNode } from "react";
import { useEffect, useState } from "react";

import { BasicInput, PlacementInput, SequenceInput } from "./sequence-input";
import type { Oligo } from "./types";

import { TableActions } from "../../../../../components/ui/data-table/data-table-actions";
import { DataTableColumnHeader } from "../../../../../components/ui/data-table/data-table-column-header";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../../components/ui/tooltip";
import { cn } from "../../../../../lib/utils";
import { parseInitiatorDNASequence } from "../../../../../utils/parser";

export function EditableCell<T extends { locked?: boolean }>({
  cell,
  InputComponent = BasicInput,
  initialValue,
}: {
  InputComponent?: (p: {
    disabled?: boolean;
    onBlur: () => void;
    onChange: (text: string) => void;
    value: string;
  }) => ReactNode;
  cell: CellContext<T, unknown>;
  initialValue: string;
}) {
  const {
    row: { index, original },
    column: { id },
    table,
  } = cell;
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  // When the input is blurred, we'll call our table meta's updateData function
  const onBlur = () => {
    table.options.meta?.updateData?.(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <InputComponent
      disabled={original?.locked}
      onBlur={onBlur}
      onChange={setValue}
      value={value}
    />
  );
}

const Actions = ({
  disabled = false,
  handleDelete,
}: {
  disabled?: boolean;
  handleDelete: () => void;
}) => {
  return (
    <TableActions
      items={[
        {
          alertDialog: {
            message: "Are you sure you want to delete the oligo ?",
            title: "Delete oligo",
          },
          children: (
            <div className="flex w-full flex-row items-center gap-2">
              <Trash2 /> Delete Oligo
            </div>
          ),
          disabled,
          id: "delete oligo",
          onClick: handleDelete,
        },
      ]}
    />
  );
};

export const columnSequenceId = "sequence";
export const columnInitiatorDNAId = "initiatorDNA";

// eslint-disable-next-line react-refresh/only-export-components
export const getOligoColumns = (
  containsInitiatorDNA: boolean,
): ColumnDef<Oligo>[] => {
  const columns: ColumnDef<Oligo>[] = [
    {
      accessorFn: (_, index) => index + 1,
      cell: ({ row: { index } }) => index + 1,
      enableColumnFilter: false,
      header: ({ column, table }) => (
        <DataTableColumnHeader column={column} table={table} />
      ),
      id: "index",
      meta: {
        title: "#",
      },
    },
    {
      accessorKey: "name",
      cell: (info) => (
        <EditableCell cell={info} initialValue={info.row.original.name ?? ""} />
      ),
      header: ({ column, table }) => (
        <DataTableColumnHeader column={column} table={table} />
      ),
      id: "name",
      meta: {
        title: "Name",
      },
    },
    {
      accessorFn: (row) => {
        const { sequence } = parseInitiatorDNASequence(row.sequence ?? "");
        return sequence;
      },
      cell: (info) => {
        const { sequence } = parseInitiatorDNASequence(
          info.row.original.sequence ?? "",
        );
        return (
          <EditableCell
            InputComponent={SequenceInput}
            cell={info}
            initialValue={sequence}
          />
        );
      },
      header: ({ column, table }) => (
        <DataTableColumnHeader column={column} table={table} />
      ),
      id: columnSequenceId,
      meta: {
        title: "Sequence",
      },
    },
    {
      accessorKey: "placement",
      cell: (info) => {
        const placementToRows = info.table.options.meta?.placementToRows;
        const rowsWithSimilarHint = (
          placementToRows && info.row.original.placement
            ? placementToRows.get(info.row.original.placement) || []
            : []
        )
          .filter((index) => index !== info.row.index)
          .map((index) => index + 1)
          .join("-");

        const placement = info.row.original.placement || "";
        return (
          <div className="relative">
            <EditableCell
              InputComponent={PlacementInput}
              cell={info}
              initialValue={placement}
            />
            {rowsWithSimilarHint && (
              <div className={cn("absolute right-0 top-0")}>
                <Tooltip>
                  <TooltipTrigger type="button">
                    <Info size={20} />
                  </TooltipTrigger>
                  <TooltipContent>{`Some wells have the same placement hint: ${rowsWithSimilarHint.split("-")}`}</TooltipContent>
                </Tooltip>
              </div>
            )}
          </div>
        );
      },
      header: ({ column, table }) => (
        <DataTableColumnHeader column={column} table={table} />
      ),
      meta: {
        title: "Placement",
      },
      size: 40,
    },
    {
      cell: ({ row: { index, original }, table }) => {
        const handleDelete = () => {
          table.options.meta?.deleteData?.(index);
        };
        return (
          <Actions disabled={original.locked} handleDelete={handleDelete} />
        );
      },
      id: "actions",
    },
  ];
  if (containsInitiatorDNA) {
    columns.splice(2, 0, {
      accessorFn: (row) => {
        const { initiatorSequence } = parseInitiatorDNASequence(
          row.sequence ?? "",
        );
        return initiatorSequence;
      },
      cell: (info) => {
        const { initiatorSequence } = parseInitiatorDNASequence(
          info.row.original.sequence ?? "",
        );
        return (
          <EditableCell
            InputComponent={SequenceInput}
            cell={info}
            initialValue={initiatorSequence || ""}
          />
        );
      },
      header: ({ column, table }) => (
        <DataTableColumnHeader column={column} table={table} />
      ),
      id: columnInitiatorDNAId,
      meta: {
        title: "Initiator DNA",
      },
    });
  }
  return columns;
};
