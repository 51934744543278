import type { AdditionalService, RequestFormType } from "@console/shared";
import { SequenceShippingFormat } from "@console/shared";
import { Download, Check } from "lucide-react";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { Buffer } from "buffer";

import { Button } from "../../components/ui/button";
import { Progress } from "../../components/ui/progress";
import { trpc } from "../../config/trpc";
import { cn } from "../../lib/utils";
import { downloadBase64Excel } from "../../utils/useDownload";

function processFormSequence(formSequence: {
  additionalServices?: AdditionalService[] | undefined;
  format?: SequenceShippingFormat | undefined;
  name?: string | undefined;
  sequence?: string | undefined;
}) {
  return {
    format: formSequence.format ?? SequenceShippingFormat.Plate,
    name: formSequence.name ?? "",
    sequence: formSequence.sequence ?? "",
  };
}

export default function DownloadExcelAndVerify({
  isVerified,
  isRemoteChecking,
  progress,
  setIsVerified,
  templateUrl,
}: {
  isRemoteChecking: boolean;
  isVerified: boolean;
  progress: number;
  setIsVerified: (value: boolean) => void;
  templateUrl: string;
}) {
  const [isDownloading, setIsDownloading] = useState(false);
  const form = useFormContext<RequestFormType>();
  const formValue = useWatch({
    control: form.control,
  });
  const formString = JSON.stringify(formValue);

  useEffect(() => {
    if (!isVerified) {
      return;
    }
    setIsVerified(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formString]);

  const { mutate: generateExcelFile } =
    trpc.screening.generateExcelFile.useMutation({
      onSuccess(data) {
        downloadBase64Excel(data, "request.xlsx");
        setIsDownloading(false);
      },
    });

  const handleDownload = () => {
    fetch(templateUrl).then(async (response) => {
      const blob = await response.blob();
      const buffer = Buffer.from(await blob.arrayBuffer());
      const template = buffer.toString("base64");
      setIsDownloading(true);

      generateExcelFile({
        geneFragments: (formValue.geneFragments ?? []).map(processFormSequence),
        oligos: (formValue.oligos ?? []).map(processFormSequence),
        template,
      });
    });
  };

  return (
    <div className="flex flex-row items-center justify-end space-x-2">
      <Button
        className={cn("space-x-1", isVerified && "text-green-500")}
        isLoading={form.formState.isValidating || isRemoteChecking}
        type="submit"
        variant={"outline"}
      >
        <Check />
        <span>{isVerified ? "Checked" : "Check sequences"}</span>
      </Button>
      <Button
        className="w-52 space-x-1"
        disabled={!isVerified}
        isLoading={isDownloading}
        onClick={handleDownload}
        type="button"
      >
        <Download />
        <span>Download excel request</span>
      </Button>

      {isRemoteChecking && (
        <Progress indicatorClassName="bg-green-400" value={progress} />
      )}
    </div>
  );
}
