import { useState, useEffect } from "react";

import type { FillAndFinishSingleMapping } from "./reducer";
import { MINIMUM_SHIPPING_MASS } from "./reducer";
import useUpdateItemVolume from "./useUpdateItemVolume";

import { Input } from "../../../../../components/ui/input";
import type { TubeItem } from "../types";

export default function ShippingTube({
  item,
  wellMapping,
  isFnFCompleted,
}: {
  isFnFCompleted: boolean;
  item: TubeItem;
  wellMapping: FillAndFinishSingleMapping;
}) {
  const oligo = item.oligos.find(
    (o) => o.placement?.wellId === wellMapping.productionWellId,
  );
  const productionVolume = oligo?.qcResults?.runningVolume ?? 0;
  const productionConcentration = oligo?.qcResults?.concentration ?? 0;
  const productionMass = productionVolume * productionConcentration;
  const shippingVolume = wellMapping.volume;
  const minimalVolume =
    (MINIMUM_SHIPPING_MASS / productionMass) * productionVolume;
  const [localVolume, setLocalVolume] = useState<number>(shippingVolume);
  const localMass = localVolume * productionConcentration;

  const updateTubeVolume = useUpdateItemVolume(wellMapping.productionWellId);

  const handleUpdateTubeVolume = () => {
    updateTubeVolume({
      itemId: item.id,
      orderId: item.orderId,
      volume: localVolume,
    });
  };

  const handleUpdateTubeMass = () => {
    updateTubeVolume({
      itemId: item.id,
      orderId: item.orderId,
      volume: localMass / productionConcentration,
    });
  };

  useEffect(() => {
    setLocalVolume(shippingVolume);
  }, [shippingVolume]);

  if (!oligo) {
    return null;
  }

  return (
    <li className="grid grid-cols-3 items-center gap-2" key={item.id}>
      <p>{item.tubeName}</p>
      <Input
        className="no-spinner aspect-square p-1 text-sm"
        disabled={isFnFCompleted}
        max={Math.floor(productionVolume)}
        min={Math.ceil(minimalVolume)}
        onBlur={() => {
          handleUpdateTubeVolume();
        }}
        onChange={(e) => {
          setLocalVolume(Number(e.target.value));
        }}
        placeholder="volume"
        type="number"
        value={Math.round(localVolume)}
      />
      <Input
        className="aspect-square p-1 text-sm"
        disabled={isFnFCompleted}
        max={Math.floor(productionMass)}
        min={MINIMUM_SHIPPING_MASS}
        onBlur={() => {
          handleUpdateTubeMass();
        }}
        onChange={(e) => {
          const newMass = Number(e.target.value);
          const newVolume = newMass / productionConcentration;
          setLocalVolume(newVolume);
        }}
        placeholder="mass"
        type="number"
        value={Math.round(localMass)}
      />
    </li>
  );
}
