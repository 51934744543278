import { TooltipInfo } from "../../../../../components/ui/tooltip";
import { cn } from "../../../../../lib/utils";
import type { QcResults } from "../oligos/components/oligo-row";

export function AbiSummary({ qcResults }: QcResults) {
  if (!qcResults) {
    return <span>-</span>;
  }
  const { abiPurity, abiComment, isABIPassing } = qcResults;
  const abiText = abiPurity ? abiPurity.toFixed(0) : "-";
  return (
    <div
      className={cn(
        "relative flex flex-row items-start space-x-1",
        !isABIPassing && "text-orange-400",
      )}
    >
      <span>{abiText}</span>
      {abiComment && <TooltipInfo text={abiComment} />}
    </div>
  );
}

export function LCMSSummary({ qcResults }: QcResults) {
  if (!qcResults?.lcmsConclusion) {
    return <span>-</span>;
  }
  const { lcmsConclusion, lcmsIsPass } = qcResults;
  return (
    <div
      className={cn(
        "relative flex flex-row items-start space-x-1",
        !lcmsIsPass && "text-orange-400",
      )}
    >
      {typeof lcmsIsPass === "boolean" && <span>{lcmsIsPass.toString()}</span>}
      <TooltipInfo text={lcmsConclusion} />
    </div>
  );
}
