import { Upload } from "lucide-react";
import { useDropzone } from "react-dropzone";

import { Button } from "./button";

export default function UploadCSVFile({
  setFile,
  file,
}: {
  file: File | null;
  setFile: (file: File) => void;
}) {
  const onDrop = (acceptedFiles: File[]) => {
    setFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/csv": [".csv"],
    },
    multiple: false,
    noDrag: false,
    onDrop,
    useFsAccessApi: false,
  });

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Button className="space-x-2" variant={"secondary"}>
          <span>Upload file</span>
          <Upload />
        </Button>
      </div>
      {file && (
        <p className="flex flex-row space-x-1">
          <span>File uploaded: </span>
          <span className="italic">{file.name}</span>
        </p>
      )}
    </>
  );
}
