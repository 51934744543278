import type { PlateFromTRPC } from "./types";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../../components/ui/select";
import { trpc } from "../../../../../../config/trpc";
import { useIsServiceEnabled } from "../../../../../../containers/user/hooks";

const StorageLocations = [
  "SP-1 -20°C CTA",
  "SP-2 +4°C Analytics",
  "SP-3 -20°C NGS",
];

export const SimpleStorageLocation = ({
  storageLocation,
  plateId,
  onSuccess,
}: {
  onSuccess: (newStorage: string) => void;
  plateId: string;
  storageLocation?: string | undefined;
}) => {
  const utils = trpc.useUtils();
  const { mutate: changeStorageLocation } =
    trpc.order.productionPlates.store.useMutation({
      onSuccess(_, variables) {
        onSuccess(variables.storageLocation);
        utils.plate.get.invalidate(plateId);
      },
    });

  return (
    <Select
      onValueChange={(value) => {
        changeStorageLocation({
          plateId: plateId,
          storageLocation: value,
        });
      }}
      value={storageLocation}
    >
      <SelectTrigger className="max-w-[150px]">
        <SelectValue placeholder="Set storage" />
      </SelectTrigger>
      <SelectContent>
        {StorageLocations.map((storageLocation) => (
          <SelectItem key={storageLocation} value={storageLocation}>
            {storageLocation}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default function StorageLocation({ plate }: { plate: PlateFromTRPC }) {
  const isServiceEnabled = useIsServiceEnabled();
  const isProduction = isServiceEnabled && !plate.stepId;

  const utils = trpc.useUtils();
  const onSuccess = (newStorage: string) => {
    utils.plate.get.setData(plate.id, (prev) => {
      if (!prev) {
        return undefined;
      }
      return {
        ...prev,
        storageLocation: newStorage,
      };
    });
  };

  if (!isProduction) {
    return null;
  }

  return (
    <SimpleStorageLocation
      onSuccess={onSuccess}
      plateId={plate.id}
      storageLocation={plate.storageLocation}
    />
  );
}
