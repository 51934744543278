import { OligoStatus, OrderItemType, OrderStatus } from "@console/shared";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Link, useParams } from "react-router-dom";

import CompleteFillAndFinish from "./components/complete-fill-and-finish";
import CompleteOrder from "./components/complete-order";
import OrderCompletionGraph from "./components/order-completion-graph";
import { OrderEventComponent } from "./components/order-event";
import { ItemSubRow, oligoColumns } from "./components/order-item-rows";
import { OrderStatusComponent } from "./components/order-status";
import ReplaceOrderContent from "./components/replace-order-content";
import NewFillAndFinishButton from "./fillAndFinish/new-fill-and-finish-button";
import type { OrderDetailsTRPC } from "./types";

import { EditableText } from "../../../../components/logic/editable-text";
import { Button } from "../../../../components/ui/button";
import {
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  Card,
} from "../../../../components/ui/card";
import { DataTable } from "../../../../components/ui/data-table/data-table";
import { SimpleTable } from "../../../../components/ui/simple-table";
import { trpc } from "../../../../config/trpc";
import { OrganizationRoutes } from "../../organization-routes";

type HistoryEvent = OrderDetailsTRPC["history"][number];

const columnHelper = createColumnHelper<HistoryEvent>();

const historyColumns = [
  columnHelper.accessor("state", {
    cell: (row) => OrderEventComponent[row.getValue()],
    header: "State",
  }),
  columnHelper.accessor("enteredAt", {
    cell: (row) => new Date(row.getValue()).toLocaleString(),
    header: "Date",
  }),
  columnHelper.accessor("name", {
    cell: (row) => row.getValue(),
    header: "User",
  }),
];

function useUpdateHubspotDealId(orderId: string) {
  const utils = trpc.useUtils();
  const { mutate } = trpc.order.update.hubspotQuoteId.useMutation({
    onSuccess(_, variables) {
      utils.order.read.setData(orderId, (prev) => {
        if (!prev) return undefined;
        return {
          ...prev,
          hubspotQuoteId: variables.hubspotQuoteId,
        };
      });
    },
  });

  return (hubspotQuoteId: string) =>
    mutate({ hubspotQuoteId: hubspotQuoteId, id: orderId });
}

function OrderDetails({ data }: { data: OrderDetailsTRPC }) {
  const {
    status,
    netsuiteSalesOrderId,
    hubspotQuoteId,
    items,
    history,
    skus,
    id,
    expectedCompletionDate,
    hasFillAndFinish,
  } = data;

  const orderHistoryTable = useReactTable({
    columns: historyColumns,
    data: history,
    getCoreRowModel: getCoreRowModel(),
  });

  const isOpportunity = status === OrderStatus.Opportunity;
  const itemStatuses = items.map((i) => i.itemStatus);
  const isOrderReadyForFillAndFinish =
    items.every((item) =>
      [OligoStatus.Liberated, OligoStatus.Failed].includes(item.itemStatus),
    ) && !hasFillAndFinish;
  const isOrderReadyForFillAndFinishCompletion =
    hasFillAndFinish && status !== OrderStatus.Shipping;
  const isOrderReadyForCompletion = status === OrderStatus.Shipping;

  const updateHubspotDealId = useUpdateHubspotDealId(id);

  return (
    <div className="grid grid-cols-2 gap-2">
      <Card>
        <CardHeader>
          <CardTitle>{`Order ${hubspotQuoteId}`}</CardTitle>
          <CardDescription>The details of the order</CardDescription>
        </CardHeader>
        <CardContent className="flex flex-col space-y-2">
          <div className="flex flex-row items-start justify-between">
            <div>
              <p className="italic">Hubspot quote</p>
              <EditableText
                defaultValue={hubspotQuoteId}
                disabled={!isOpportunity}
                onSubmit={(newHubspotId) => {
                  updateHubspotDealId(newHubspotId);
                }}
              />
            </div>
            <div>
              <p className="italic">Netsuite SO#</p>
              <p>{netsuiteSalesOrderId ?? "-"}</p>
            </div>
            <div>
              <p className="italic">Expected completion</p>
              <p>
                {expectedCompletionDate
                  ? new Date(expectedCompletionDate).toLocaleDateString()
                  : "-"}
              </p>
            </div>
            <div>
              <p className="italic">Status</p>
              <p>{OrderStatusComponent[status]}</p>
            </div>
            <div>
              <p className="italic">Items</p>
              <p>{items.length}</p>
            </div>
          </div>
          <div>
            <h3 className="font-semibold">SKUs</h3>
            <ul>
              {skus
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .sort(([_, sku1], [_2, sku2]) => sku2.priority - sku1.priority)
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .filter(([_, skuValue]) => skuValue.count > 0)
                .map(([sku, skuValue]) => (
                  <li
                    key={sku}
                  >{`${sku}: ${skuValue.count} ${skuValue.priority >= 0 ? "nts" : ""}`}</li>
                ))}
            </ul>
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>History</CardTitle>
        </CardHeader>
        <CardContent className="m-1 max-h-[30vh] overflow-scroll">
          <SimpleTable table={orderHistoryTable} />
        </CardContent>
      </Card>
      <Card className="col-span-2">
        <CardHeader className="flex flex-row justify-between">
          <CardTitle>Items</CardTitle>
          <div className="flex flex-row space-x-2">
            {isOpportunity && <ReplaceOrderContent id={id} />}
            {isOrderReadyForFillAndFinish && (
              <NewFillAndFinishButton order={data} />
            )}
            {isOrderReadyForFillAndFinishCompletion && (
              <CompleteFillAndFinish orderId={id} />
            )}
            {hasFillAndFinish && (
              <Link
                to={OrganizationRoutes.SERVICE_ORDER_FNF.replace(
                  ":orderId",
                  id,
                )}
              >
                <Button variant={"outline"}>See Fill and Finish</Button>
              </Link>
            )}
            {isOrderReadyForCompletion && <CompleteOrder orderId={id} />}
          </div>
        </CardHeader>
        <CardContent className="space-y-2">
          <OrderCompletionGraph itemStatuses={itemStatuses} />
          <h3 className="font-semibold">Oligos</h3>
          <DataTable
            CustomSubRow={({ row }) => <ItemSubRow row={row.original} />}
            columns={oligoColumns}
            data={items.filter((i) => i.type === OrderItemType.OligoSet)}
            getRowCanExpand={(row) => row.original.oligos.length > 0}
            paginateExpandedRows={false}
            useBorders={false}
          />
        </CardContent>
      </Card>
    </div>
  );
}

export default function Order() {
  const { orderId } = useParams();

  const { data } = trpc.order.read.useQuery(orderId ?? "");

  if (!data) {
    return null;
  }

  return <OrderDetails data={data} />;
}
