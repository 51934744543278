import { InstrumentStatus } from "@console/shared";
import { Heading } from "@radix-ui/themes";
import { Download } from "lucide-react";
import { useState } from "react";
import { v4 } from "uuid";
import { z } from "zod";

import { statusMap } from "./components/status-map";
import { useGetInstrument } from "./useGetInstrument";

import DateRangePicker from "../../components/logic/date-range-picker";
import { Button } from "../../components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../components/ui/tooltip";
import { useToast } from "../../components/ui/use-toast";
import { trpc } from "../../config/trpc";
import { useDownloadFile } from "../../utils/useDownload";

const logsRequestSchema = z.object({
  range: z
    .object({
      end: z.date().optional(),
      start: z.date().optional(),
    })
    .refine(
      (data) => (data.start && data.end ? data.start <= data.end : true),
      {
        message: "Start date must be before end date",
      },
    ),
});

type LogsRequest = z.infer<typeof logsRequestSchema>;

export default function InstrumentTroubleshoot() {
  const [loading, setLoading] = useState(false);
  const [requestId, setRequestId] = useState<string | null>(null);
  const { data: instrument } = useGetInstrument();
  const { toast } = useToast();
  const downloadFile = useDownloadFile();

  trpc.instrument.syntax.subscribeToLogs.useSubscription(
    { requestId: requestId ?? "" },
    {
      enabled: !!requestId,
      onData(response) {
        (async () => {
          try {
            if ("errors" in response) {
              throw new Error(response.errors.join(", "));
            }

            if (!response.url) {
              throw new Error("No logs found");
            }

            await downloadFile(response.url, "logs.zip");
            toast({
              description:
                "The logs have been downloaded. Please check your Downloads folder.",
              title: "Success",
              variant: "success",
            });
          } catch (error) {
            const message =
              "message" in (error as any) ? (error as any).message : "";
            toast({
              description: message,
              title: "Error occurred while downloading the logs",
              variant: "destructive",
            });
          } finally {
            setLoading(false);
          }
        })();
      },
    },
  );

  const { mutate: downloadLogs, isPending } =
    trpc.instrument.syntax.downloadLogs.useMutation({
      onError(error) {
        setLoading(false);
        toast({
          description: error.message,
          title: "Error",
          variant: "destructive",
        });
      },
    });

  function onSubmit(data: LogsRequest) {
    const instrumentId = instrument?.id;
    if (!instrumentId) {
      return;
    }
    const tzOffset = new Date().getTimezoneOffset() * 60000;
    const startDate = data.range.start
      ? new Date(data.range.start?.getTime() - tzOffset).toISOString()
      : undefined;
    const endDate = data.range.end
      ? new Date(data.range.end?.getTime() - tzOffset).toISOString()
      : undefined;
    const uniqueRequestId = v4();
    setRequestId(uniqueRequestId);

    setLoading(true);
    downloadLogs({
      endDate,
      instrumentId,
      requestId: uniqueRequestId,
      startDate,
    });
  }

  if (!instrument) {
    return <Heading>Instrument not found</Heading>;
  }
  const isDisconnected = instrument.status === InstrumentStatus.Unavailable;

  return (
    <div className="flex flex-col space-y-8">
      <div className="flex flex-row justify-between">
        <Heading className="space-x-2">
          <span>Instrument</span>
          <span className="italic">{instrument.name}</span>
          <span>troubleshooting</span>
        </Heading>
        <div className="flex flex-row items-center space-x-2">
          <p>{instrument.status}</p>
          {statusMap[instrument.status]}
        </div>
      </div>
      <div className="bg-card space-y-2 rounded-lg border p-4">
        <Heading>Download logs</Heading>
        <DateRangePicker onSubmit={onSubmit}>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                aria-label="Download logs"
                disabled={isDisconnected || isPending || loading}
                id="download-logs"
                isLoading={loading}
                type="submit"
                variant={"secondary"}
              >
                <Download />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Download logs</TooltipContent>
          </Tooltip>
        </DateRangePicker>
      </div>
    </div>
  );
}
