import { Button } from "@frontend/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@frontend/components/ui/dialog";
import { Label } from "@frontend/components/ui/label";
import SelectWithSearch from "@frontend/components/ui/select-with-search";
import { LoadingSpinner } from "@frontend/components/ui/spinner";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@frontend/components/ui/tooltip";
import { useToast } from "@frontend/components/ui/use-toast";
import { OrganizationRoutes } from "@frontend/routes/organization/organization-routes";
import { HardDriveUpload, X } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { trpc } from "../../../../../config/trpc";
import { cn } from "../../../../../lib/utils";

export default function AddConstructToAssay({
  constructId,
  isMenu,
}: {
  constructId: string;
  isMenu: boolean;
}) {
  const [open, setOpen] = useState(false);

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <Tooltip>
        <TooltipTrigger asChild>
          <DialogTrigger asChild>
            {isMenu ? (
              <div className="flex flex-row items-center space-x-1">
                <HardDriveUpload />
                <span>Add to assay</span>
              </div>
            ) : (
              <Button className="space-x-1" variant={"outline"}>
                <span>Add to assay</span>
                <HardDriveUpload />
              </Button>
            )}
          </DialogTrigger>
        </TooltipTrigger>
      </Tooltip>
      <AddConstructToAssayDialog constructId={constructId} setOpen={setOpen} />
    </Dialog>
  );
}

function AddConstructToAssayDialog({
  constructId,
  setOpen,
}: {
  constructId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [selectedAssayId, setSelectedAssayId] = useState("");
  const { data: assays } = trpc.assay.list.useQuery();
  const availableAssays = assays ?? [];

  const { mutate: createAssay, isPending: createIsPending } =
    trpc.assay.createFromConstruct.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "An error occurred while creating the assay",
          variant: "destructive",
        });
      },
      onSuccess(newAssayId) {
        setOpen(false);
        toast({
          description: "Your assay was created successfully",
          title: "Assay created",
          variant: "default",
        });
        navigate(OrganizationRoutes.ASSAY.replace(":assayId", newAssayId));
      },
    });

  const { mutate: addToAssay, isPending: addingIsPending } =
    trpc.assay.addConstructs.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "An error occurred while updating the assay",
          variant: "destructive",
        });
      },
      onSuccess() {
        setOpen(false);
        toast({
          description: "Assay was updated successfully",
          title: "Assay updated",
          variant: "default",
        });
        navigate(OrganizationRoutes.ASSAY.replace(":assayId", selectedAssayId));
      },
    });

  const isPending = createIsPending || addingIsPending;
  const noAvailableAssays = availableAssays.length === 0;

  const handleSubmitForm = () => {
    if (selectedAssayId) {
      addToAssay({
        assayId: selectedAssayId,
        constructIds: [constructId],
      });
      return;
    }
    createAssay(constructId);
  };

  return (
    <DialogContent
      className={cn(
        "flex flex-col space-y-2",
        isPending && "pointer-events-none opacity-50",
      )}
      onEscapeKeyDown={(e) => {
        e.preventDefault();
      }}
    >
      {isPending && (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
          <LoadingSpinner />
        </div>
      )}
      <DialogHeader>
        <DialogTitle>Add construct to assay</DialogTitle>
        <DialogDescription>
          Change existing assay or create a new one
        </DialogDescription>
      </DialogHeader>
      <div>
        <Label htmlFor="assay-selection">
          Choose assay to add construct to or leave it empty to create a new
          assay
        </Label>
        <div className="flex items-center space-x-2">
          <SelectWithSearch
            inputTitle={""}
            options={
              assays?.map((assay) => ({
                id: assay.id,
                name: assay.name,
              })) || []
            }
            setValue={setSelectedAssayId}
            value={selectedAssayId}
          />
          <Button
            disabled={noAvailableAssays || isPending || !selectedAssayId}
            onClick={() => setSelectedAssayId("")}
            variant={"outline"}
          >
            <X />
          </Button>
        </div>
      </div>
      <DialogFooter>
        <Tooltip>
          <TooltipTrigger>
            <Button
              aria-label="Create assay"
              onClick={handleSubmitForm}
              type="submit"
            >
              Submit
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            {selectedAssayId
              ? "Add construct to selected assay"
              : "Create assay"}
          </TooltipContent>
        </Tooltip>
      </DialogFooter>
    </DialogContent>
  );
}
