export enum PlateSize {
  S384 = "384",
  S96 = "96",
}

export enum Normalization {
  Lowest = "Lowest",
  None = "None",
  Target = "Target",
}

export enum WellErrorSeverity {
  Error = "error",
  Warning = "warning",
}

export enum RunState {
  Canceled = "Canceled",
  Completed = "Completed",
  Failed = "Failed",
  Loaded = "Loaded",
  Printing = "Printing",
  Queued = "Queued",
  Unknown = "Unknown",
}

export enum PlateErrorType {
  Biosecurity = "Biosecurity",
  Cycles = "Cycles",
  Modifiers = "Modifiers",
}

export enum PlateErrorSeverity {
  Error = "error",
  Warning = "warning",
}

export enum WellErrorType {
  Biosecurity = "Biosecurity",
  Click = "Click",
  Length = "Length",
  Purity = "Purity",
  UnknownNucleotide = "UnknownNucleotide",
}

export enum ModificationStatus {
  Acceptable = "Acceptable",
  Low = "Low",
  NonQuantifiable = "NonQuantifiable",
  None = "None",
  Successful = "Successful",
}

export enum RunReportFileFormat {
  CSV = "CSV",
  Excel = "Excel",
  JSON = "JSON",
}

export enum PQA {
  HighPlex = "HighPlex",
  Labels = "Labels",
  Standard = "Standard",
}

export enum PlateStatus {
  InQC = "InQC",
  QCCompleted = "QCCompleted",
  AwaitingQC = "AwaitingQC",
  Canceled = "Canceled",
  Loaded = "Loaded",
  Printing = "Printing",
  QueuedOnInstrument = "QueuedOnInstrument",
  Synthesized = "Synthesized",
  ToBeAssigned = "ToBeAssigned",
  Failed = "Failed",
}

export enum PlateEventType {
  Canceled = "Canceled",
  Created = "Created",
  InstrumentAssigned = "InstrumentAssigned",
  LoadOnInstrument = "LoadOnInstrument",
  LostSyntaxConnection = "LostSyntaxConnection",
  QCPlateCreated = "QCPlateCreated",
  Stored = "Stored",
  RemovedFromQueue = "RemovedFromQueue",
  RunCompleted = "RunCompleted",
  MarkedAsFailed = "MarkedAsFailed",
  RunFailed = "RunFailed",
  RunStarted = "RunStarted",
  UnloadFromInstrument = "UnloadFromInstrument",
  UploadQCQuantificationResults = "UploadQCQuantificationResults",
  UploadQCABIResults = "UploadQCABIResults",
  UploadQCOP2Results = "UploadQCOP2Results",
  UploadQCNGSResults = "UploadQCNGSResults",
  UploadQCLCMSResults = "UploadQCLCMSResults",
}

export const ModificationStatusToLabel: Record<ModificationStatus, string> = {
  [ModificationStatus.Acceptable]: "Acceptable Coupling Efficiency",
  [ModificationStatus.Low]: "Low Coupling Efficiency",
  [ModificationStatus.NonQuantifiable]: "Non Quantifiable",
  [ModificationStatus.None]: "No Modification",
  [ModificationStatus.Successful]: "Successful Coupling Efficiency",
};
