import { getOligoAllowedRange, useGetLocalVolumesAndMass } from "./helpers";
import type { FillAndFinishMapping } from "./reducer";
import { MINIMUM_SHIPPING_MASS } from "./reducer";
import ShippingTube from "./shipping-tube";

import { Input } from "../../../../../components/ui/input";
import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";
import type { OrderDetailsTRPC, TubeItem } from "../types";

export default function ShippingTubes({
  data,
  tubeItems,
  mapping,
}: {
  data: OrderDetailsTRPC;
  mapping: FillAndFinishMapping;
  tubeItems: TubeItem[];
}) {
  const { id, fillAndFinish } = data;
  const isFnFCompleted = fillAndFinish?.isCompleted ?? false;
  const { toast } = useToast();
  const utils = trpc.useUtils();
  const { mutate: updateTubesVolume } =
    trpc.order.fillAndFinish.updateTubesVolume.useMutation({
      onSuccess() {
        toast({
          title: "Updated tubes volume",
          variant: "success",
        });
        utils.order.read.invalidate(id);
      },
    });
  const { mutate: updateTubesMass } =
    trpc.order.fillAndFinish.updateTubesMass.useMutation({
      onSuccess() {
        toast({
          title: "Updated tubes mass",
          variant: "success",
        });
        utils.order.read.invalidate(id);
      },
    });

  const handleUpdateTubesVolume = (volume: number) => {
    updateTubesVolume({
      orderId: id,
      volume,
    });
  };

  const handleUpdateTubesMass = (mass: number) => {
    updateTubesMass({
      mass,
      orderId: id,
    });
  };

  const mappedWells = mapping.map((m) => m.productionWellId);
  const tubeOligos = tubeItems
    .flatMap((i) => i.oligos)
    .filter((o) => mappedWells.includes(o.placement?.wellId ?? ""));
  const tubeOligoVolumes = tubeOligos.map((o) =>
    getOligoAllowedRange(
      o,
      mapping.find((m) => m.productionWellId === o.placement?.wellId)?.volume ??
        0,
    ),
  );
  const {
    minShippingVolumeCommonToEachWell,
    maxShippingVolumeCommonToEachWell,
    maximumMass,
    localCommonVolume,
    setLocalCommonVolume,
    localCommonMass,
    setLocalCommonMass,
  } = useGetLocalVolumesAndMass(tubeOligoVolumes);

  if (tubeItems.length === 0) {
    return "No tubes";
  }

  return (
    <div className="space-y-2">
      <h3 className="text-md font-bold">Tubes</h3>
      <li className="grid grid-cols-3 items-center gap-2">
        <p>Name</p>
        <p>Volume</p>
        <p>Mass</p>
        <p className="font-bold">Common</p>
        <div className="flex flex-col">
          <span className="text-sm">{`${Math.ceil(minShippingVolumeCommonToEachWell)}μL - ${Math.floor(
            maxShippingVolumeCommonToEachWell,
          ).toFixed(0)}μL`}</span>
          <Input
            className=" p-1 text-sm"
            disabled={isFnFCompleted}
            max={Math.floor(maxShippingVolumeCommonToEachWell)}
            min={Math.ceil(minShippingVolumeCommonToEachWell)}
            onBlur={(e) => {
              handleUpdateTubesVolume(Number(e.target.value));
            }}
            onChange={(e) => {
              setLocalCommonVolume(Number(e.target.value));
            }}
            placeholder="Tubes volume"
            type="number"
            value={
              localCommonVolume ? Math.round(localCommonVolume ?? 0) : undefined
            }
          />
        </div>
        <div className="flex flex-col">
          <span className="text-sm">{`${Math.ceil(MINIMUM_SHIPPING_MASS)}pmol - ${Math.floor(
            maximumMass,
          ).toFixed(0)}pmol`}</span>
          <Input
            className="p-1 text-sm"
            disabled={isFnFCompleted}
            max={Math.floor(maximumMass)}
            min={MINIMUM_SHIPPING_MASS}
            onBlur={(e) => {
              handleUpdateTubesMass(Number(e.target.value));
            }}
            onChange={(e) => {
              setLocalCommonMass(Number(e.target.value));
            }}
            placeholder="Tubes mass"
            type="number"
            value={
              localCommonMass ? Math.round(localCommonMass ?? 0) : undefined
            }
          />
        </div>
      </li>
      <ul className="list-inside list-disc space-y-2">
        {tubeItems.map((item) => {
          const wellMapping = mapping.find((m) =>
            item.oligos.some((o) => o.placement?.wellId === m.productionWellId),
          );
          if (!wellMapping) {
            return null;
          }
          return (
            <ShippingTube
              isFnFCompleted={isFnFCompleted}
              item={item}
              key={item.id}
              wellMapping={wellMapping}
            />
          );
        })}
      </ul>
    </div>
  );
}
