import type { FC } from "react";
import { useEffect, useReducer, useRef, useState } from "react";

import { BlocksPreview } from "./blockPreview";
import { BlocksView } from "./blocks";
import { ErrorsTooltip } from "./common";
import {
  useGeneDesignScroll,
  useKeyboardController,
  useMouseController,
} from "./customHooks";
import { OligosView } from "./oligos";

import "./geneDesignView.css";
import { Button } from "../../../../../../../components/ui/button";
import { MenubarShortcut } from "../../../../../../../components/ui/menubar";
import useZoom from "../../../../build/components/useZoom";
import { GeneDesignConfiguration } from "../logic/configuration";
import type { GeneDesignConfigurationType } from "../logic/configuration";
import {
  GeneDesignActionType,
  geneDesignInitialReducerState,
  geneDesignReducer,
} from "../logic/reducer";
import type { GeneDesign, GeneDesignParameters } from "../logic/types";

const zoomConfig = {
  defaultZoom: GeneDesignConfiguration.nuclWidth,
  maxZoom: 50,
  minZoom: GeneDesignConfiguration.nuclWidth,
  step: 5,
};
const GeneDesignView: FC<{
  geneDesign: GeneDesign;
  isUpdating?: boolean;
  onSubmit: (
    geneDesign: GeneDesign,
    validationParameters: GeneDesignParameters,
    errors?: { [key: string]: string },
  ) => void;
  parameters?: GeneDesignParameters;
}> = ({ geneDesign, onSubmit, parameters, isUpdating }) => {
  const timeoutRef = useRef<number | null>(null);
  const [
    {
      currentLocalTemporaryDesign: data,
      activeBlock,
      validationErrors,
      parameters: validationParameters,
    },
    dispatch,
  ] = useReducer(
    geneDesignReducer,
    structuredClone({
      ...geneDesignInitialReducerState,
      data: geneDesign,
      initialData: structuredClone(geneDesign),
    }),
  );
  const [configuration, setConfiguration] =
    useState<GeneDesignConfigurationType>({ ...GeneDesignConfiguration });

  const { zoom, zoomElement } = useZoom(zoomConfig);
  useKeyboardController(dispatch);
  useMouseController(dispatch, configuration.nuclWidth);

  const startPosition: number = data?.blocks[activeBlock ?? -1]?.start ?? -1;
  const {
    blocksScrollRef,
    oligosScrollRef,
    previewScrollRef,
    scrollPreviewToPosition,
  } = useGeneDesignScroll(startPosition, configuration);

  useEffect(() => {
    dispatch({ payload: geneDesign, type: GeneDesignActionType.SET_GENE_DATA });
  }, [geneDesign]);

  useEffect(() => {
    if (parameters) {
      dispatch({
        payload: parameters,
        type: GeneDesignActionType.SET_GENE_PARAMETERS,
      });
    }
  }, [parameters]);

  useEffect(() => {
    timeoutRef.current = window.setTimeout(() => {
      setConfiguration((old) => ({
        ...old,
        nuclWidth: zoom ?? GeneDesignConfiguration.nuclWidth,
      }));
    }, 200);

    return () => {
      clearTimeout(timeoutRef.current ?? 0);
    };
  }, [zoom]);

  const setActiveBlock = (blockIndex: number) => {
    if (data && blockIndex >= 0) {
      scrollPreviewToPosition(
        (data.blocks?.[blockIndex]?.start ?? 0) * configuration.nuclWidth,
      );
    }
    dispatch({
      payload: blockIndex,
      type: GeneDesignActionType.SET_ACTIVE_BLOCK,
    });
  };

  if (!data) {
    return null;
  }

  return (
    <div className="ml-auto mr-auto flex flex-col gap-4">
      <h1 className="flex justify-center gap-1 text-center text-2xl">
        <Button
          className="flex flex-row items-center space-x-1"
          onClick={() => {
            dispatch({ type: GeneDesignActionType.UNDO });
          }}
          variant="outline"
        >
          <span>Undo</span>
          <MenubarShortcut>⌘Z</MenubarShortcut>
        </Button>
        <Button
          className="flex flex-row items-center space-x-1"
          onClick={() => {
            dispatch({ type: GeneDesignActionType.REDO });
          }}
          variant="outline"
        >
          <span>Redo</span>
          <MenubarShortcut>⇧⌘Z</MenubarShortcut>
        </Button>
        <Button
          className="flex flex-row items-center space-x-1"
          onClick={() => {
            dispatch({ type: GeneDesignActionType.RESET });
          }}
          variant="outline"
        >
          <span>Reset</span>
          <MenubarShortcut>⌘R</MenubarShortcut>
        </Button>
        <div className="grow basis-0" />
        <div className="shrink basis-0">{zoomElement}</div>
      </h1>
      <BlocksPreview
        activeBlock={activeBlock}
        configuration={configuration}
        data={data}
        errors={validationErrors}
        ref={previewScrollRef}
        sequence={geneDesign.gene.sequence}
        setActiveBlock={setActiveBlock}
      />
      {activeBlock !== null ? (
        <>
          <OligosView
            activeBlock={activeBlock}
            configuration={configuration}
            data={data}
            errors={validationErrors}
            ref={oligosScrollRef}
            sequence={geneDesign.gene.sequence.slice(
              data.blocks[activeBlock].start,
              data.blocks[activeBlock].end,
            )}
          />
        </>
      ) : (
        <BlocksView
          configuration={configuration}
          data={data}
          errors={validationErrors}
          ref={blocksScrollRef}
          sequence={geneDesign.gene.sequence}
        />
      )}
      <div className="ml-auto flex gap-2">
        {!!validationErrors && <ErrorsTooltip errors={validationErrors} />}
        <Button
          className="ml-auto"
          disabled={!!validationErrors}
          isLoading={isUpdating}
          onClick={() => onSubmit(data, validationParameters, validationErrors)}
          variant="default"
        >
          {!!validationErrors ? "Remaining errors" : "Submit"}
        </Button>
      </div>
    </div>
  );
};

export default GeneDesignView;
