import type { ColumnDef } from "@tanstack/react-table";
import type { inferProcedureOutput } from "@trpc/server";
import { Download } from "lucide-react";
import { useState } from "react";

import type { PlateFromTRPC } from "./components/plate/types";

import { Button } from "../../../../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import { DataTable } from "../../../../components/ui/data-table/data-table";
import { DataTableColumnHeader } from "../../../../components/ui/data-table/data-table-column-header";
import { Input } from "../../../../components/ui/input";
import { Textarea } from "../../../../components/ui/textarea";
import type { AppRouter } from "../../../../config/trpc";
import { trpc } from "../../../../config/trpc";
import { useDownloadFile } from "../../../../utils/useDownload";

type PQAResults = inferProcedureOutput<AppRouter["assay"]["checkSupportPlate"]>;
type PQAYieldResult = PQAResults["yieldResults"][number];
type PQACouplingResult = PQAResults["couplingResults"][number];

const pqaYieldResultsColumns: ColumnDef<PQAYieldResult>[] = [
  {
    accessorKey: "constraint.name",
    cell: (info) => info.getValue(),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "name",
    meta: {
      title: "Name",
    },
  },
  {
    accessorKey: "yieldInfo.count",
    cell: (info) => info.getValue(),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "count",
    meta: {
      title: "Total wells count",
    },
  },
  {
    accessorKey: "yieldInfo.average",
    cell: ({ row }) => row.original.yieldInfo.average.toFixed(0),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "average",
    meta: {
      title: "Average yield (pmol)",
    },
  },
  {
    accessorKey: "yieldInfo.std",
    cell: ({ row }) => row.original.yieldInfo.std.toFixed(0),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "std",
    meta: {
      title: "Standard deviation",
    },
  },
  {
    accessorKey: "yieldInfo.cv",
    cell: ({ row }) => (row.original.yieldInfo.cv * 100).toFixed(1),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "cv",
    meta: {
      title: "Coefficient of variation (%)",
    },
  },
  {
    accessorKey: "yieldInfo.min",
    cell: ({ row }) => row.original.yieldInfo.min.toFixed(0),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "min",
    meta: {
      title: "Minimum (pmol)",
    },
  },
  {
    accessorKey: "yieldInfo.max",
    cell: ({ row }) => row.original.yieldInfo.max.toFixed(0),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "max",
    meta: {
      title: "Maximum (pmol)",
    },
  },
  {
    cell: ({ row }) => {
      const constraint = row.original.constraint;
      if (constraint.forInformationOnly) {
        return "for information";
      }
      return constraint.maxWellCountUnder;
    },
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "invalidAcceptable",
    meta: {
      title: "Acceptable invalid wells count",
    },
  },
  {
    cell: ({ row }) => {
      const constraint = row.original.constraint;
      if (constraint.forInformationOnly && constraint.threshold < 0) {
        return "for information";
      }
      return `< ${constraint.threshold}`;
    },
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "threshold",
    meta: {
      title: "Condition",
    },
  },
  {
    accessorKey: "countOfWellsBelowThreshold",
    cell: (info) => (
      <p
        className={
          info.row.original.isRespected ? "text-black" : "text-red-500"
        }
      >
        {info.row.original.countOfWellsBelowThreshold}
      </p>
    ),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "invalidCount",
    meta: {
      title: "Invalid wells count",
    },
  },
];

const pqaCouplingResultsColumns: ColumnDef<PQACouplingResult>[] = [
  {
    accessorKey: "wellIndex",
    cell: (info) => info.getValue(),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "wellIndex",
    meta: {
      title: "Well",
    },
  },
  {
    accessorKey: "label",
    cell: (info) => info.getValue(),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "label",
    meta: {
      title: "Label",
    },
  },
  {
    accessorKey: "couplingRate",
    cell: ({ row }) => (row.original.couplingRate * 100).toFixed(0),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "coupling",
    meta: {
      title: "Coupling %",
    },
  },
  {
    cell: ({ row }) => {
      const isRespected = row.original.isRespected;
      const level = row.original.level;
      return (
        <span className={`${isRespected ? "text-black" : "text-red-500"}`}>
          {level}
        </span>
      );
    },
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "couplingLevel",
    meta: {
      title: "Coupling Level",
    },
  },
];

function PQAReportSection({ plate }: { plate: PlateFromTRPC }) {
  const plateId = plate.id;
  const { comment, location, netsuiteTaskNumber } = plate.pqaInfo ?? {};
  const downloadFile = useDownloadFile();
  const { mutate: generateReport } = trpc.assay.generatePQAReport.useMutation({
    onSuccess(url) {
      downloadFile(url, "report.pdf");
    },
  });
  const [localComment, setLocalComment] = useState(comment);
  const [localLocation, setLocalLocation] = useState(location);
  const [localNetsuiteTaskNumber, setLocalNetsuiteTaskNumber] =
    useState(netsuiteTaskNumber);

  return (
    <>
      <div className="flex justify-end">
        <Button
          className="flex flex-row items-center space-x-1"
          onClick={() =>
            generateReport({
              comment: localComment,
              location: localLocation,
              netsuiteTaskNumber: localNetsuiteTaskNumber,
              plateId,
            })
          }
        >
          <span>Download report</span>
          <Download />
        </Button>
      </div>
      <h3 className="font-bold">Metadata</h3>
      <div className="grid grid-cols-2 gap-2 p-4">
        <Input
          onChange={(e) => setLocalLocation(e.target.value)}
          placeholder="Location"
          value={localLocation}
        />
        <Input
          onChange={(e) => setLocalNetsuiteTaskNumber(e.target.value)}
          placeholder="Netsuite task number"
          value={localNetsuiteTaskNumber}
        />
        <Textarea
          className="col-span-2"
          onChange={(e) => setLocalComment(e.target.value)}
          placeholder="Comment on the run"
          rows={3}
          value={localComment}
        />
      </div>
    </>
  );
}

function PQAResults({ plate }: { plate: PlateFromTRPC }) {
  const plateId = plate.id;

  const { data } = trpc.assay.checkSupportPlate.useQuery(plateId);

  if (!data) {
    return null;
  }

  const { yieldResults, couplingResults } = data;

  const uniqueCouplingLabels = Array.from(
    new Set(couplingResults.map((result) => result.label)),
  );

  return (
    <div className="flex flex-col space-y-2">
      <PQAReportSection plate={plate} />
      <h3 className="font-bold">Yields</h3>
      <DataTable
        columns={pqaYieldResultsColumns}
        data={yieldResults ?? []}
        disableQueryParams
        tableContainerClassName="nth-3:bg-red"
        useBorders={false}
        usePagination={false}
      />
      {couplingResults.length > 0 && (
        <>
          <h3 className="font-bold">Label efficiency</h3>
          <DataTable
            columns={pqaCouplingResultsColumns}
            data={couplingResults.flat()}
            disableQueryParams
            tableContainerClassName="nth-3:bg-red"
            useBorders={false}
            usePagination={false}
          />
          <div className="flex flex-row justify-end">
            <table className="table-auto p-4 text-center [&_td]:p-2">
              <thead>
                <tr>
                  <td>Well coupling criteria</td>
                  <td>Low</td>
                  <td>Acceptable</td>
                  <td>Successful</td>
                </tr>
              </thead>
              <tbody>
                {uniqueCouplingLabels.map((label) => {
                  const constraint = couplingResults.find(
                    (result) => result.label === label,
                  )?.constraint;
                  if (!constraint) {
                    return null;
                  }
                  return (
                    <tr key={label}>
                      <td>{label}</td>
                      <td>{`0% - ${constraint.acceptableThreshold * 100 - 1}%`}</td>
                      <td>{`${constraint.acceptableThreshold * 100}% - ${constraint.successfulThreshold * 100 - 1}%`}</td>
                      <td>{`${constraint.successfulThreshold * 100}% - 100%`}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}

export default function PQAReport({ plate }: { plate: PlateFromTRPC }) {
  if (!plate.pqaType || !plate.run) {
    return null;
  }
  return (
    <Card id="run-reagents">
      <CardHeader>
        <CardTitle>
          <span>PQA report</span>
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <PQAResults plate={plate} />
      </CardContent>
    </Card>
  );
}
