import { OligoStatus, SequenceShippingFormat } from "@console/shared";
import { v4 } from "uuid";

import { PlateIndices96 } from "../../build/new-workflow/utils";
import type { OrderDetailsFillAndFinish, OrderDetailsTRPCItem } from "../types";

export type FillAndFinishMapping = OrderDetailsFillAndFinish["mapping"];

export type FillAndFinishSingleMapping = FillAndFinishMapping[number];

export enum FillAndFinishAction {
  "ChangeSelectedReplicate",
  "MoveShippingWells",
  "ChangeOligoVolume",
  "ChangeOligosVolume",
}

export const MINIMUM_SHIPPING_MASS = 50;
const DEFAULT_MINIMUM_SHIPPING_MASS = 100;

function getVolumeToPick(
  finalYield: number | null | undefined,
  runningVolume: number | null | undefined,
): number {
  const volume = (() => {
    if (!runningVolume) {
      return 0;
    }
    if (!finalYield || finalYield <= DEFAULT_MINIMUM_SHIPPING_MASS) {
      return Math.floor(runningVolume);
    }
    return Math.floor(
      (DEFAULT_MINIMUM_SHIPPING_MASS / finalYield) * runningVolume,
    );
  })();
  return Math.floor(volume);
}

export function getInitialMapping(
  items: OrderDetailsTRPCItem[],
): FillAndFinishMapping {
  const mapping: FillAndFinishMapping = [];
  const itemsToShip = items.filter((item) =>
    item.oligos.some((o) => o.status === OligoStatus.Liberated),
  );
  const itemsToMapOnTubes = itemsToShip.filter(
    (item) => item.shippingFormat === SequenceShippingFormat.Tube,
  );
  const itemsToMapOnPlates = itemsToShip.filter(
    (item) => item.shippingFormat === SequenceShippingFormat.Plate,
  );
  const plateNames = itemsToMapOnPlates
    .flatMap((item) =>
      item.oligos.filter((o) => o.placement).map((o) => o.placement!.plate),
    )
    .sort();
  const firstPlateName = plateNames[0];

  for (const item of itemsToMapOnTubes) {
    for (const oligo of item.oligos) {
      if (!oligo.placement || oligo.status !== OligoStatus.Liberated) {
        continue;
      }
      const volume = getVolumeToPick(
        oligo.qcResults?.finalYield,
        oligo.qcResults?.runningVolume,
      );
      mapping.push({
        productionWellId: oligo.placement.wellId,
        volume,
      });
      break;
    }
  }
  let plateId = v4();
  const itemsMapped = new Set();
  let availablePlateHoles = new Set(PlateIndices96);
  for (const item of itemsToMapOnPlates) {
    const liberatedOligos = item.oligos.filter(
      (o) => o.status === OligoStatus.Liberated,
    );
    const oligoInFirstPlate = liberatedOligos.find(
      (o) => o.placement?.plate === firstPlateName,
    );
    if (!oligoInFirstPlate) {
      continue;
    }
    itemsMapped.add(item);
    const placement = oligoInFirstPlate.placement!;
    availablePlateHoles.delete(placement.well);
    const volume = getVolumeToPick(
      oligoInFirstPlate.qcResults?.finalYield,
      oligoInFirstPlate.qcResults?.runningVolume,
    );
    mapping.push({
      plate: {
        index: plateId,
        wellIndex: placement.well,
      },
      productionWellId: placement.wellId,
      volume,
    });
  }

  for (const item of itemsToMapOnPlates) {
    if (itemsMapped.has(item)) {
      continue;
    }
    const liberatedOligo = item.oligos.find(
      (o) => o.status === OligoStatus.Liberated,
    );
    if (!liberatedOligo) {
      continue;
    }
    const placement = liberatedOligo.placement!;
    if (availablePlateHoles.size === 0) {
      plateId = v4();
      availablePlateHoles = new Set(PlateIndices96);
    }
    const wellIndex = availablePlateHoles.has(placement.well)
      ? placement.well
      : availablePlateHoles.values().next().value!;
    availablePlateHoles.delete(wellIndex);
    const volume = getVolumeToPick(
      liberatedOligo.qcResults?.finalYield,
      liberatedOligo.qcResults?.runningVolume,
    );
    mapping.push({
      plate: {
        index: plateId,
        wellIndex,
      },
      productionWellId: placement.wellId,
      volume,
    });
  }
  return mapping;
}

type FillAndFinishReducerActions =
  | {
      // We assume we stay on the same plate
      payload: { newWellIndex: string; wellId: string };
      type: FillAndFinishAction.MoveShippingWells;
    }
  | {
      payload: { newWellId: string; oldWellId: string };
      type: FillAndFinishAction.ChangeSelectedReplicate;
    }
  | {
      payload: { parentWellId: string; volume: number };
      type: FillAndFinishAction.ChangeOligoVolume;
    }
  | {
      payload: { parentWellIds: string[]; volume: number };
      type: FillAndFinishAction.ChangeOligosVolume;
    };

export function fillAndFinishReducer(
  mapping: FillAndFinishMapping,
  action: FillAndFinishReducerActions,
) {
  switch (action.type) {
    case FillAndFinishAction.MoveShippingWells: {
      const wellToMove = mapping.find(
        (m) => m.productionWellId === action.payload.wellId,
      );
      if (!wellToMove || !wellToMove.plate) {
        return mapping;
      }
      const wellToGoTo = mapping.find(
        (m) =>
          m.plate?.wellIndex === action.payload.newWellIndex &&
          m.plate?.index === wellToMove.plate?.index,
      );
      if (wellToGoTo) {
        return mapping.map((m) => {
          if (m.productionWellId === action.payload.wellId) {
            return wellToGoTo;
          }
          if (m.productionWellId === wellToGoTo.productionWellId) {
            return wellToMove;
          }
          return m;
        });
      }
      return mapping.map((m) => {
        if (m.productionWellId === action.payload.wellId) {
          return {
            ...m,
            plate: {
              ...m.plate!,
              wellIndex: action.payload.newWellIndex,
            },
          };
        }
        return m;
      });
    }
    case FillAndFinishAction.ChangeOligoVolume: {
      return mapping.map((m) => {
        if (m.productionWellId === action.payload.parentWellId) {
          return {
            ...m,
            volume: action.payload.volume,
          };
        }
        return m;
      });
    }
    case FillAndFinishAction.ChangeOligosVolume: {
      return mapping.map((m) => {
        if (action.payload.parentWellIds.includes(m.productionWellId)) {
          return {
            ...m,
            volume: action.payload.volume,
          };
        }
        return m;
      });
    }
    case FillAndFinishAction.ChangeSelectedReplicate: {
      return mapping.map((m) => {
        if (m.productionWellId === action.payload.oldWellId) {
          return {
            ...m,
            productionWellId: action.payload.newWellId,
          };
        }
        return m;
      });
    }
    default: {
      return mapping;
    }
  }
}
