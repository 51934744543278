import { useEffect, useState } from "react";
import { useDrag, useDrop } from "react-dnd";

import useUpdateItemVolume from "./useUpdateItemVolume";

import { Input } from "../../../../../components/ui/input";
import { TooltipInfo } from "../../../../../components/ui/tooltip";
import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";
import { cn } from "../../../../../lib/utils";

export default function ShippingWellRenderer({
  minimalValue,
  maximumValue,
  disabled,
  value,
  itemName,
  wellId,
  itemId,
  orderId,
  concentration,
  isViewingMass,
  plateId,
  index,
}: {
  concentration: number;
  disabled: boolean;
  index: string;
  isViewingMass: boolean;
  itemId: string;
  itemName: string;
  maximumValue: number;
  minimalValue: number;
  orderId: string;
  plateId: string;
  value: number;
  wellId: string | undefined;
}) {
  const utils = trpc.useUtils();
  const { toast } = useToast();
  const [localValue, setLocalValue] = useState<number | undefined>(value);

  useEffect(() => {
    if (!wellId) {
      return setLocalValue(undefined);
    }
    setLocalValue(value);
  }, [value, wellId]);

  const updateShippingWellVolume = useUpdateItemVolume(wellId ?? "");

  const handleApproveChange = () => {
    if (disabled) {
      return;
    }
    const newVolume = isViewingMass
      ? (localValue ?? 0) / concentration
      : localValue ?? 0;
    updateShippingWellVolume({
      itemId,
      orderId,
      volume: newVolume,
    });
  };

  const { mutate: updateShippingWellPosition } =
    trpc.order.fillAndFinish.updateShippingWellPosition.useMutation({
      onSuccess() {
        toast({
          title: "Shipping well position updated",
          variant: "success",
        });
        utils.order.read.invalidate(orderId);
      },
    });

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ["Well"],
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
      drop: (item: { itemId: string }) => {
        updateShippingWellPosition({
          itemId: item.itemId,
          orderId,
          shippingPlateId: plateId,
          shippingWellIndex: index,
        });
      },
    }),
    [plateId, index],
  );

  const [collected, drag] = useDrag(
    () => ({
      canDrag: () => !!wellId,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      item: () => {
        return { itemId };
      },
      type: "Well",
    }),
    [itemId, wellId],
  );

  return (
    <div
      className={cn(
        "relative rounded-lg",
        isOver && "ring",
        collected.isDragging && "bg-orange-200",
      )}
      ref={(node) => {
        drop(node);
        drag(node);
      }}
    >
      <Input
        className="no-spinner aspect-square p-1 text-sm"
        disabled={disabled || !wellId}
        inputMode="numeric"
        max={Math.floor(maximumValue)}
        min={Math.ceil(minimalValue)}
        onBlur={handleApproveChange}
        onChange={(e) => setLocalValue(Number(e.target.value))}
        type="number"
        value={wellId && localValue ? Math.round(localValue) : ""}
      />
      {wellId && (
        <div className="absolute right-0 top-0">
          <TooltipInfo text={itemName} />
        </div>
      )}
    </div>
  );
}
