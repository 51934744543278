import type { ColumnDef } from "@tanstack/react-table";
import { Link } from "react-router-dom";

import useNewPlateContext from "./useNewPlateContext";

import { ExpandColumn } from "../../../../../components/logic/expand-column";
import { SelectColumn } from "../../../../../components/logic/select-column";
import { DataTableColumnHeader } from "../../../../../components/ui/data-table/data-table-column-header";
import { OrganizationRoutes } from "../../../organization-routes";
import { getUrlPointingToWell } from "../../build/components/plate/useSelectWell";
import ExternalComplexity from "../components/external-complexity";
import {
  abiColumn,
  concentrationColumn,
  finalYieldColumn,
  lcmsColumn,
  nameColumn,
  ngsErrorRateColumn,
  op2Column,
  runningMassColumn,
  runningVolumeColumn,
  statusColumn,
} from "../components/oligo-columns";
import {
  DuplicateOligoButton,
  RemoveOligoButton,
} from "../oligos/components/oligo-column-components";
import type { BacklogOligo } from "../oligos/components/oligo-row";
import { FailCell, LiberationCell } from "../oligos/components/oligos-actions";

const plateCreationColumns: ColumnDef<BacklogOligo>[] = [
  {
    accessorKey: "assigment",
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "assigment",
    meta: {
      title: "Assigment",
    },
  },
];

const plateLinkColumn: ColumnDef<BacklogOligo> = {
  accessorKey: "plate.name",
  cell: ({ row }) =>
    row.original.plate ? (
      <Link
        className="text-black underline"
        to={getUrlPointingToWell(
          OrganizationRoutes.SERVICE_PLATE.replace(
            ":plateId",
            row.original.plate.id,
          ),
          row.original.plate.well,
        )}
      >
        {row.original.plate.name}
      </Link>
    ) : null,
  header: ({ column, table }) => (
    <DataTableColumnHeader column={column} table={table} />
  ),
  id: "plateId",
  meta: {
    title: "Plate",
  },
};

const orderLinkColumn: ColumnDef<BacklogOligo> = {
  accessorKey: "orderSOId",
  cell: ({ row }) => (
    <Link
      className="text-black underline"
      to={OrganizationRoutes.SERVICE_ORDER.replace(
        ":orderId",
        row.original.orderId,
      )}
    >
      {row.original.orderSOId}
    </Link>
  ),
  header: ({ column, table }) => (
    <DataTableColumnHeader column={column} table={table} />
  ),
  id: "orderSOId",
  meta: {
    title: "Order #",
  },
};

const sizeColumn: ColumnDef<BacklogOligo> = {
  accessorKey: "size",
  cell: ({ row }) => row.original.size,
  header: ({ column, table }) => (
    <DataTableColumnHeader column={column} table={table} />
  ),
  id: "size",
  meta: {
    title: "Size (nts)",
  },
};

const externalComplexityColumn: ColumnDef<BacklogOligo> = {
  accessorKey: "externalComplexity.score",
  cell: ({ row }) => (
    <ExternalComplexity
      flag={row.original.externalComplexity.flag}
      score={row.original.externalComplexity.score}
    />
  ),
  header: ({ column, table }) => (
    <DataTableColumnHeader column={column} table={table} />
  ),
  id: "externalComplexity",
  meta: {
    title: "Complexity score",
  },
};

const actionsColumn: ColumnDef<BacklogOligo> = {
  cell: ({ row }) => (
    <div className="flex flex-row space-x-2">
      <DuplicateOligoButton id={row.original.id} />
      <RemoveOligoButton oligo={row.original} />
    </div>
  ),
  header: "Actions",
  id: "actions",
};
const liberationColumn: ColumnDef<BacklogOligo> = {
  cell: ({ row }) => <LiberationCell row={row} />,
  header: "Liberate",
  id: "liberation",
};

const failColumn: ColumnDef<BacklogOligo> = {
  cell: ({ row }) => <FailCell row={row} />,
  header: "Fail",
  id: "fail",
};

export const useGetOligoBacklogColumns = (): ColumnDef<BacklogOligo>[] => {
  const { displayQCColumns, isLiberating, isCreatingPlate } =
    useNewPlateContext();

  const coreColumns = displayQCColumns
    ? [
        orderLinkColumn,
        nameColumn as ColumnDef<BacklogOligo>,
        externalComplexityColumn as ColumnDef<BacklogOligo>,
        plateLinkColumn,
        statusColumn as ColumnDef<BacklogOligo>,
        runningMassColumn as ColumnDef<BacklogOligo>,
        runningVolumeColumn as ColumnDef<BacklogOligo>,
        concentrationColumn as ColumnDef<BacklogOligo>,
        finalYieldColumn as ColumnDef<BacklogOligo>,
        abiColumn as ColumnDef<BacklogOligo>,
        op2Column as ColumnDef<BacklogOligo>,
        ngsErrorRateColumn as ColumnDef<BacklogOligo>,
        lcmsColumn as ColumnDef<BacklogOligo>,
      ]
    : [
        orderLinkColumn,
        nameColumn as ColumnDef<BacklogOligo>,
        externalComplexityColumn as ColumnDef<BacklogOligo>,
        ...(isCreatingPlate ? plateCreationColumns : [plateLinkColumn]),
        statusColumn as ColumnDef<BacklogOligo>,
        sizeColumn,
        actionsColumn,
      ];
  if (isLiberating) {
    coreColumns.push(...[liberationColumn, failColumn]);
  }

  return [SelectColumn(() => null), ...coreColumns, ExpandColumn()];
};
