import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";

export default function EllipsedText({
  children,
  maxWidth,
}: React.PropsWithChildren<{
  maxWidth: string;
}>) {
  return (
    <Tooltip>
      <TooltipTrigger asChild type="button">
        <p
          className="truncate"
          style={{
            maxWidth,
          }}
        >
          {children}
        </p>
      </TooltipTrigger>
      <TooltipContent>{children}</TooltipContent>
    </Tooltip>
  );
}
