import { useInView } from "react-intersection-observer";

import { getBottleColors } from "./consumables-colors";

import type { InstrumentTRPC } from "../useGetInstrument";

type InstrumentConsumables = InstrumentTRPC["consumables"];

const DynamicIndicator = ({
  percentage,
  color,
  bg,
}: {
  bg: string;
  color: string;
  percentage: number;
}) => {
  const { ref, inView } = useInView({ triggerOnce: true });

  return (
    <div className="relative grow overflow-hidden rounded-lg" ref={ref}>
      <div
        className={`relative h-10 rounded-md ${bg} flex items-center justify-center`}
      >
        <div
          className={`transition-width absolute inset-y-0 left-0 top-1 h-8 w-10 rounded-md ${color} duration-500 ease-out hover:w-48`}
          style={{
            width: inView ? (percentage < 1 ? "1px" : `${percentage}%`) : 0,
          }}
        />
        <span className="absolute items-center text-sm font-bold">
          {percentage.toFixed(0)}%
        </span>
      </div>
    </div>
  );
};

const Bottles = ({
  bottles,
}: {
  bottles: NonNullable<InstrumentConsumables>["bottleGroups"];
}) => {
  return (
    <ul className="space-y-3">
      {bottles.map((bottleGroup) => {
        const { bg, color } = getBottleColors(bottleGroup);
        return (
          <div
            className="flex flex-row items-center space-x-2"
            key={bottleGroup.group}
          >
            <span>{bottleGroup.group}</span>
            <DynamicIndicator
              bg={bg}
              color={color}
              percentage={bottleGroup.fillPercentage}
            />
          </div>
        );
      })}
    </ul>
  );
};
export default function Consumables({
  consumables,
}: {
  consumables: InstrumentConsumables | null | undefined;
}) {
  if (!consumables) return null;

  return <Bottles bottles={consumables.bottleGroups} />;
}
