import { OligoStatus } from "@console/shared";
import { Truck } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { getInitialMapping } from "./reducer";

import { Button } from "../../../../../components/ui/button";
import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";
import { OrganizationRoutes } from "../../../organization-routes";
import type { OrderDetailsTRPC } from "../types";

export default function NewFillAndFinishButton({
  order,
}: {
  order: OrderDetailsTRPC;
}) {
  const { items, id } = order;
  const { toast } = useToast();
  const navigate = useNavigate();
  const { mutate: createFillAndFinish, isPending } =
    trpc.order.fillAndFinish.create.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Error creating fill and finish",
          variant: "destructive",
        });
      },
      onSuccess() {
        toast({
          title: "Created fill and finish",
          variant: "success",
        });
        navigate(OrganizationRoutes.SERVICE_ORDER_FNF.replace(":orderId", id));
      },
    });

  const allItemsAreReady = items.every((item) =>
    [OligoStatus.Liberated, OligoStatus.Failed].includes(item.itemStatus),
  );

  const handleCreateFillAndFinish = () => {
    createFillAndFinish({ mapping: getInitialMapping(items), orderId: id });
  };

  return (
    <Button
      className="flex flex-row space-x-1"
      disabled={!allItemsAreReady}
      isLoading={isPending}
      onClick={handleCreateFillAndFinish}
      variant={"outline"}
    >
      <span>New fill and finish</span>
      <Truck />
    </Button>
  );
}
