import { OligoStatus } from "@console/shared";
import { zodResolver } from "@hookform/resolvers/zod";
import { Plus } from "lucide-react";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import GenericPlate from "../../../../../../components/plate/generic-plate";
import { Button } from "../../../../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../../../components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../../../components/ui/form";
import { Input } from "../../../../../../components/ui/input";
import { Label } from "../../../../../../components/ui/label";
import { useToast } from "../../../../../../components/ui/use-toast";
import { trpc } from "../../../../../../config/trpc";
import type { PlateFromTRPC } from "../../../build/components/plate/types";

const defineQCPlateSchema = z.object({
  name: z.string(),
  volumes: z.record(z.number()),
});

type QCPlate = z.infer<typeof defineQCPlateSchema>;

export default function CreateQCPlate({ plate }: { plate: PlateFromTRPC }) {
  const [open, setOpen] = useState(false);
  const { toast } = useToast();
  const nonFailedWellIndices: Set<string> = useMemo(() => {
    return new Set(
      plate.wells
        .filter((w) => w.oligoStatus !== OligoStatus.Failed)
        .map((well) => well.index),
    );
  }, [plate]);
  const form = useForm<QCPlate>({
    defaultValues: {
      volumes: Array.from(nonFailedWellIndices).reduce(
        (acc, wellIndex) => {
          acc[wellIndex] = 6;
          return acc;
        },
        {} as Record<string, number>,
      ),
    },
    resolver: zodResolver(defineQCPlateSchema),
  });

  const utils = trpc.useUtils();
  const { mutate: createQCPlate, isPending } =
    trpc.order.productionPlates.createQCPlate.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Error creating QC plate",
          variant: "destructive",
        });
        setOpen(false);
      },
      onSuccess() {
        utils.order.productionPlates.listQCPlates.invalidate(plate.id);
        setOpen(false);
      },
    });

  const volumes = form.watch("volumes");
  const firstVolume = Object.values(volumes)[0];
  const allVolumesAreEqual = Object.values(volumes).every(
    (volume) => volume === firstVolume,
  );

  if (plate.stepId) {
    return null;
  }

  const onSubmit = (data: QCPlate) => {
    const firstVolume = data.volumes[Object.keys(data.volumes)[0]];
    const allVolumesAreEqual = Object.values(data.volumes).every(
      (volume) => volume === firstVolume,
    );
    createQCPlate({
      name: data.name,
      pippetedVolume: allVolumesAreEqual ? firstVolume : data.volumes,
      plateId: plate.id,
    });
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <Button className="flex items-center space-x-1" variant="outline">
          <span>New QC Plate</span>
          <Plus />
        </Button>
      </DialogTrigger>
      <DialogContent className="max-h-[90%] min-w-[70%] overflow-scroll">
        <Form {...form}>
          <form className="space-y-4" onSubmit={form.handleSubmit(onSubmit)}>
            <DialogHeader>
              <DialogTitle>Extract QC Plate</DialogTitle>
            </DialogHeader>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <div className="flex flex-row items-center space-x-1">
                    <p>{plate.name}_QC_</p>
                    <FormControl>
                      <Input placeholder="ABI_NGS" {...field} />
                    </FormControl>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="space-y-2">
              <h3 className="text-lg font-semibold">Volumes (µL)</h3>
              <div className="flex flex-col space-y-2">
                <div className="flex flex-row items-center space-x-2">
                  <Label htmlFor="global-volume">Global value</Label>
                  <Input
                    className="w-1/4"
                    id="global-volume"
                    onChange={(e) => {
                      form.setValue(
                        "volumes",
                        Object.fromEntries(
                          Object.keys(volumes).map((key) => [
                            key,
                            Number(e.target.value),
                          ]),
                        ),
                      );
                    }}
                    type="number"
                    value={allVolumesAreEqual ? firstVolume : NaN}
                  />
                </div>
                <FormField
                  control={form.control}
                  name="volumes"
                  render={({ field }) => (
                    <GenericPlate
                      size={plate.size}
                      wellRenderer={(index) => {
                        const well = plate.wells.find(
                          (well) => well.index === index,
                        );
                        if (well?.oligoStatus === OligoStatus.Failed) {
                          return <Input disabled value="failed" />;
                        }
                        if (!nonFailedWellIndices.has(index)) {
                          return <Input disabled />;
                        }
                        return (
                          <Input
                            className="aspect-square p-1 text-sm"
                            onChange={(e) => {
                              const newVolumes = {
                                ...field.value,
                                [index]: Number(e.target.value),
                              };
                              field.onChange(newVolumes);
                            }}
                            type="number"
                            value={field.value[index]}
                          />
                        );
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex justify-end">
              <Button isLoading={isPending} type="submit">
                Submit
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
