import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../../../../components/ui/accordion";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../../../components/ui/card";
import HomologyInfo from "../../../../../screening/components/homologyInfo";
import type { SequenceRow } from "../../../../../screening/types";
import type { PlateFromTRPC } from "../../../build/components/plate/types";

export default function Homology({ plate }: { plate: PlateFromTRPC }) {
  if (plate.stepId) {
    return null;
  }

  const rows: SequenceRow[] = plate.wells.map((well) => ({
    name: well.name,
    sequence: well.sequence,
    well: well.index,
  }));

  return (
    <Card>
      <Accordion collapsible type="single">
        <AccordionItem value="configuration">
          <CardHeader>
            <AccordionTrigger>
              <CardTitle>Homology</CardTitle>
            </AccordionTrigger>
          </CardHeader>
          <AccordionContent className="flex flex-col py-2">
            <CardContent>
              <HomologyInfo filename={plate.name} rows={rows} />
            </CardContent>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </Card>
  );
}
