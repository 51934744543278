import { Complexity } from "@console/shared";

import { SmallColoredDot } from "../../build/components/plate/sidebarSingleWell";

const ComplexityColor: Record<Complexity, string> = {
  [Complexity.Complex]: "bg-red-500",
  [Complexity.Medium]: "bg-yellow-500",
  [Complexity.Normal]: "bg-green-500",
  [Complexity.Unknown]: "bg-gray-500",
};

const ComplexityDot = ({ flag }: { flag: Complexity }) => (
  <SmallColoredDot className={ComplexityColor[flag]} />
);

export default function ExternalComplexity({
  flag,
  score,
}: {
  flag: Complexity;
  score: number;
}) {
  return (
    <p className="flex items-center">
      <ComplexityDot flag={flag} />
      <span>{score.toFixed(1)}</span>
    </p>
  );
}
