import { OligoStatus } from "@console/shared";
import type { ColumnDef } from "@tanstack/react-table";

import { OligoStatusComponent } from "./oligo-status";
import { AbiSummary, LCMSSummary } from "./qc-basic-components";

import { DataTableColumnHeader } from "../../../../../components/ui/data-table/data-table-column-header";
import { arrIncludesSomeWithEmptyFn } from "../../../../../components/ui/data-table/filters";
import EllipsedText from "../../../../../components/ui/tooltipText";
import DateTooltip from "../../../../../components/ui/upload-date-tooltip";
import type { BacklogOligo, QcResults } from "../oligos/components/oligo-row";

export const nameColumn: ColumnDef<{ name: string; replicates: number }> = {
  accessorKey: "name",
  cell: ({ row }) => (
    <p className="relative space-x-1">
      <EllipsedText maxWidth="130px">{row.original.name}</EllipsedText>
      {row.original.replicates > 1 && (
        <span className="absolute right-0 top-0 rounded-full border bg-gray-300 text-xs">
          {row.original.replicates}
        </span>
      )}
    </p>
  ),
  header: ({ column, table }) => (
    <DataTableColumnHeader column={column} table={table} />
  ),
  id: "name",
  meta: {
    title: "Name",
  },
};

export const statusColumn: ColumnDef<{
  liberatedAt: string | null;
  status: OligoStatus;
}> = {
  accessorKey: "status",
  cell: ({ row }) => {
    const statusComponent = OligoStatusComponent[row.original.status];
    if (
      row.original.status === OligoStatus.Liberated ||
      row.original.status === OligoStatus.Failed
    ) {
      return (
        <div className="flex flex-row items-start space-x-1">
          {statusComponent}
          <DateTooltip date={row.original.liberatedAt} prefix="Liberated at" />
        </div>
      );
    }
    return statusComponent;
  },
  filterFn: arrIncludesSomeWithEmptyFn,
  header: ({ column, table }) => (
    <DataTableColumnHeader column={column} table={table} />
  ),
  id: "status",
  meta: {
    title: "Status",
    uniqueFilter: true,
  },
};

export const concentrationColumn: ColumnDef<QcResults> = {
  accessorFn: (row) => row.qcResults?.concentration ?? -1,
  cell: ({ row }) => (
    <div className="relative">
      <span>
        {row.original.qcResults?.concentration
          ? row.original.qcResults?.concentration.toFixed(1)
          : "-"}
      </span>
    </div>
  ),
  header: ({ column, table }) => (
    <DataTableColumnHeader column={column} table={table} />
  ),
  id: "concentration",
  meta: {
    title: "Concentration (µM)",
  },
};

export const finalYieldColumn: ColumnDef<QcResults> = {
  accessorFn: (row) => row.qcResults?.finalYield ?? -1,
  cell: ({ row }) => (
    <div className="relative">
      <span>
        {row.original.qcResults?.finalYield
          ? row.original.qcResults?.finalYield.toFixed(0)
          : "-"}
      </span>
    </div>
  ),
  header: ({ column, table }) => (
    <DataTableColumnHeader column={column} table={table} />
  ),
  id: "yield",
  meta: {
    title: "Final yield (pmol)",
  },
};

export const runningMassColumn: ColumnDef<QcResults> = {
  accessorFn: (row) => row.qcResults?.runningMass ?? -1,
  cell: ({ row }) => (
    <div className="relative">
      <span>
        {row.original.qcResults?.runningMass
          ? row.original.qcResults?.runningMass.toFixed(0)
          : "-"}
      </span>
    </div>
  ),
  header: ({ column, table }) => (
    <DataTableColumnHeader column={column} table={table} />
  ),
  id: "running_mass",
  meta: {
    title: "Running mass (pmol)",
  },
};

export const runningVolumeColumn: ColumnDef<QcResults> = {
  accessorFn: (row) => row.qcResults?.runningVolume ?? -1,
  cell: ({ row }) => (
    <div className="relative">
      <span>
        {row.original.qcResults?.runningVolume
          ? row.original.qcResults?.runningVolume.toFixed(0)
          : "-"}
      </span>
    </div>
  ),
  header: ({ column, table }) => (
    <DataTableColumnHeader column={column} table={table} />
  ),
  id: "running_volume",
  meta: {
    title: "Running volume (µL)",
  },
};

export const abiColumn: ColumnDef<QcResults> = {
  accessorFn: (row) => row.qcResults?.abiPurity ?? undefined,
  cell: ({ row }) => {
    return <AbiSummary qcResults={row.original.qcResults} />;
  },
  header: ({ column, table }) => (
    <DataTableColumnHeader column={column} table={table} />
  ),
  id: "abiPurity",
  meta: {
    title: "ABI Purity (%)",
  },
};

export const lcmsColumn: ColumnDef<QcResults> = {
  accessorFn: (row) => row.qcResults?.lcmsIsPass ?? undefined,
  cell: ({ row }) => {
    return <LCMSSummary qcResults={row.original.qcResults} />;
  },
  header: ({ column, table }) => (
    <DataTableColumnHeader column={column} table={table} />
  ),
  id: "lcmsIsPass",
  meta: {
    title: "LCMS pass",
  },
};

export const op2Column: ColumnDef<QcResults> = {
  accessorFn: (row) => row.qcResults?.op2Purity ?? undefined,
  cell: ({ row }) => (
    <div className="relative">
      <span>
        {row.original.qcResults?.op2Purity
          ? row.original.qcResults?.op2Purity.toFixed(0)
          : "-"}
      </span>
    </div>
  ),
  header: ({ column, table }) => (
    <DataTableColumnHeader column={column} table={table} />
  ),
  id: "op2Purity",
  meta: {
    title: "OP2 Purity (%)",
  },
};

export const ngsErrorRateColumn: ColumnDef<BacklogOligo> = {
  accessorFn: (row) => row.qcResults?.ngsErrorRate ?? undefined,
  cell: ({ row }) => (
    <div className="relative">
      <span>
        {row.original.qcResults?.ngsErrorRate
          ? (row.original.qcResults.ngsErrorRate * 100).toFixed(2)
          : "-"}
      </span>
    </div>
  ),
  header: ({ column, table }) => (
    <DataTableColumnHeader column={column} table={table} />
  ),
  id: "ngsErrorRate",
  meta: {
    title: "NGS Error Rate (%)",
  },
};
