import { OrderEvent } from "@console/shared";

import { Badge } from "../../../../../components/ui/badge";

export const OrderEventComponent: Record<OrderEvent, React.ReactNode> = {
  [OrderEvent.Canceled]: (
    <Badge className="bg-gray-500">{OrderEvent.Canceled}</Badge>
  ),
  [OrderEvent.Queued]: (
    <Badge className="bg-lime-500">{OrderEvent.Queued}</Badge>
  ),
  [OrderEvent.Opportunity]: (
    <Badge className="bg-yellow-600">{OrderEvent.Opportunity}</Badge>
  ),
  [OrderEvent.Archived]: (
    <Badge className="bg-gray-900">{OrderEvent.Archived}</Badge>
  ),
  [OrderEvent.Reopened]: (
    <Badge className="bg-purple-600">{OrderEvent.Reopened}</Badge>
  ),
  [OrderEvent.FillAndFinishCompleted]: (
    <Badge className="bg-purple-800">{OrderEvent.FillAndFinishCompleted}</Badge>
  ),
  [OrderEvent.FillAndFinishCreated]: (
    <Badge className="bg-purple-400">{OrderEvent.FillAndFinishCreated}</Badge>
  ),
  [OrderEvent.Completed]: (
    <Badge className="bg-green-600">{OrderEvent.Completed}</Badge>
  ),
};
