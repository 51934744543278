import { useCallback, useEffect, useMemo, useState } from "react";
import type { Connection, Edge, NodeChange, EdgeChange } from "reactflow";
import ReactFlow, {
  addEdge,
  applyNodeChanges,
  applyEdgeChanges,
  MiniMap,
  useReactFlow,
} from "reactflow";

import { getWorkflowGraph } from "./getWorkflowGraph";
import WorkflowStepNode from "./workflow-step-node";

import "reactflow/dist/style.css";
import type { WorkflowTRPC } from "../../../../../../config/trpc";

const CUSTOM_NODE_TYPES = {
  workflowStepNode: WorkflowStepNode,
};

type CreateWorkflowProps = {
  workflow: WorkflowTRPC;
};

export default function WorkflowGraph({ workflow }: CreateWorkflowProps) {
  const { nodes: initialNodes, edges: initialEdges } = useMemo(
    () => getWorkflowGraph(workflow),
    [workflow],
  );
  const { fitView } = useReactFlow();

  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  useEffect(() => {
    setNodes(initialNodes);
    setEdges(initialEdges);
    fitView();
  }, [fitView, initialEdges, initialNodes]);

  const onNodesChange = useCallback(
    (changes: NodeChange[]) =>
      setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes],
  );
  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) =>
      setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges],
  );
  const onConnect = useCallback(
    (connection: Edge | Connection) =>
      setEdges((eds) => addEdge(connection, eds)),
    [setEdges],
  );

  if (nodes.length === 0) {
    return <div className="p-4 text-center">No workflow</div>;
  }

  return (
    <div className="h-[600px] w-full rounded-lg border p-4">
      <ReactFlow
        edges={edges}
        fitView
        maxZoom={1.5}
        nodeTypes={CUSTOM_NODE_TYPES}
        nodes={nodes}
        onConnect={onConnect}
        onEdgesChange={onEdgesChange}
        onNodesChange={onNodesChange}
        proOptions={{ hideAttribution: true }}
      >
        <MiniMap />
      </ReactFlow>
    </div>
  );
}
