import { SaveIcon, Upload } from "lucide-react";
import { useState } from "react";

import { Button } from "../../../../../../components/ui/button";
import { TableActions } from "../../../../../../components/ui/data-table/data-table-actions";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../../../components/ui/dialog";
import UploadCSVFile from "../../../../../../components/ui/upload-csv-file";
import UploadExcelFile from "../../../../../../components/ui/upload-excel-file";
import { useToast } from "../../../../../../components/ui/use-toast";
import { IS_DEVELOPMENT } from "../../../../../../config";
import { trpc } from "../../../../../../config/trpc";
import {
  arrayBufferTobase64,
  downloadBase64Excel,
} from "../../../../../../utils/useDownload";

const UploadABIResults = ({ id }: { id: string }) => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const { toast } = useToast();

  const utils = trpc.useUtils();
  const { mutate: uploadABIResults, isPending } =
    trpc.order.productionPlates.qualityControl.uploadABIOutput.useMutation({
      onSuccess() {
        utils.order.productionPlates.listQCPlates.invalidate();
        toast({
          title: "ABI Results uploaded",
          variant: "success",
        });
        setOpen(false);
      },
    });

  const handleSubmit = () => {
    if (!file) {
      return;
    }
    const submit = async () => {
      const arrayBuffer = await file.arrayBuffer();
      const base64 = arrayBufferTobase64(arrayBuffer);
      const fileName = file.name.split(".").slice(0, -1).join(".");
      uploadABIResults({ file: base64, fileName, qcPlateId: id });
    };
    submit();
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <div className="flex items-center gap-2">
          <Upload />
          <span>Upload ABI Results</span>
        </div>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Upload ABI Results</DialogTitle>
        </DialogHeader>
        <UploadExcelFile file={file} setFile={setFile} />
        <div className="flex flex-row justify-end space-x-2">
          <Button disabled={!file} isLoading={isPending} onClick={handleSubmit}>
            <SaveIcon />
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const UploadLCMSResults = ({ id }: { id: string }) => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const { toast } = useToast();

  const utils = trpc.useUtils();
  const { mutate: uploadLCMSResults, isPending } =
    trpc.order.productionPlates.qualityControl.uploadLCMSOutput.useMutation({
      onSuccess() {
        utils.order.productionPlates.listQCPlates.invalidate();
        toast({
          title: "LCMS Results uploaded",
          variant: "success",
        });
        setOpen(false);
      },
    });

  const handleSubmit = () => {
    if (!file) {
      return;
    }
    const submit = async () => {
      const arrayBuffer = await file.arrayBuffer();
      const base64 = arrayBufferTobase64(arrayBuffer);
      const fileName = file.name.split(".").slice(0, -1).join(".");
      uploadLCMSResults({ file: base64, fileName, qcPlateId: id });
    };
    submit();
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <div className="flex items-center gap-2">
          <Upload />
          <span>Upload LCMS Results</span>
        </div>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Upload LCMS Results</DialogTitle>
        </DialogHeader>
        <UploadExcelFile file={file} setFile={setFile} />
        <div className="flex flex-row justify-end space-x-2">
          <Button disabled={!file} isLoading={isPending} onClick={handleSubmit}>
            <SaveIcon />
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const UploadOP2Results = ({ id }: { id: string }) => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const { toast } = useToast();

  const utils = trpc.useUtils();
  const { mutate: uploadOP2Results, isPending } =
    trpc.order.productionPlates.qualityControl.uploadOP2Output.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Error uploading OP2 Results",
          variant: "destructive",
        });
        setOpen(false);
      },
      onSuccess() {
        utils.order.productionPlates.listQCPlates.invalidate();
        toast({
          title: "OP2 Results uploaded",
          variant: "success",
        });
        setOpen(false);
      },
    });

  const handleSubmit = () => {
    if (!file) {
      return;
    }
    const submit = async () => {
      const arrayBuffer = await file.arrayBuffer();
      const base64 = arrayBufferTobase64(arrayBuffer);
      const fileName = file.name.split(".").slice(0, -1).join(".");
      uploadOP2Results({ file: base64, fileName, qcPlateId: id });
    };
    submit();
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <div className="flex items-center gap-2">
          <Upload />
          <span>Upload OP2 Results</span>
        </div>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Upload OP2 Results</DialogTitle>
        </DialogHeader>
        <UploadExcelFile file={file} setFile={setFile} />
        <div className="flex flex-row justify-end space-x-2">
          <Button disabled={!file} isLoading={isPending} onClick={handleSubmit}>
            <SaveIcon />
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const UploadNGSResults = ({ id }: { id: string }) => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const { toast } = useToast();

  const utils = trpc.useUtils();
  const { mutate: uploadNGSResults, isPending } =
    trpc.order.productionPlates.qualityControl.uploadNGSOutput.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Error uploading NGS Results",
          variant: "destructive",
        });
        setOpen(false);
      },
      onSuccess() {
        utils.order.productionPlates.listQCPlates.invalidate();
        toast({
          title: "NGS Results uploaded",
          variant: "success",
        });
        setOpen(false);
      },
    });

  const handleSubmit = () => {
    if (!file) {
      return;
    }
    const submit = async () => {
      const arrayBuffer = await file.arrayBuffer();
      const base64 = arrayBufferTobase64(arrayBuffer);
      const fileName = file.name.split(".").slice(0, -1).join(".");
      uploadNGSResults({ file: base64, fileName, qcPlateId: id });
    };
    submit();
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <div className="flex items-center gap-2">
          <Upload />
          <span>Upload NGS Results</span>
        </div>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Upload NGS Results</DialogTitle>
        </DialogHeader>
        <UploadCSVFile file={file} setFile={setFile} />
        <div className="flex flex-row justify-end space-x-2">
          <Button disabled={!file} isLoading={isPending} onClick={handleSubmit}>
            <SaveIcon />
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default function QCPlateActions({
  id,
  plateName,
}: {
  id: string;
  plateName: string;
}) {
  const { mutate: downloadABIInput } =
    trpc.order.productionPlates.qualityControl.downloadABIInput.useMutation({
      onSuccess(base64File) {
        downloadBase64Excel(base64File, `${plateName}_ABI_Input.xlsx`);
      },
    });
  const { mutate: generateABIOutput } =
    trpc.order.productionPlates.qualityControl.generateABIOutput.useMutation({
      onSuccess(base64File) {
        downloadBase64Excel(
          base64File,
          `${plateName}_ABI_Generated_results.xlsx`,
        );
      },
    });
  const { mutate: downloadLCMSInput } =
    trpc.order.productionPlates.qualityControl.downloadLCMSInput.useMutation({
      onSuccess(base64File) {
        downloadBase64Excel(base64File, `${plateName}_LCMS_Input.xlsx`);
      },
    });
  const { mutate: generateLCMSOutput } =
    trpc.order.productionPlates.qualityControl.generateLCMSOutput.useMutation({
      onSuccess(base64File) {
        downloadBase64Excel(
          base64File,
          `${plateName}_LCMS_Generated_results.xlsx`,
        );
      },
    });
  const { mutate: generateNGSOutput } =
    trpc.order.productionPlates.qualityControl.generateNGSOutput.useMutation({
      onSuccess(base64File) {
        downloadBase64Excel(
          base64File,
          `${plateName}_NGS_Generated_results.csv`,
        );
      },
    });

  return (
    <TableActions
      items={[
        {
          children: "Download ABI Input",
          id: "ABI Input",
          onClick: () => {
            downloadABIInput(id);
          },
        },
        ...(IS_DEVELOPMENT
          ? [
              {
                children: "Generate ABI Results",
                id: "ABI Mock Generation",
                onClick: () => {
                  generateABIOutput(id);
                },
              },
            ]
          : []),
        {
          children: <UploadABIResults id={id} />,
          id: "ABI Results",
          preventDefault: true,
        },
        {
          children: <UploadOP2Results id={id} />,
          id: "OP2 Results",
          preventDefault: true,
        },
        ...(IS_DEVELOPMENT
          ? [
              {
                children: "Generate NGS Results",
                id: "NGS Mock Generation",
                onClick: () => {
                  generateNGSOutput(id);
                },
              },
            ]
          : []),
        {
          children: <UploadNGSResults id={id} />,
          id: "NGS Results",
          preventDefault: true,
        },
        {
          children: "Download LCMS Input",
          id: "LCMS Input",
          onClick: () => {
            downloadLCMSInput(id);
          },
        },
        ...(IS_DEVELOPMENT
          ? [
              {
                children: "Generate LCMS Results",
                id: "LCMS Mock Generation",
                onClick: () => {
                  generateLCMSOutput(id);
                },
              },
            ]
          : []),
        {
          children: <UploadLCMSResults id={id} />,
          id: "LCMS Results",
          preventDefault: true,
        },
      ]}
    />
  );
}
