import { PlateSize } from "@console/shared";
import { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { getOligoAllowedRange, useGetLocalVolumesAndMass } from "./helpers";
import {
  MINIMUM_SHIPPING_MASS,
  type FillAndFinishMapping,
  type FillAndFinishSingleMapping,
} from "./reducer";
import ShippingWellRenderer from "./shipping-well-renderer";

import GenericPlate from "../../../../../components/plate/generic-plate";
import { Input } from "../../../../../components/ui/input";
import { Label } from "../../../../../components/ui/label";
import { Switch } from "../../../../../components/ui/switch";
import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";
import type { OrderDetailsTRPC, OrderDetailsTRPCItem } from "../types";

function ShippingPlate({
  isFnFCompleted,
  plateItems,
  shippingPlateWells: plateWells,
  index,
}: {
  index: number;
  isFnFCompleted: boolean;
  plateItems: OrderDetailsTRPCItem[];
  shippingPlateWells: FillAndFinishMapping;
}) {
  const [viewMass, setViewMass] = useState(false);
  const { toast } = useToast();
  const plateOligos = plateItems
    .flatMap((i) => i.oligos)
    .filter((o) =>
      plateWells.some((m) => m.productionWellId === o.placement?.wellId),
    );
  const orderId = plateItems[0].orderId;
  const plateId = plateWells[0].plate?.index ?? "";
  const utils = trpc.useUtils();
  const { mutate: updatePlateWellsMass } =
    trpc.order.fillAndFinish.updatePlateWellsMass.useMutation({
      onSuccess() {
        toast({
          title: "Plate mass updated",
          variant: "success",
        });
        utils.order.read.invalidate(orderId);
      },
    });
  const { mutate: updatePlateWellsVolume } =
    trpc.order.fillAndFinish.updatePlateWellsVolume.useMutation({
      onSuccess() {
        toast({
          title: "Plate volume updated",
          variant: "success",
        });
        utils.order.read.invalidate(orderId);
      },
    });

  const applyChangeToMassOrVolume = () => {
    const commonPayload = {
      orderId,
      shippingPlateId: plateWells[0].plate?.index ?? "",
    };

    if (!localCommonValue) {
      return;
    }

    if (viewMass) {
      return updatePlateWellsMass({
        ...commonPayload,
        mass: localCommonValue,
      });
    }
    return updatePlateWellsVolume({
      ...commonPayload,
      volume: localCommonValue,
    });
  };

  const oligoToMapping: Record<string, FillAndFinishSingleMapping> =
    plateWells.reduce(
      (acc, m) => {
        const oligo = plateOligos.find(
          (o) => o.placement?.wellId === m.productionWellId,
        );
        if (oligo) {
          acc[oligo.id] = m;
        }
        return acc;
      },
      {} as Record<string, FillAndFinishSingleMapping>,
    );
  const volumes = plateOligos.map((o) =>
    getOligoAllowedRange(o, oligoToMapping[o.id].volume),
  );

  const {
    minShippingVolumeCommonToEachWell,
    maxShippingVolumeCommonToEachWell,
    maximumMass,
    localCommonVolume,
    localCommonMass,
    setLocalCommonVolume,
    setLocalCommonMass,
  } = useGetLocalVolumesAndMass(volumes);

  const localCommonValue = viewMass ? localCommonMass : localCommonVolume;

  const minMax = viewMass
    ? [Math.ceil(MINIMUM_SHIPPING_MASS), Math.floor(maximumMass)]
    : [
        Math.ceil(minShippingVolumeCommonToEachWell),
        Math.floor(maxShippingVolumeCommonToEachWell),
      ];
  const unit = viewMass ? "pmol" : "μL";
  const rangeString = `${minMax[0]}${unit} - ${minMax[1]}${unit}`;

  return (
    <div className="space-y-1">
      <div className="flex flex-row items-center justify-between space-x-3">
        <p>{`Plate ${index + 1}`}</p>
        <div className="flex flex-row items-end space-x-2">
          <div>
            <p className="space-x-1">
              <span>Min-Max:</span>
              <span>{rangeString}</span>
            </p>
            <Input
              className="w-[200px] p-1 text-sm"
              disabled={isFnFCompleted}
              max={minMax[1]}
              min={minMax[0]}
              onBlur={applyChangeToMassOrVolume}
              onChange={(e) => {
                if (viewMass) {
                  setLocalCommonMass(Number(e.target.value));
                } else {
                  setLocalCommonVolume(Number(e.target.value));
                }
              }}
              placeholder={`Common plate ${viewMass ? "mass" : "volume"}`}
              type="number"
              value={
                localCommonValue ? Math.round(localCommonValue) : undefined
              }
            />
          </div>
          <div className="flex flex-col space-y-1">
            <Label htmlFor="switch-shipping-plate-input">View mass</Label>
            <Switch
              checked={viewMass}
              className="data-[state=checked]:bg-secondary"
              id="switch-shipping-plate-input"
              onCheckedChange={setViewMass}
            />
          </div>
        </div>
      </div>
      <GenericPlate
        size={PlateSize.S96}
        wellRenderer={(index) => {
          const wellMapping = plateWells.find(
            (m) => m.plate && m.plate.wellIndex === index,
          );
          const oligo = plateItems
            .flatMap((i) => i.oligos)
            .find((o) => o.placement?.wellId === wellMapping?.productionWellId);
          const hasMappingAndOligo = wellMapping && oligo;
          const {
            minimalVolume,
            maximumVolume,
            maximumMass,
            shippingMass,
            shippingVolume,
          } = hasMappingAndOligo
            ? getOligoAllowedRange(oligo, wellMapping.volume)
            : {
                maximumMass: 0,
                maximumVolume: 0,
                minimalVolume: 0,
                shippingMass: 0,
                shippingVolume: 0,
              };
          return (
            <ShippingWellRenderer
              concentration={oligo?.qcResults?.concentration ?? -1}
              disabled={!!isFnFCompleted}
              index={index}
              isViewingMass={viewMass}
              itemId={oligo?.itemId ?? ""}
              itemName={oligo?.name ?? ""}
              maximumValue={viewMass ? maximumMass : maximumVolume}
              minimalValue={viewMass ? MINIMUM_SHIPPING_MASS : minimalVolume}
              orderId={orderId}
              plateId={plateId}
              value={viewMass ? shippingMass : shippingVolume}
              wellId={wellMapping?.productionWellId}
            />
          );
        }}
      />
    </div>
  );
}

export default function ShippingPlates({
  data,
  mapping,
  plateItems,
}: {
  data: OrderDetailsTRPC;
  mapping: FillAndFinishMapping;
  plateItems: OrderDetailsTRPCItem[];
}) {
  const { fillAndFinish } = data;
  const isFnFCompleted = fillAndFinish?.isCompleted ?? false;
  const shippingWells = mapping.filter((m) => m.plate);
  const plateIds = Array.from(
    new Set(shippingWells.map((m) => m.plate?.index)),
  );
  if (plateIds.length === 0) {
    return "No plates";
  }
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex flex-col space-y-2">
        <h3 className="text-md font-bold">Plates</h3>
        <div className="flex space-y-1">
          {plateIds.map((plateIndex, index) => {
            const shippingPlateWells = shippingWells.filter(
              (m) => m.plate && m.plate.index === plateIndex,
            );
            return (
              <ShippingPlate
                index={index}
                isFnFCompleted={isFnFCompleted}
                key={plateIndex}
                plateItems={plateItems}
                shippingPlateWells={shippingPlateWells}
              />
            );
          })}
        </div>
      </div>
    </DndProvider>
  );
}
