import { OligoStatus } from "@console/shared";
import type { Row } from "@tanstack/react-table";
import { PlusSquare } from "lucide-react";
import { useCallback } from "react";

import type { BacklogOligo } from "./oligo-row";

import { AlertDialogWrapper } from "../../../../../../components/ui/alert-dialog";
import { Button } from "../../../../../../components/ui/button";
import { Checkbox } from "../../../../../../components/ui/checkbox";
import { Input } from "../../../../../../components/ui/input";
import { Label } from "../../../../../../components/ui/label";
import { Separator } from "../../../../../../components/ui/separator";
import { Switch } from "../../../../../../components/ui/switch";
import { useToast } from "../../../../../../components/ui/use-toast";
import { trpc } from "../../../../../../config/trpc";
import type { LiberationState } from "../../containers/NewPlateContainer";
import { getOligoQCPrefilledValue } from "../../containers/oligoQC";
import useNewPlateContext from "../../hooks/useNewPlateContext";

export default function OligoActions() {
  const { toast } = useToast();

  const {
    displayQCColumns,
    displayReplicatesAsSubRows,
    getSelectedOligos,
    initPlate,
    isCreatingPlate,
    isLiberating,
    liberationState,
    oligosById,
    setDisplayQCColumns,
    setDisplayReplicatesAsSubRows,
    setIsLiberating,
    setLiberationState,
  } = useNewPlateContext();

  const utils = trpc.useUtils();
  const { mutate: liberateOligos } = trpc.order.liberate.useMutation({
    onSuccess() {
      setIsLiberating(false);
      toast({
        title: "Oligos liberated successfully",
        variant: "success",
      });
      utils.order.oligos.invalidate();
    },
  });

  const startLiberation = useCallback(() => {
    const selectedOligos = getSelectedOligos();
    if (selectedOligos.length === 0) {
      toast({
        title: "No oligos selected",
        variant: "destructive",
      });
      return;
    }
    const oligos: BacklogOligo[] = [];
    for (const oligoId of selectedOligos) {
      const oligo = oligosById.get(oligoId);
      if (!oligo) {
        toast({
          title: `Oligo with ID ${oligoId} not found`,
          variant: "destructive",
        });
        return;
      }
      if (
        oligo.status !== OligoStatus.Synthesized &&
        oligo.status !== OligoStatus.PendingQC
      ) {
        toast({
          title: `Oligo ${oligo.name} is not synthesized`,
          variant: "destructive",
        });
        return;
      }
      oligos.push(oligo);
    }
    setIsLiberating(true);
    setLiberationState(
      oligos.reduce((acc, oligo) => {
        const qcPrefilledValue = getOligoQCPrefilledValue(oligo);
        return {
          ...acc,
          [oligo.id]: {
            liberation: qcPrefilledValue,
            qcPrefilledValue: qcPrefilledValue,
          },
        };
      }, {} as LiberationState),
    );
  }, [
    getSelectedOligos,
    oligosById,
    setIsLiberating,
    setLiberationState,
    toast,
  ]);

  const handleConfirmLiberation = () => {
    liberateOligos(
      Object.entries(liberationState).map(([id, { liberation }]) => ({
        id,
        liberate: Boolean(liberation),
        reason: typeof liberation === "string" ? liberation : undefined,
      })),
    );
  };

  return (
    <div className="flex flex-row items-center space-x-2">
      <div className="grid grid-cols-3 place-items-center gap-2">
        <Label className="col-span-2" htmlFor="display-qc-columns">
          Group replicates
        </Label>
        <Switch
          checked={Boolean(displayReplicatesAsSubRows)}
          className="data-[state=checked]:bg-secondary"
          id="display-qc-columns"
          onCheckedChange={setDisplayReplicatesAsSubRows}
        />
        <Label className="col-span-2" htmlFor="display-qc-columns">
          Display QC Columns
        </Label>
        <Switch
          checked={Boolean(displayQCColumns)}
          className="data-[state=checked]:bg-secondary"
          id="display-qc-columns"
          onCheckedChange={(shouldDisplayQC) => {
            setDisplayQCColumns(shouldDisplayQC);
            if (!shouldDisplayQC) {
              setLiberationState({});
              setIsLiberating(false);
            }
          }}
        />
      </div>
      <Separator orientation="vertical" />
      {isLiberating ? (
        <div className="flex flex-row space-x-1">
          <Button onClick={() => setIsLiberating(false)} variant="outline">
            Cancel
          </Button>
          <AlertDialogWrapper
            description="Are you sure you want to liberate or fail the oligos ? This action cannot be undone."
            onConfirm={handleConfirmLiberation}
            title="Liberate oligos"
          >
            <Button>Liberate</Button>
          </AlertDialogWrapper>
        </div>
      ) : (
        <Button onClick={startLiberation} variant={"outline"}>
          Start liberation
        </Button>
      )}

      {!isCreatingPlate && !isLiberating && (
        <>
          <Separator orientation="vertical" />
          <div className="justify-end">
            <Button
              className="flex space-x-1"
              onClick={initPlate}
              type="button"
            >
              <span>New synthesis plate</span>
              <PlusSquare />
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export const LiberationCell = ({ row }: { row: Row<BacklogOligo> }) => {
  const { liberationState, setLiberationState } = useNewPlateContext();
  const oligoLiberationState = liberationState[row.original.id];
  if (oligoLiberationState === undefined) {
    return null;
  }

  const { liberation, qcPrefilledValue } = oligoLiberationState;

  if (typeof liberation === "boolean") {
    return (
      <Checkbox
        checked={Boolean(liberation)}
        className="border-secondary data-[state=checked]:bg-secondary"
        onCheckedChange={(newVal) => {
          setLiberationState((old) => ({
            ...old,
            [row.original.id]: {
              liberation:
                newVal && !qcPrefilledValue ? "Default explanation" : newVal,
              qcPrefilledValue,
            },
          }));
        }}
        onClick={(e) => e.stopPropagation()}
      />
    );
  }

  return (
    <div className="flex flex-row items-center space-x-2">
      <Checkbox
        checked={true}
        className="border-secondary data-[state=checked]:bg-secondary"
        onCheckedChange={() =>
          setLiberationState((old) => ({
            ...old,
            [row.original.id]: {
              liberation: false,
              qcPrefilledValue,
            },
          }))
        }
        onClick={(e) => e.stopPropagation()}
      />
      <div className="flex flex-col space-y-1">
        <Label htmlFor="liberation-explanation">
          Explanation for liberation
        </Label>
        <Input
          id="liberation-explanation"
          minLength={1}
          onChange={(e) => {
            setLiberationState((old) => ({
              ...old,
              [row.original.id]: {
                liberation: e.target.value,
                qcPrefilledValue,
              },
            }));
          }}
          onClick={(e) => e.stopPropagation()}
          value={liberation}
        />
      </div>
    </div>
  );
};

export const FailCell = ({ row }: { row: Row<BacklogOligo> }) => {
  const { liberationState, setLiberationState } = useNewPlateContext();

  const oligoLiberationState = liberationState[row.original.id];
  if (oligoLiberationState === undefined) {
    return null;
  }

  const { liberation, qcPrefilledValue } = oligoLiberationState;

  return (
    <Checkbox
      checked={liberation === false}
      className="border-secondary data-[state=checked]:bg-secondary"
      onCheckedChange={(newVal) =>
        setLiberationState((old) => ({
          ...old,
          [row.original.id]: {
            liberation: !newVal,
            qcPrefilledValue,
          },
        }))
      }
      onClick={(e) => e.stopPropagation()}
    />
  );
};
