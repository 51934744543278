import { useEffect, useState } from "react";

import { MINIMUM_SHIPPING_MASS } from "./reducer";

import type { OrderDetailsOligoTRPC } from "../types";

type AllowedRange = ReturnType<typeof getOligoAllowedRange>;

export function getOligoAllowedRange(
  oligo: OrderDetailsOligoTRPC,
  currentShippingVolume: number,
) {
  const productionVolume = oligo.qcResults?.runningVolume ?? 0;
  const productionConcentration = oligo.qcResults?.concentration ?? 0;
  const productionMass = productionVolume * productionConcentration;
  const shippingVolume = currentShippingVolume;
  const minimalVolume =
    (MINIMUM_SHIPPING_MASS / productionMass) * productionVolume;
  const shippingMass = shippingVolume * productionConcentration;
  return {
    maximumMass: productionMass,
    maximumVolume: productionVolume,
    minimalVolume,
    shippingMass,
    shippingVolume,
  };
}

export function useGetLocalVolumesAndMass(volumes: AllowedRange[]) {
  const minShippingVolumeCommonToEachWell = Math.max(
    ...volumes.map((v) => v.minimalVolume),
  );
  const maxShippingVolumeCommonToEachWell = Math.min(
    ...volumes.map((v) => v.maximumVolume),
  );
  const maximumMass = Math.min(...volumes.map((v) => v.maximumMass));
  const firstWellShippingVolume = volumes[0]?.shippingVolume;
  const firstWellShippingMass = volumes[0]?.shippingMass;
  const allMassesAreEqual = volumes.every(
    (v) => v.shippingMass === firstWellShippingMass,
  );
  const getCommonMass = () => {
    if (allMassesAreEqual) {
      return firstWellShippingMass;
    }
    return undefined;
  };
  const allVolumesAreEqual = volumes.every(
    (v) => v.shippingVolume === firstWellShippingVolume,
  );
  const commonMass = getCommonMass();
  const getCommonVolume = () => {
    if (allVolumesAreEqual) {
      return firstWellShippingVolume;
    }
    return undefined;
  };
  const commonVolume = getCommonVolume();

  const [localCommonVolume, setLocalCommonVolume] = useState<
    number | undefined
  >(commonVolume);
  const [localCommonMass, setLocalCommonMass] = useState<number | undefined>(
    commonMass,
  );

  useEffect(() => {
    setLocalCommonVolume(commonVolume);
  }, [commonVolume]);

  useEffect(() => {
    setLocalCommonMass(commonMass);
  }, [commonMass]);

  return {
    localCommonMass,
    localCommonVolume,
    maxShippingVolumeCommonToEachWell,
    maximumMass,
    minShippingVolumeCommonToEachWell,
    setLocalCommonMass,
    setLocalCommonVolume,
  };
}
