import { Purity, ModificationStatus } from "@console/shared";

import { ModificationStatusToPosition } from "./constants";
import type { PlateKit, WorkflowWell } from "./types";

import { getSequenceLength } from "../../../../../../utils/parser";

export const DefaultBackgroundColor = "bg-background";
const defaultText = "text-slate-800";
const textWhite = "text-white";

export const defaultWellColors = {
  background: DefaultBackgroundColor,
  text: defaultText,
  useGradient: undefined,
};

const MAX_SEQUENCE_LENGH = 500;
const MIN_SEQUENCE_LENGH = 15;
const THRESHOLD_OP2 = 80;

export const getLengthColors = (well: {
  sequence: string;
}): {
  background: string;
  text: string;
  useGradient?: {
    gradient: string;
    position: number;
  };
} => {
  if (!well.sequence) {
    return defaultWellColors;
  }
  const sequenceLength = getSequenceLength(well.sequence);
  const value = Math.min(
    MAX_SEQUENCE_LENGH,
    Math.max(MIN_SEQUENCE_LENGH, sequenceLength),
  );
  const logarithm = Math.log(value);
  const percentage =
    ((logarithm - Math.log(MIN_SEQUENCE_LENGH)) /
      (Math.log(MAX_SEQUENCE_LENGH) - Math.log(MIN_SEQUENCE_LENGH))) *
    100;
  return {
    background: DefaultBackgroundColor,
    text: percentage > 40 ? textWhite : defaultText,
    useGradient: {
      gradient: "var(--report-sequence-gradient)",
      position: percentage,
    },
  };
};

export const getVolumeColors = (
  volumeValue: number | undefined | null,
  volume: PlateKit["volume"],
): {
  background: string;
  text: string;
  useGradient?: {
    gradient: string;
    position: number;
  };
} => {
  if (volumeValue === undefined || volumeValue === null) {
    return defaultWellColors;
  }
  const volumeRange = volume.max - volume.min;
  const percentage = Math.min(
    100,
    Math.max(0, ((volumeValue ?? 0) - volume.min) / volumeRange) * 100,
  );

  return {
    background: DefaultBackgroundColor,
    text: percentage > 40 ? textWhite : defaultText,
    useGradient: {
      gradient: "var(--report-volume-gradient)",
      position: percentage,
    },
  };
};

export const getABIColors = (
  bandPercent: number | undefined | null,
): {
  background: string;
  text: string;
  useGradient?: {
    gradient: string;
    position: number;
  };
} => {
  if (!bandPercent) {
    return defaultWellColors;
  }
  return {
    background: "bg-green-500",
    text: textWhite,
  };
};

export const getLCMSColors = (
  isPass: boolean | undefined,
): {
  background: string;
  text: string;
  useGradient?: {
    gradient: string;
    position: number;
  };
} => {
  if (isPass === undefined) {
    return defaultWellColors;
  }
  return {
    background: isPass ? "bg-green-500" : "bg-red-500",
    text: textWhite,
  };
};

export const getNGSColors = (
  bandPercent: number | undefined | null,
): {
  background: string;
  text: string;
  useGradient?: {
    gradient: string;
    position: number;
  };
} => {
  if (!bandPercent) {
    return defaultWellColors;
  }
  return {
    background: "bg-green-500",
    text: textWhite,
  };
};

export const getOP2Colors = (
  bandPercent: number | undefined | null,
): {
  background: string;
  text: string;
  useGradient?: {
    gradient: string;
    position: number;
  };
} => {
  if (!bandPercent) {
    return defaultWellColors;
  }
  if (bandPercent < THRESHOLD_OP2) {
    return {
      background: "bg-red-500",
      text: textWhite,
    };
  }
  return {
    background: "bg-green-500",
    text: textWhite,
  };
};

export const getConcentrationColors = (
  concentration: number | undefined | null,
  kitConcentration: PlateKit["concentration"],
): {
  background: string;
  text: string;
  useGradient?: {
    gradient: string;
    position: number;
  };
} => {
  if (!concentration) {
    return defaultWellColors;
  }
  const concentrationRange = kitConcentration.max - kitConcentration.min;
  const percentage = Math.min(
    100,
    Math.max(
      0,
      ((concentration ?? 0) - kitConcentration.min) / concentrationRange,
    ) * 100,
  );
  return {
    background: DefaultBackgroundColor,
    text: percentage > 40 ? textWhite : defaultText,
    useGradient: {
      gradient: "var(--report-concentration-gradient)",
      position: percentage,
    },
  };
};

export const getYieldColors = (
  yieldValue: number | undefined | null,
  yields: PlateKit["yields"],
): {
  background: string;
  text: string;
  useGradient?: {
    gradient: string;
    position: number;
  };
} => {
  if (!yieldValue) {
    return defaultWellColors;
  }
  const percentage = Math.min(
    100,
    Math.max(
      0,
      (yieldValue - yields.medium.min) / (yields.high.min - yields.low.max),
    ) * 100,
  );
  return {
    background: DefaultBackgroundColor,
    text: percentage > 40 ? textWhite : defaultText,
    useGradient: {
      gradient: "var(--report-yield-gradient)",
      position: percentage,
    },
  };
};

export const getColorsFromPurity = (purityFlag: Purity) => {
  if (purityFlag === Purity.Unknown) {
    return defaultWellColors;
  }
  if (purityFlag === Purity.Extreme) {
    return {
      background: "bg-red-500",
      text: textWhite,
    };
  }
  if (purityFlag === Purity.Difficult) {
    return {
      background: "bg-orange-500",
      text: textWhite,
    };
  }
  return {
    background: "bg-green-500",
    text: textWhite,
  };
};

export const getModificationColors = (well: WorkflowWell) => {
  if (!well.result) {
    return defaultWellColors;
  }
  const percentage =
    ModificationStatusToPosition[
      well.result?.sequenceModificationStatus ?? ModificationStatus.None
    ];
  return {
    background: DefaultBackgroundColor,
    text: percentage > 40 ? textWhite : defaultText,
    useGradient: {
      gradient: "var(--report-modification-gradient)",
      position: percentage,
    },
  };
};

export const getOrderColors = (
  well: WorkflowWell,
  getWellOrderBgColor: (index: string) => string | undefined,
) => {
  if (!well.order) {
    return defaultWellColors;
  }
  const bgColor = getWellOrderBgColor(well.id);
  if (!bgColor) {
    return defaultWellColors;
  }
  return {
    background: bgColor,
    text: defaultText,
  };
};
