export enum SequenceShippingFormat {
  Plate = "96 well-plate",
  Tube = "Tube",
}

export enum OrderItemType {
  GeneFragment = "Gene Fragment",
  OligoSet = "Oligo set",
}

export enum OrderStatus {
  Canceled = "Canceled",
  Completed = "Completed",
  InFillNFinish = "In fill and finish",
  InProduction = "In production",
  Opportunity = "Opportunity",
  Queued = "Queued",
  Shipping = "Shipping",
}

export enum OrderEvent {
  Archived = "Archived",
  Canceled = "Canceled",
  Completed = "Completed",
  Opportunity = "Opportunity",
  Queued = "Queued",
  Reopened = "Reopened",
  FillAndFinishCreated = "FillAndFinishCreated",
  FillAndFinishCompleted = "FillAndFinishCompleted",
}

export enum AdditionalService {
  GeneFragmentAmplification = "Gene Fragment Amplification",
  NGSSequencing = "NGS Sequencing",
}

export const AdditionalServiceLabel: Record<AdditionalService, string> = {
  [AdditionalService.GeneFragmentAmplification]: "Amplification",
  [AdditionalService.NGSSequencing]: "NGS",
};

export enum OrderWorksheet {
  AdditionalServices = "Additional Services",
  SKU = "SKU",
  SequencesV0 = "Customer Sequences",
  SequencesV1ssDNA = "ssDNA-sequence-request",
}