import { Info } from "lucide-react";

import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";

export default function DateTooltip({
  date,
  prefix = "Uploaded at",
}: {
  date: string | null | undefined;
  prefix?: string;
}) {
  const dateString = date ? new Date(date).toLocaleString() : "N/A";
  return (
    <Tooltip delayDuration={200}>
      <TooltipTrigger>
        <Info size={16} />
      </TooltipTrigger>
      <TooltipContent>
        <p className="space-x-1">
          <span>{prefix}</span>
          <span>{dateString}</span>
        </p>
      </TooltipContent>
    </Tooltip>
  );
}
