import { OligoStatus, SequenceShippingFormat } from "@console/shared";
import { useParams } from "react-router-dom";

import { AbiSummary, LCMSSummary } from "./components/qc-basic-components";
import ShippingPlates from "./fillAndFinish/shipping-plates";
import ShippingTubes from "./fillAndFinish/shipping-tubes";
import type { OrderDetailsTRPC, TubeItem } from "./types";

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import { Label } from "../../../../components/ui/label";
import { RadioGroup, RadioGroupItem } from "../../../../components/ui/radio";
import { useToast } from "../../../../components/ui/use-toast";
import { trpc } from "../../../../config/trpc";

function FillAndFinishDetails({ data }: { data: OrderDetailsTRPC }) {
  const { netsuiteSalesOrderId, items, fillAndFinish, id: orderId } = data;
  const { mapping, isCompleted: isFnFCompleted } = fillAndFinish ?? {
    mapping: [],
  };
  const { toast } = useToast();
  const utils = trpc.useUtils();
  const { mutate: updateItemProductionWell } =
    trpc.order.fillAndFinish.updateItemProductionWell.useMutation({
      onSuccess(_, variables) {
        toast({
          title: "Updated item production well",
          variant: "success",
        });
        utils.order.read.setData(variables.orderId, (prev) => {
          if (!prev) {
            return prev;
          }
          const item = items.find((i) => i.id === variables.itemId);
          const itemWellIds = item?.oligos.map(
            (o) => o.placement?.wellId,
          ) as string[];
          if (!prev.fillAndFinish) {
            return prev;
          }
          const oldMappingItem = prev.fillAndFinish.mapping.find((m) =>
            itemWellIds.includes(m.productionWellId),
          );
          return {
            ...prev,
            fillAndFinish: {
              ...prev.fillAndFinish,
              mapping: prev.fillAndFinish?.mapping.map((m) => {
                if (m.productionWellId === oldMappingItem?.productionWellId) {
                  return {
                    ...m,
                    productionWellId: variables.productionWellId,
                  };
                }
                return m;
              }),
            },
          };
        });
      },
    });

  const itemsToLiberate = items.filter((item) =>
    item.oligos.some((o) => o.status === OligoStatus.Liberated),
  );

  const parentWellIds = mapping.map((m) => m.productionWellId);
  const selectedItems = items.filter((item) =>
    item.oligos.some(
      (o) => o.placement && parentWellIds.includes(o.placement.wellId),
    ),
  );

  const tubeItems: TubeItem[] = selectedItems
    .filter((item) => item.shippingFormat === SequenceShippingFormat.Tube)
    .map((item) => ({
      ...item,
      tubeName: `${netsuiteSalesOrderId}_${item.name}`,
    }));

  const plateItems = selectedItems.filter(
    (item) => item.shippingFormat === SequenceShippingFormat.Plate,
  );

  const handleChangeProductionWell =
    (orderItemId: string) => (wellId: string) => {
      updateItemProductionWell({
        itemId: orderItemId,
        orderId,
        productionWellId: wellId,
      });
    };

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex justify-between">
          <p>{`Fill and finish for order ${netsuiteSalesOrderId}`}</p>
        </CardTitle>
      </CardHeader>
      <CardContent className="grid grid-cols-5 gap-4">
        <ul className="col-span-3 space-y-3 divide-y-2">
          <li className="grid grid-cols-11 gap-2">
            <p className="col-span-2 font-bold">Oligo</p>
            <p className="font-bold">Plate</p>
            <p className="font-bold">Mass (pmol)</p>
            <p className="font-bold">Volume (µL)</p>
            <p className="font-bold">Concentration (µM)</p>
            <p className="font-bold">Final yield (pmol)</p>
            <p className="font-bold">ABI Purity</p>
            <p className="font-bold">OP2 Purity</p>
            <p className="font-bold">NGS error rate</p>
            <p className="font-bold">LCMS</p>
          </li>
          <div className="max-h-[75vh] overflow-y-scroll">
            {itemsToLiberate.map((item) => {
              const oligoSelected = item.oligos.find((o) =>
                parentWellIds.includes(o.placement?.wellId ?? ""),
              );
              if (!oligoSelected) {
                return null;
              }
              const onRepeatSelectedChange = (newOligoId: string) => {
                const oligo = item.oligos.find((o) => o.id === newOligoId);
                handleChangeProductionWell(item.id)(
                  oligo?.placement?.wellId ?? "",
                );
              };
              return (
                <li key={item.id}>
                  <p className="font-bold">{item.name}</p>
                  <ul className="text-sm">
                    <RadioGroup
                      className="gap-0 divide-y"
                      disabled={isFnFCompleted}
                      onValueChange={onRepeatSelectedChange}
                      value={oligoSelected.id}
                    >
                      {item.oligos
                        .filter((o) => o.status === OligoStatus.Liberated)
                        .map((oligo) => {
                          const { placement, name, qcResults, id } = oligo;
                          if (!placement || !qcResults) {
                            return null;
                          }
                          const {
                            runningMass,
                            runningVolume,
                            concentration,
                            finalYield,
                            op2Purity,
                            ngsErrorRate,
                          } = qcResults;
                          const position = `${placement.plate} - ${placement.well}`;
                          return (
                            <li
                              className="grid grid-cols-11 gap-2 rounded-md"
                              data-group={placement.wellId}
                              key={oligo.id}
                            >
                              <div className="col-span-2 flex flex-row items-center space-x-1">
                                <RadioGroupItem id={id} value={id} />
                                <Label
                                  className="max-w-full text-wrap group-hover:bg-slate-100"
                                  htmlFor={id}
                                >
                                  {name}
                                </Label>
                              </div>
                              <p>{position}</p>
                              <p>{runningMass?.toFixed(0) ?? "-"}</p>
                              <p>{runningVolume?.toFixed(0) ?? "-"}</p>
                              <p>{concentration?.toFixed(1) ?? "-"}</p>
                              <p>{finalYield?.toFixed(0) ?? "-"}</p>
                              <AbiSummary qcResults={qcResults} />
                              <p>{op2Purity?.toFixed(0) ?? "-"}</p>
                              <p>{ngsErrorRate?.toFixed(0) ?? "-"}</p>
                              <LCMSSummary qcResults={qcResults} />
                            </li>
                          );
                        })}
                    </RadioGroup>
                  </ul>
                </li>
              );
            })}
          </div>
        </ul>
        <div className="col-span-2 space-y-2">
          <h3 className="text-lg font-bold">Shipping</h3>
          <ShippingTubes data={data} mapping={mapping} tubeItems={tubeItems} />
          <ShippingPlates
            data={data}
            mapping={mapping}
            plateItems={plateItems}
          />
        </div>
      </CardContent>
    </Card>
  );
}

export default function FillAndFinish() {
  const { orderId } = useParams();

  const { data } = trpc.order.read.useQuery(orderId ?? "");

  if (!data || !data.fillAndFinish) {
    return null;
  }

  return <FillAndFinishDetails data={data} />;
}
