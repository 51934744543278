import {
  createSyntaxInstrumentSchema,
  InstrumentHardwareVersion,
  InstrumentStatus,
  instrumentHardwareVersions,
} from "@console/shared";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@frontend/components/ui/select";
import { zodResolver } from "@hookform/resolvers/zod";
import { Card, Heading } from "@radix-ui/themes";
import { SaveIcon, UndoIcon } from "lucide-react";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import type { z } from "zod";

import { useGetInstrument } from "./useGetInstrument";

import { Button } from "../../components/ui/button";
import { CardHeader, CardTitle, CardContent } from "../../components/ui/card";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Switch } from "../../components/ui/switch";
import { useToast } from "../../components/ui/use-toast";
import { trpc } from "../../config/trpc";
import { OrganizationRoutes } from "../organization/organization-routes";
import { useGetMyOrganization } from "../settings/organization-settings/hooks";

const editInstrumentForm = createSyntaxInstrumentSchema;
type EditInstrumentForm = z.infer<typeof editInstrumentForm>;

export default function InstrumentEdit() {
  const navigate = useNavigate();
  const { data: instrument, isPending } = useGetInstrument();
  const { toast } = useToast();
  const utils = trpc.useUtils();
  const { mutate: updateInstrument } =
    trpc.instrument.syntax.update.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Error",
          variant: "destructive",
        });
      },
      onSuccess() {
        utils.instrument.syntax.get.invalidate({
          instrumentId: instrument?.id,
        });
        navigate(
          OrganizationRoutes.INSTRUMENT.replace(
            ":instrumentId",
            instrument?.id ?? "",
          ),
        );
      },
    });

  const organizationData = useGetMyOrganization();
  const hasTriggerFromOutsideOption = Boolean(
    organizationData?.configuration.remoteRunTriggerEnabled,
  );

  const defaultValues = useMemo(
    () =>
      ({
        canBeTriggeredExternally: instrument?.canBeTriggeredExternally ?? false,
        name: instrument?.name ?? "",
        serialNumber: instrument?.serialNumber ?? "",
        version:
          instrument?.hardwareVersion ?? InstrumentHardwareVersion.HW_301,
      }) as EditInstrumentForm,
    [instrument],
  );
  const form = useForm<EditInstrumentForm>({
    defaultValues: {},
    resolver: zodResolver(editInstrumentForm),
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  if (isPending) return <Heading>Loading...</Heading>;

  if (!instrument) {
    return <Heading>Instrument not found</Heading>;
  }

  const onSubmit = (data: EditInstrumentForm) => {
    updateInstrument({
      canBeTriggeredExternally: data.canBeTriggeredExternally,
      instrumentId: instrument.id,
      name: data.name,
      serialNumber: data.serialNumber,
      version: data.version,
    });
  };

  return (
    <Form {...form}>
      <form
        className="flex flex-col space-y-8"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Heading className="space-x-2">
          <span>Instrument</span>
          <span className="italic">{instrument.name}</span>
        </Heading>
        <Card>
          <CardHeader className="flex flex-row justify-between">
            <CardTitle>Edit instrument</CardTitle>
            <div className="space-x-2">
              <Button
                onClick={() => {
                  navigate(
                    OrganizationRoutes.INSTRUMENT.replace(
                      ":instrumentId",
                      instrument.id,
                    ),
                  );
                }}
                variant={"outline"}
              >
                <UndoIcon />
              </Button>
              <Button type="submit">
                <SaveIcon />
              </Button>
            </div>
          </CardHeader>
          <CardContent className="space-y-4">
            <FormField
              control={form.control}
              name={"name"}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl className="mt-0">
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={"serialNumber"}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Serial number</FormLabel>
                  <FormControl className="mt-0">
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="version"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Version</FormLabel>
                  <Select
                    defaultValue={field.value}
                    disabled={InstrumentStatus.Available === instrument.status}
                    onValueChange={field.onChange}
                    {...field}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {Object.entries(instrumentHardwareVersions).map(
                        ([key, value]) => (
                          <SelectItem key={key} value={key}>
                            {value}
                          </SelectItem>
                        ),
                      )}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            {hasTriggerFromOutsideOption && (
              <FormField
                control={form.control}
                name="canBeTriggeredExternally"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center space-x-2">
                    <FormControl className="mt-0">
                      <Switch
                        checked={field.value}
                        className="mt-0"
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <div>
                      <FormLabel>Trigger from outside</FormLabel>
                      <FormDescription>
                        Instrument runs can be triggered from console or the API
                      </FormDescription>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
          </CardContent>
        </Card>
      </form>
    </Form>
  );
}
