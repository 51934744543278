export const OrganizationRoutes = {
  ASSAY: "/organization/assays/:assayId",
  ASSAYS: "/organization/assays",
  ASSAY_NEW: "/organization/new-assay",
  ASSAY_PREPARE: "/organization/assays/:assayId/prepare",
  ASSAY_WORKFLOW_STEP: "/organization/assays/:assayId/step/:stepId",
  CONSTRUCT: "/organization/constructs/:constructId",
  CONSTRUCT_GENE_DESIGN_EDIT:
    "/organization/constructs/:constructId/designs/:designId",
  CONSTRUCT_GENE_DESIGN_NEW: "/organization/constructs/:constructId/design",
  CONSTRUCT_NEW: "/organization/new-construct",
  HAMILTON_INSTRUMENT_NEW: "/organization/new-instrument/hamilton",
  INSTRUMENT: "/organization/instruments/:instrumentId",
  INSTRUMENTS: "/organization/instruments",
  INSTRUMENT_EDIT: "/organization/instruments/:instrumentId/edit",
  INSTRUMENT_HAMILTON: "/organization/hamilton/:instrumentId",
  INSTRUMENT_HAMILTON_EDIT: "/organization/hamilton/:instrumentId/edit",
  INSTRUMENT_NEW: "/organization/new-instrument",
  INSTRUMENT_SYNTAX_NEW: "/organization/new-instrument/syntax",
  INSTRUMENT_TROUBLESHOOTING:
    "/organization/instruments/:instrumentId/troubleshooting",
  LIBRARY: "/organization/library",
  QUICK_PLATE: "/organization/new-quick-plate",
  RUNS: "/organization/runs",
  SERVICE: "/organization/service",
  SERVICE_OLIGOS: "/organization/service/oligos",
  SERVICE_ORDER: "/organization/service/orders/:orderId",
  SERVICE_ORDERS: "/organization/service/orders",
  SERVICE_ORDER_FNF: "/organization/service/orders/:orderId/fill-and-finish",
  SERVICE_PLATE: "/organization/service/plates/:plateId",
  SERVICE_PLATES: "/organization/service/plates",
} as const;

export type OrganizationRoute =
  (typeof OrganizationRoutes)[keyof typeof OrganizationRoutes];

type Tab = {
  id: string;
  link: string;
  path: OrganizationRoute;
};

const ORGANIZATION_TABS: Tab[] = [
  {
    id: "constructs",
    link: "Constructs",
    path: OrganizationRoutes.LIBRARY,
  },
  {
    id: "assays",
    link: "Assays",
    path: OrganizationRoutes.ASSAYS,
  },
  {
    id: "instruments",
    link: "Instruments",
    path: OrganizationRoutes.INSTRUMENTS,
  },
  {
    id: "runs",
    link: "Runs",
    path: OrganizationRoutes.RUNS,
  },
];

const SERVICE_TABS: Tab[] = [
  {
    id: "orders",
    link: "Orders",
    path: OrganizationRoutes.SERVICE_ORDERS,
  },
  {
    id: "oligos",
    link: "Oligos",
    path: OrganizationRoutes.SERVICE_OLIGOS,
  },
  {
    id: "plates",
    link: "Plates",
    path: OrganizationRoutes.SERVICE_PLATES,
  },
  {
    id: "instruments",
    link: "Instruments",
    path: OrganizationRoutes.INSTRUMENTS,
  },
];

export const useGetOrganizationTabs = (serviceEnabled: boolean) => {
  if (!serviceEnabled) {
    return ORGANIZATION_TABS;
  }
  return SERVICE_TABS;
};
