import { Heading } from "@radix-ui/themes";
import { EditIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";

import TeamMembershipsBlock from "./organization-settings/components/team-memberships-block";
import { SettingRoutes } from "./setting-routes";
import UserNotFound from "./user-not-found";
import AddMFA from "./user-profile-components/mfa";
import ResetMfaRecovery from "./user-profile-components/mfa-recovery-reset";

import { RoleList } from "../../components/logic/roles";
import { Badge } from "../../components/ui/badge";
import { Button } from "../../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Checkbox } from "../../components/ui/checkbox";
import { useUser } from "../../containers/hooks";
import { CheckIfTrueElseCross } from "../admin/organizations/components/ui";

export default function UserProfile() {
  const user = useUser();
  const navigate = useNavigate();

  if (!user) {
    return <UserNotFound />;
  }

  const {
    isAdmin,
    email,
    name,
    updatedAt,
    createdAt,
    mfaActivated,
    notificationChannels,
    membership,
    roles,
  } = user;

  return (
    <div className="space-y-4">
      <div className="flex flex-row justify-between">
        <Heading>Profile</Heading>
        <div>
          <Button
            data-testid="edit-user-profile-settings"
            onClick={() => {
              navigate(SettingRoutes.PROFILE_EDIT);
            }}
            variant={"outline"}
          >
            <EditIcon />
          </Button>
        </div>
      </div>
      <Card>
        <CardHeader>
          <CardTitle>Details</CardTitle>
        </CardHeader>
        <CardContent className="space-y-2">
          <h3 className="font-bold">Details</h3>
          <div className="flex flex-row items-center space-x-2">
            <span>Email</span>
            <span>{email}</span>
          </div>
          <div className="flex flex-row items-center space-x-2">
            <span>Name</span>
            <span>{name}</span>
          </div>
          <div className="flex flex-row items-center space-x-2">
            <span>Created at</span>
            <span>{new Date(createdAt).toLocaleDateString()}</span>
          </div>
          <div className="flex flex-row items-center space-x-2">
            <span>Last update</span>
            <span>{new Date(updatedAt).toLocaleString()}</span>
          </div>
          <div className="flex flex-row items-center space-x-2">
            <span>MFA</span>
            <CheckIfTrueElseCross value={mfaActivated} />
            <span>{mfaActivated ? "Activated" : "Not activated"}</span>
            <AddMFA mfaActivated={mfaActivated} />
            {mfaActivated ? <ResetMfaRecovery /> : ""}
          </div>
          <div className="flex flex-row items-center space-x-2">
            <span>Notification channels</span>
            {notificationChannels.length > 0 ? (
              notificationChannels.map((channel) => {
                return (
                  <Badge key={channel} variant={"outline"}>
                    {channel}
                  </Badge>
                );
              })
            ) : (
              <Badge variant={"secondary"}>No channels</Badge>
            )}
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>Authorizations</CardTitle>
        </CardHeader>
        <CardContent className="space-y-2">
          {roles && (
            <div>
              <span>In organization</span>
              <RoleList roles={roles} />
            </div>
          )}
          <div className="flex flex-row items-center space-x-2">
            <p className="font-bold">Is admin</p>
            <Checkbox
              checked={isAdmin}
              className="border-secondary data-[state=checked]:bg-secondary"
            />
          </div>
        </CardContent>
      </Card>
      {membership && (
        <TeamMembershipsBlock organizationUserId={membership.id} />
      )}
    </div>
  );
}
