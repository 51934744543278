import { PlateStatus } from "@console/shared";
import { Heading } from "@radix-ui/themes";
import type { ColumnDef } from "@tanstack/react-table";
import type { inferProcedureOutput } from "@trpc/server";
import { Link, useNavigate } from "react-router-dom";

import { PlateStatusComponent } from "./components/plate-status";

import { DataTable } from "../../../../components/ui/data-table/data-table";
import type { TableActionItem } from "../../../../components/ui/data-table/data-table-actions";
import { TableActions } from "../../../../components/ui/data-table/data-table-actions";
import { DataTableColumnHeader } from "../../../../components/ui/data-table/data-table-column-header";
import { arrIncludesSomeWithEmptyFn } from "../../../../components/ui/data-table/filters";
import type { AppRouter } from "../../../../config/trpc";
import { trpc } from "../../../../config/trpc";
import { useGetUIAuthorizedCreations } from "../../../../containers/user/hooks";
import { OrganizationRoutes } from "../../organization-routes";

type PlateFromList = inferProcedureOutput<
  AppRouter["order"]["productionPlates"]["list"]
>[number];

const PlateActions = ({ plate }: { plate: PlateFromList }) => {
  const { status, id } = plate;
  const utils = trpc.useUtils();
  const {
    order: { change: canModifyPlate },
  } = useGetUIAuthorizedCreations();

  const { mutate: cancelPlate } =
    trpc.order.productionPlates.cancel.useMutation({
      onSuccess() {
        utils.order.productionPlates.list.setData(undefined, (prev) => {
          if (!prev) return undefined;
          return prev.map((p) =>
            p.id === id ? { ...p, status: PlateStatus.Canceled } : p,
          );
        });
      },
    });
  const { mutate: markRunAsFailed } =
    trpc.order.productionPlates.markRunAsFailed.useMutation({
      onSuccess() {
        utils.order.productionPlates.list.setData(undefined, (prev) => {
          if (!prev) return undefined;
          return prev.map((p) =>
            p.id === id ? { ...p, status: PlateStatus.Failed } : p,
          );
        });
      },
    });

  const cancelPlateItem: TableActionItem = {
    alertDialog: {
      message:
        "Are you sure you want to cancel this plate ? This action cannot be undone.",
      title: "Cancel plate",
    },
    children: "Cancel",
    id: "cancel",
    onClick: () => cancelPlate(id),
    preventDefault: true,
  };

  const markPlateAsFailedItem: TableActionItem = {
    alertDialog: {
      message:
        "Are you sure you want to mark this the full plate as failed ? You can mark individual wells instead if needed (by selecting them individally). This action cannot be undone.",
      title: "Mark all wells in plate as failed",
    },
    children: "Mark as failed",
    id: "fail",
    onClick: () => markRunAsFailed(id),
    preventDefault: true,
  };

  const earlyPlateItems = canModifyPlate ? [cancelPlateItem] : [];
  const latePlateItems = canModifyPlate ? [markPlateAsFailedItem] : [];

  const items: TableActionItem[] = {
    [PlateStatus.AwaitingQC]: latePlateItems,
    [PlateStatus.Canceled]: [],
    [PlateStatus.Loaded]: [],
    [PlateStatus.Printing]: latePlateItems,
    [PlateStatus.Failed]: [],
    [PlateStatus.InQC]: latePlateItems,
    [PlateStatus.QueuedOnInstrument]: earlyPlateItems,
    [PlateStatus.Synthesized]: [],
    [PlateStatus.ToBeAssigned]: earlyPlateItems,
    [PlateStatus.QCCompleted]: [],
  }[status];

  if (!items.length) {
    return null;
  }

  return <TableActions items={items} />;
};

const plateColumns: ColumnDef<PlateFromList>[] = [
  {
    accessorKey: "name",
    cell: ({ row }) => row.original.name,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "name",
    meta: {
      title: "Name",
    },
  },
  {
    accessorKey: "status",
    cell: ({ row }) => PlateStatusComponent[row.original.status],
    filterFn: arrIncludesSomeWithEmptyFn,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "status",
    meta: {
      title: "Status",
      uniqueFilter: true,
    },
  },
  {
    accessorKey: "instrument",
    cell: ({ row }) => {
      if (row.original.instrument.id) {
        return (
          <Link
            className="text-blue-500 hover:underline"
            onClick={(e) => e.stopPropagation()}
            to={OrganizationRoutes.INSTRUMENT.replace(
              ":instrumentId",
              row.original.instrument.id,
            )}
          >
            {row.original.instrument.name}
          </Link>
        );
      }
      if (row.original.prototype) {
        return row.original.prototype;
      }
      return "-";
    },
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "instrument",
    meta: {
      title: "Instrument",
    },
  },
  {
    accessorKey: "createdBy",
    cell: ({ row }) => row.original.createdBy,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "createdBy",
    meta: {
      title: "Creator",
    },
  },
  {
    accessorKey: "createdAt",
    cell: ({ row }) => new Date(row.original.createdAt).toLocaleString(),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "createdAt",
    meta: {
      title: "Creation date",
    },
  },
  {
    accessorKey: "filledWells",
    cell: ({ row }) => row.original.filledWells,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "filledWells",
    meta: {
      title: "Filled wells",
    },
  },
  {
    cell: ({ row }) => {
      return <PlateActions plate={row.original} />;
    },
    enableHiding: false,
    header: "Actions",
    id: "actions",
  },
];

export default function Plates() {
  const { data: plates } = trpc.order.productionPlates.list.useQuery();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col space-y-3">
      <Heading>Plates</Heading>
      <DataTable
        columns={plateColumns}
        data={plates ?? []}
        onRowClick={(row) => {
          navigate(
            OrganizationRoutes.SERVICE_PLATE.replace(
              ":plateId",
              row.original.id,
            ),
          );
        }}
      />
    </div>
  );
}
