import type { ColumnDef, Row, RowData } from "@tanstack/react-table";
import type { Table } from "@tanstack/table-core";

import { IndeterminateCheckbox } from "../table/basic";
import { Checkbox } from "../ui/checkbox";
import { SELECTION_COLUMN_ID } from "../ui/data-table/data-table-pagination";

export function CellCheckbox<T extends RowData>({
  row,
  stopPropagation = false,
}: {
  row: Row<T>;
  stopPropagation?: boolean;
}) {
  // TODO: understand why Checkbox from radix UI doesn't work
  return (
    <IndeterminateCheckbox
      aria-label="Select row"
      checked={row.getIsSelected()}
      disabled={!row.getCanSelect()}
      id={`select-row-${row.id}`}
      indeterminate={row.getIsSomeSelected()}
      onChange={(ev) => {
        row.getToggleSelectedHandler()(ev);
      }}
      onClick={(ev) => {
        if (stopPropagation) {
          ev.stopPropagation();
        }
      }}
    />
  );
}

const HeaderCheckbox = ({ table }: { table: Table<any> }) => {
  const isChecked = table.getIsAllPageRowsSelected();
  return (
    <Checkbox
      aria-label="Select all"
      checked={isChecked}
      className="border-secondary data-[state=checked]:bg-secondary"
      onCheckedChange={(value) => {
        table.toggleAllPageRowsSelected(!!value);
      }}
      type="button"
    />
  );
};

export function SelectColumn<T extends RowData>(
  SelectionComponent: React.ComponentType<{ row: Row<T> }> | undefined,
  stopPropagation = false,
): ColumnDef<T> {
  return {
    cell: ({ row }) => (
      <CellCheckbox row={row} stopPropagation={stopPropagation} />
    ),
    enableHiding: false,
    enableSorting: false,
    header: HeaderCheckbox,
    id: SELECTION_COLUMN_ID,
    meta: {
      SelectionComponent,
    },
    size: 30,
  };
}
