import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";

export default function useUpdateItemVolume(wellId: string) {
  const { toast } = useToast();
  const utils = trpc.useUtils();
  const { mutate: updateItemVolume } =
    trpc.order.fillAndFinish.updateItemVolume.useMutation({
      onSuccess(_, variables) {
        toast({
          title: "Updated tube",
          variant: "success",
        });
        utils.order.read.setData(variables.orderId, (prev) => {
          if (!prev) {
            return prev;
          }
          return {
            ...prev,
            fillAndFinish: prev.fillAndFinish
              ? {
                  ...prev.fillAndFinish,
                  mapping: prev.fillAndFinish?.mapping.map((m) => {
                    if (m.productionWellId === wellId) {
                      return {
                        ...m,
                        volume: variables.volume,
                      };
                    }
                    return m;
                  }),
                }
              : undefined,
          };
        });
      },
    });
  return updateItemVolume;
}
