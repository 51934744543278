import { Normalization, PlateSize } from "@console/shared";
import { X } from "lucide-react";
import { useCallback, useEffect, useState } from "react";

import NewPlateLegend from "./new-plate-legend";
import NewPlateWell from "./new-plate-well";
import type { NewPlateProperties } from "./new-production-plate.reducer";
import { NewPlateAction } from "./new-production-plate.reducer";

import GenericPlate from "../../../../../components/plate/generic-plate";
import { AlertDialogWrapper } from "../../../../../components/ui/alert-dialog";
import { Button } from "../../../../../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../../components/ui/card";
import CustomLink from "../../../../../components/ui/custom-link";
import { Input } from "../../../../../components/ui/input";
import { Label } from "../../../../../components/ui/label";
import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";
import { getFormattedTimeFromS } from "../../../../../utils/time";
import { useKitIdsToSizeMap } from "../../../../settings/organization-settings/hooks";
import { OrganizationRoutes } from "../../../organization-routes";
import PickConcentration from "../../assay/components/pick-concentration";
import PickKit from "../../assay/components/pick-kit";
import { useGetCurrentKitConcentrations } from "../../assay/hooks/useGetCurrentKitConcentrations";
import useComputeEstimatedTime from "../hooks/useComputeEstimatedTime";
import useNewPlateContext from "../hooks/useNewPlateContext";

export default function NewPlateMaker({
  cancel,
  newPlate,
}: {
  cancel: () => void;
  newPlate: NewPlateProperties;
}) {
  const { dispatchPlateChange: dispatcher } = useNewPlateContext();
  const { toast } = useToast();
  const kitToSize = useKitIdsToSizeMap();
  const size = kitToSize.get(newPlate.kit) ?? PlateSize.S96;

  const { kitConcentrationMax, kitConcentrationMin } =
    useGetCurrentKitConcentrations(newPlate.kit);

  const { mutate: generatePlateName } =
    trpc.order.productionPlates.yieldName.useMutation({
      onSuccess(name) {
        dispatcher({ payload: name, type: NewPlateAction.ChangeId });
      },
    });

  useEffect(() => {
    generatePlateName();
  }, [generatePlateName]);

  const reset = () => dispatcher({ type: NewPlateAction.Init });

  const utils = trpc.useUtils();
  const { mutate: createPlate } =
    trpc.order.productionPlates.create.useMutation({
      onError(err) {
        const zodError = err.data?.zodError;
        const description = zodError ? zodError : err.message;
        toast({
          description,
          title: "Error creating plate",
          variant: "destructive",
        });
      },
      onSuccess(data) {
        utils.order.oligos.invalidate();
        toast({
          description: (
            <div>
              <p>Plate created successfully</p>
              <CustomLink
                to={OrganizationRoutes.SERVICE_PLATE.replace(
                  ":plateId",
                  data.id,
                )}
              >
                View plate
              </CustomLink>
            </div>
          ),
          title: "Plate created",
          variant: "success",
        });
      },
    });

  const handleCreatePlate = () => {
    if (!newPlate.name) {
      toast({
        description: "Please provide a name for the plate",
        title: "Invalid plate name",
        variant: "destructive",
      });
      return;
    }
    const name = `${newPlate.name.id}_${newPlate.name.suffix}`;
    createPlate({
      concentration: newPlate.concentration,
      kit: newPlate.kit,
      name,
      normalization: newPlate.normalization,
      wells: newPlate.wells.map((w) => ({
        index: w.wellIndex,
        oligoId: w.oligoId,
      })),
    });
    reset();
  };

  const estimatedTime = useComputeEstimatedTime();
  const endDate =
    estimatedTime > 0
      ? new Date(new Date().getTime() + estimatedTime * 1000).toLocaleString()
      : "--";

  const currentConcentration = newPlate.concentration ?? kitConcentrationMin;

  const applyConcentrationChange = useCallback(
    (normalization: Normalization, concentration?: number) => {
      const concentrationToSet =
        normalization === Normalization.Target && !concentration
          ? kitConcentrationMin
          : concentration;
      dispatcher({
        payload: { concentration: concentrationToSet, normalization },
        type: NewPlateAction.ChangeNormalization,
      });
    },
    [dispatcher, kitConcentrationMin],
  );

  return (
    <Card className="sticky top-[70px]">
      <NewPlateHeader cancel={cancel} newPlate={newPlate} />
      <CardContent className="flex flex-col space-y-2 p-4">
        <div className="flex flex-col items-start space-y-2">
          <div className="flex flex-row items-center space-x-2">
            <Label htmlFor="kitSelection">Kit</Label>
            <PickKit
              onChange={(newKit) => {
                dispatcher({
                  payload: {
                    kit: newKit,
                    size: kitToSize.get(newKit) ?? PlateSize.S96,
                  },
                  type: NewPlateAction.ChangeKit,
                });
              }}
              value={newPlate.kit}
            />
          </div>
          <PickConcentration
            currentConcentration={currentConcentration}
            handleApplyConcentrationChange={applyConcentrationChange}
            isEditable
            kitConcentrationMax={kitConcentrationMax}
            kitConcentrationMin={kitConcentrationMin}
            normalization={newPlate.normalization}
          />
          <AlertDialogWrapper
            description={`Are you sure you want to reset the assignment ?`}
            onConfirm={reset}
            title={`Reset assignment`}
          >
            <Button variant={"outline"}>Reset assignment</Button>
          </AlertDialogWrapper>
        </div>
        <NewPlateLegend />
        <GenericPlate
          size={size}
          wellRenderer={(index) => {
            const wellWithOligo = newPlate.wells.find(
              (w) => w.wellIndex === index,
            );
            return <NewPlateWell index={index} well={wellWithOligo} />;
          }}
        />
        <div className="flex flex-col space-y-2">
          <div className="flex justify-between space-x-2">
            <div className="grid grid-cols-2 gap-2">
              <span>Estimated duration:</span>
              <span>{getFormattedTimeFromS(estimatedTime)}</span>
              <span>Estimated end:</span>
              <span>{endDate}</span>
            </div>
            <Button onClick={handleCreatePlate} type="button">
              Create plate
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function NewPlateHeader({
  newPlate,
  cancel,
}: {
  cancel: () => void;
  newPlate: NewPlateProperties;
}) {
  const { dispatchPlateChange: dispatcher } = useNewPlateContext();
  const [localName, setLocalName] = useState(newPlate.name.id);

  return (
    <CardHeader className="flex-row items-center space-x-2">
      <CardTitle>{newPlate.name.id}_</CardTitle>
      <Input
        className="min-w-[180px]"
        id="plateName"
        onBlur={() => {
          dispatcher({
            payload: localName,
            type: NewPlateAction.ChangeNameSuffix,
          });
        }}
        onChange={(e) => setLocalName(e.currentTarget.value)}
        value={localName}
      />
      <AlertDialogWrapper
        description={`Are you sure you want to close the plate ?`}
        onConfirm={cancel}
        title={`Cancel plate creation`}
      >
        <Button variant={"outline"}>
          <X />
        </Button>
      </AlertDialogWrapper>
    </CardHeader>
  );
}
