import {
  createSyntaxInstrumentSchema,
  InstrumentHardwareVersion,
  instrumentHardwareVersions,
} from "@console/shared";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@frontend/components/ui/select";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import type { z } from "zod";

import { Button } from "../../components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Switch } from "../../components/ui/switch";
import { useToast } from "../../components/ui/use-toast";
import { trpc } from "../../config/trpc";
import { useGetUIAuthorizedCreations } from "../../containers/user/hooks";
import { OrganizationRoutes } from "../organization/organization-routes";
import { useIsRemoteRunEnabled } from "../settings/organization-settings/hooks";

type NewInstrumentForm = z.infer<typeof createSyntaxInstrumentSchema>;

export default function NewSyntaxInstrument() {
  const { instrument: canCreateInstrument } = useGetUIAuthorizedCreations();
  const hasTriggerFromOutsideOption = useIsRemoteRunEnabled();
  const navigate = useNavigate();
  const { toast } = useToast();
  const form = useForm<NewInstrumentForm>({
    defaultValues: {
      canBeTriggeredExternally: false,
      name: "",
      serialNumber: "",
      version: InstrumentHardwareVersion.HW_201,
    },
    resolver: zodResolver(createSyntaxInstrumentSchema),
  });

  const { mutate: createInstrument } =
    trpc.instrument.syntax.create.useMutation({
      onError: (error) => {
        toast({
          description: error.message,
          title: "Failed to create instrument",
          variant: "destructive",
        });
      },
      onSuccess(instrumentId) {
        toast({
          description: "Instrument created",
          title: "Success",
          variant: "success",
        });
        navigate(
          OrganizationRoutes.INSTRUMENT.replace(":instrumentId", instrumentId),
        );
      },
    });

  function onSubmit(values: NewInstrumentForm) {
    createInstrument(values);
  }

  if (!canCreateInstrument) {
    navigate(OrganizationRoutes.INSTRUMENTS);
  }

  return (
    <Form {...form}>
      <form className="space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="bg-card space-y-4 rounded-lg border p-4">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input data-testid="instrument-name-input" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="serialNumber"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Serial number</FormLabel>
                <FormControl>
                  <Input
                    data-testid="instrument-serial-number-input"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="version"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Version</FormLabel>
                <Select
                  defaultValue={field.value}
                  onValueChange={field.onChange}
                  {...field}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {Object.entries(instrumentHardwareVersions).map(
                      ([key, value]) => (
                        <SelectItem key={key} value={key}>
                          {value}
                        </SelectItem>
                      ),
                    )}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          {hasTriggerFromOutsideOption && (
            <FormField
              control={form.control}
              name="canBeTriggeredExternally"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-2">
                  <FormControl className="mt-0">
                    <Switch
                      checked={field.value}
                      className="mt-0"
                      data-testId="trigger-externally-switch"
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div>
                    <FormLabel>Trigger from outside</FormLabel>
                    <FormDescription>
                      Instrument runs can be triggered from console or the API
                    </FormDescription>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </div>
        <div className="flex flex-row space-x-2">
          <Button type="submit">Submit</Button>
          <Button
            onClick={() => navigate(OrganizationRoutes.INSTRUMENTS)}
            variant={"secondary"}
          >
            Cancel
          </Button>
        </div>
      </form>
    </Form>
  );
}
