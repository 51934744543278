import { Button } from "@frontend/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@frontend/components/ui/popover";
import { Separator } from "@frontend/components/ui/separator";
import {
  Table,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@frontend/components/ui/table";

import type { PlateRun } from "./components/plate/types";

const ProgressErrorsPopover = ({ errors }: { errors: PlateRun["errors"] }) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant={"outline"}>View</Button>
      </PopoverTrigger>
      <PopoverContent className="w-[600px] p-4 text-sm">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Type</TableHead>
              <TableHead>Description</TableHead>
              <TableHead>Location</TableHead>
              <TableHead>Mitigation</TableHead>
              <TableHead>Unit</TableHead>
            </TableRow>
          </TableHeader>
          {errors.map((error, index) => (
            <TableRow key={index}>
              <TableCell>{error.type}</TableCell>
              <TableCell>{error.description}</TableCell>
              <TableCell>{error.location}</TableCell>
              <TableCell>{error.mitigation}</TableCell>
              <TableCell>{error.unit}</TableCell>
            </TableRow>
          ))}
        </Table>
      </PopoverContent>
    </Popover>
  );
};

const ProgressWarningPopover = ({ warnings }: { warnings: string[] }) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant={"outline"}>View</Button>
      </PopoverTrigger>
      <PopoverContent className="w-96 text-sm">
        <ul className="flex flex-col">
          {warnings.map((warning) => {
            return (
              <li className="flex flex-row items-start space-x-2" key={warning}>
                <p>{warning}</p>
              </li>
            );
          })}
        </ul>
      </PopoverContent>
    </Popover>
  );
};

export default function RunProgressErrors({
  run,
}: {
  run: PlateRun | undefined | null;
}) {
  if (!run) {
    return null;
  }

  const errors = run?.errors;
  const warnings = run?.warnings;

  return (
    <div className="mt-4 flex w-full items-center justify-between">
      <div className="mr-4 flex w-1/2 items-center">
        <h3 className="mr-2 font-bold">Errors: </h3>
        <span>{errors.length}</span>
        {errors.length > 0 && (
          <div className="ml-auto">
            <ProgressErrorsPopover errors={errors} />
          </div>
        )}
      </div>
      <Separator className="h-6" orientation="vertical" />
      <div className="ml-4 flex w-1/2 items-center">
        <h3 className="mr-2 font-bold">Warnings:</h3>
        <span>{warnings.length}</span>
        {warnings.length > 0 && (
          <div className="ml-auto">
            <ProgressWarningPopover warnings={warnings} />
          </div>
        )}
      </div>
    </div>
  );
}
