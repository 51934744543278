import { RunState } from "@console/shared";
import { useNavigate } from "react-router-dom";

import { RunStateToBadge } from "./runs-table";
import { statusMap } from "./status-map";

import { PlateSizeLabel } from "../../../components/logic/plate";
import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import type { RunOfInstrumentTRPC } from "../../../config/trpc";
import { OrganizationRoutes } from "../../../routes/organization/organization-routes";
import type { InstrumentTRPC } from "../useGetInstrument";

const RunProgressOfInstrument = ({
  run,
}: {
  run: RunOfInstrumentTRPC | undefined;
}) => {
  const navigate = useNavigate();

  if (!run) {
    return <span>Waiting for run ...</span>;
  }

  const kit = run.kitLabel ?? "N/A";
  const plateSize = run.plateSize ? PlateSizeLabel[run.plateSize] : "";
  const filledWells = run.filledWells;

  return (
    <div className="flex w-full flex-col justify-between">
      <div className="w-full space-y-8">
        <div className="flex flex-row items-center justify-between">
          <Button
            className={`flex max-w-[200px] flex-row items-center space-x-3 text-clip text-xl`}
            onClick={() => {
              navigate(
                OrganizationRoutes.ASSAY_WORKFLOW_STEP.replace(
                  ":assayId",
                  run.assayId,
                ).replace(":stepId", run.stepId),
              );
            }}
            variant={"outline"}
          >
            <span className="truncate">{run.stepName}</span>
          </Button>
          <div>{RunStateToBadge[run.state]}</div>
        </div>
        <div>
          <p className="text-muted-foreground text-xl">Details</p>
          <div className="flex flex-wrap">
            <div className="m-1">
              <Badge variant={"outline"}>{kit}</Badge>
            </div>
            <div className="m-1">
              <Badge variant={"outline"}>{`Plate: ${plateSize}`}</Badge>
            </div>
            <div className="m-1">
              <Badge variant={"outline"}>{`Wells: ${filledWells}`}</Badge>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function InstrumentStatusCard({
  instrument,
  runs,
}: {
  instrument: InstrumentTRPC;
  runs: RunOfInstrumentTRPC[];
}) {
  const currentRun = runs
    .filter((run) => run.state !== RunState.Queued)
    .sort(
      (run1, run2) => run2.updatedAt.getTime() - run1.updatedAt.getTime(),
    )[0];

  return (
    <Card className="col-span-1">
      <CardHeader>
        <CardTitle className="flex flex-row justify-between">
          <div className="flex flex-row items-center space-x-4">
            <span>Status</span>
          </div>
          <div className="flex flex-row items-center space-x-4">
            <p data-testid="instrument-status">{instrument.status}</p>
            {statusMap[instrument.status]}
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-row justify-between">
        <RunProgressOfInstrument run={currentRun} />
      </CardContent>
    </Card>
  );
}
