import { Heading } from "@radix-ui/themes";
import type { ColumnDef } from "@tanstack/react-table";
import type { inferProcedureOutput } from "@trpc/server";
import { HousePlus, RefreshCcw, Trash, Trash2 } from "lucide-react";
import { useState } from "react";
import { Link } from "react-router-dom";

import { SelectColumn } from "../../../components/logic/select-column";
import { AlertDialogWrapper } from "../../../components/ui/alert-dialog";
import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import { DataTable } from "../../../components/ui/data-table/data-table";
import { TableActions } from "../../../components/ui/data-table/data-table-actions";
import { DataTableColumnHeader } from "../../../components/ui/data-table/data-table-column-header";
import { arrIncludesSomeWithEmptyFn } from "../../../components/ui/data-table/filters";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import { useToast } from "../../../components/ui/use-toast";
import type { AppRouter } from "../../../config/trpc";
import { trpc } from "../../../config/trpc";
import { AdminRoutes } from "../adminRoutes";
import type { OrganizationDisplay } from "../organizations/organizations-list";

const InstrumentActions = ({ serialNumber }: { serialNumber: string }) => {
  const utils = trpc.useUtils();
  const { mutate: deleteInstrument } =
    trpc.instrument.syntax.delete.useMutation({
      onSuccess() {
        utils.instrument.syntax.listAll.invalidate();
      },
    });

  const handleDeleteInstrument = () => {
    deleteInstrument({
      serialNumber,
    });
  };

  return (
    <TableActions
      items={[
        {
          alertDialog: {
            message:
              "Are you sure you want to delete this instrument ? This action cannot be undone.",
            title: "Delete instrument",
          },
          children: (
            <div className="flex items-center gap-2">
              <Trash /> Delete
            </div>
          ),
          id: "Delete instrument",
          onClick: handleDeleteInstrument,
        },
      ]}
    />
  );
};

const OrganizationLink = ({
  organizationId,
  name,
  instrumentId,
}: {
  instrumentId: string;
  name: string;
  organizationId: string;
}) => {
  const { toast } = useToast();
  const utils = trpc.useUtils();
  const { mutate: deleteOrganization } =
    trpc.instrument.syntax.removeFromOrganization.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Error removing instrument from organization",
          variant: "destructive",
        });
      },
      onSuccess() {
        utils.instrument.syntax.listAll.invalidate();
        toast({
          description: "Instrument removed from organization",
          title: "Success",
          variant: "success",
        });
      },
    });

  return (
    <Badge className="items-center space-x-1" variant={"outline"}>
      <Link
        className="hover:underline"
        to={AdminRoutes.ORGANIZATION_SETTINGS.replace(
          ":organizationId",
          organizationId,
        )}
      >
        {name}
      </Link>
      <AlertDialogWrapper
        description="Are you sure you want to remove this instrument from this organization ?"
        onConfirm={() => deleteOrganization({ instrumentId, organizationId })}
        title="Remove instrument"
      >
        <Button size="xs" variant="ghost">
          <Trash2 />
        </Button>
      </AlertDialogWrapper>
    </Badge>
  );
};

const organizationColumns: ColumnDef<OrganizationDisplay>[] = [
  SelectColumn(({ row }) => (
    <Badge variant={"secondary"}>{row.original?.name}</Badge>
  )),
  {
    accessorKey: "name",
    cell: ({ row }) => row.getValue("name"),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      title: "Name",
    },
  },
];

const SelectOrganizations = ({
  instrumentId,
  existingIds,
}: {
  existingIds: string[];
  instrumentId: string;
}) => {
  const { data: organizations } =
    trpc.account.organization.list.useQuery(undefined);
  const { toast } = useToast();
  const [selectedOrgs, setSelectedOrgs] = useState<string[]>([]);

  const utils = trpc.useUtils();
  const { mutate: addToOrganizations } =
    trpc.instrument.syntax.addToOrganizations.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Error adding instrument to organizations",
          variant: "destructive",
        });
      },
      onSuccess() {
        setSelectedOrgs([]);
        utils.instrument.syntax.listAll.invalidate();
      },
    });

  const handleSubmit = () => {
    if (!selectedOrgs.length) {
      return;
    }
    addToOrganizations({
      instrumentId,
      organizationIds: selectedOrgs,
    });
  };

  return (
    <div>
      <DataTable
        columns={organizationColumns}
        data={organizations ?? []}
        disableQueryParams
        enableRowSelection={(row) => !existingIds.includes(row.id)}
        getRowId={(row) => row.id}
        onSelectionChange={(selectedRows) => {
          setSelectedOrgs(selectedRows.map((s) => s.id));
        }}
        tableContainerClassName="max-h-80 overflow-scroll"
        useBorders={false}
      />
      <div className="flex justify-end">
        <Button onClick={handleSubmit}>Submit</Button>
      </div>
    </div>
  );
};

const AddToOrganization = ({
  instrumentId,
  instrumentSN,
  existingIds,
}: {
  existingIds: string[];
  instrumentId: string;
  instrumentSN: string;
}) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button size={"sm"} variant={"outline"}>
          <HousePlus />
        </Button>
      </DialogTrigger>
      <DialogContent className="min-w-[80vw]">
        <DialogHeader>
          <DialogTitle>
            {`Choose organizations to add ${instrumentSN} to`}
          </DialogTitle>
        </DialogHeader>
        <SelectOrganizations
          existingIds={existingIds}
          instrumentId={instrumentId}
        />
      </DialogContent>
    </Dialog>
  );
};

type SyntaxInstrument = inferProcedureOutput<
  AppRouter["instrument"]["syntax"]["listAll"]
>[number];

const columns: ColumnDef<SyntaxInstrument>[] = [
  {
    accessorFn: (row) => row.serialNumber,
    cell: ({ row }) => row.original.serialNumber,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "serialNumber",
    meta: {
      title: "Serial number",
    },
  },
  {
    accessorFn: (row) => row.organizations.map((org) => org.name),
    cell: (info) => {
      return (
        <div className="relative space-x-1 space-y-1">
          {info.row.original.organizations.map((org) => (
            <OrganizationLink
              instrumentId={info.row.original.id}
              key={org.id}
              name={org.name}
              organizationId={org.id}
            />
          ))}
          <AddToOrganization
            existingIds={info.row.original.organizations.map((org) => org.id)}
            instrumentId={info.row.original.id}
            instrumentSN={info.row.original.serialNumber}
          />
        </div>
      );
    },
    filterFn: arrIncludesSomeWithEmptyFn,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "organizations",
    meta: {
      title: "Organizations",
    },
  },
  {
    accessorFn: (row) => row.softwareVersion,
    cell: ({ row }) => row.original.softwareVersion,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "softwareVersion",
    meta: {
      title: "Software version",
    },
  },
  {
    cell: (info) => (
      <InstrumentActions serialNumber={info.row.original.serialNumber} />
    ),
    id: "actions",
  },
];

export default function AllInstruments() {
  const { data, refetch, isPending } =
    trpc.instrument.syntax.listAll.useQuery();

  const handleRefetch = () => {
    refetch();
  };

  return (
    <div className="p-2">
      <div className="m-2 flex items-center justify-between">
        <Heading>Instruments dashboard</Heading>
        <Button
          className="space-x-1"
          disabled={isPending}
          onClick={handleRefetch}
          type="button"
        >
          <RefreshCcw />
          <span>Refresh</span>
        </Button>
      </div>
      <DataTable columns={columns} data={data ?? []} />
    </div>
  );
}
