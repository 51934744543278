import { maxOligoLength, minimumOligosPerPlate } from "@console/shared";
import { ShoppingBasket, SaveIcon } from "lucide-react";
import { useState } from "react";

import { Button } from "../../../../../components/ui/button";
import { Checkbox } from "../../../../../components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../../components/ui/dialog";
import { Input } from "../../../../../components/ui/input";
import { Label } from "../../../../../components/ui/label";
import UploadExcelFile from "../../../../../components/ui/upload-excel-file";
import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";
import { useGetUIAuthorizedCreations } from "../../../../../containers/user/hooks";
import { arrayBufferTobase64 } from "../../../../../utils/useDownload";

export default function NewOrder() {
  const { toast } = useToast();
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [hubspotQuoteId, setHubspotDealId] = useState<string | null>(null);
  const [allowBiosecurityErrors, setAllowBiosecurityErrors] = useState(false);
  const [allowLongerThanSizeLimit, setallowLongerThanSizeLimit] =
    useState(false);
  const [allowLessThanPlateMin, setAllowLessThanPlateMin] = useState(false);
  const {
    order: { create: canCreateOrder },
  } = useGetUIAuthorizedCreations();

  const utils = trpc.useUtils();
  const { mutate: createOrderRequest, isPending } =
    trpc.order.create.useMutation({
      onError(error) {
        setOpen(false);
        toast({
          description: (
            <p>
              <span>An error occurred while creating the order</span>
              <span>{error.message}</span>
            </p>
          ),
          title: "Error",
          variant: "destructive",
        });
      },
      onSuccess() {
        toast({
          description: "The order was successfully created",
          title: "Order created",
          variant: "success",
        });
        setOpen(false);
        utils.order.list.invalidate();
      },
    });

  const handleSubmit = () => {
    if (!file || !hubspotQuoteId) {
      return;
    }
    const submit = async () => {
      const arrayBuffer = await file.arrayBuffer();
      const base64 = arrayBufferTobase64(arrayBuffer);
      createOrderRequest({
        excelBase64: base64,
        hubspotQuoteId,
        options: {
          allowBiosecurityErrors,
          allowLessThanPlateMin,
          allowLongerThanSizeLimit,
        },
      });
    };

    submit();
  };

  if (!canCreateOrder) {
    return null;
  }

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <Button className="flex flex-row items-center space-x-1">
          <span>New order</span>
          <ShoppingBasket />
        </Button>
      </DialogTrigger>
      <DialogContent className="min-w-[700px]">
        <DialogHeader>
          <DialogTitle>Create new order</DialogTitle>
        </DialogHeader>
        <UploadExcelFile file={file} setFile={setFile} />
        <div>
          <Label htmlFor="hubspotDealID">Hubspot quote Id</Label>
          <Input
            id="hubspotDealID"
            onChange={(e) => setHubspotDealId(e.target.value)}
            required
            value={hubspotQuoteId ?? ""}
          />
        </div>
        <h3 className="font-semibold">Options</h3>
        <div className="flex flex-row items-center space-x-1">
          <Label htmlFor="allowBiosecurityErrors">
            Allow biosecurity errors
          </Label>
          <Checkbox
            checked={allowBiosecurityErrors}
            id="allowBiosecurityErrors"
            onCheckedChange={(v) => setAllowBiosecurityErrors(Boolean(v))}
          />
        </div>
        <div className="flex flex-row items-center space-x-1">
          <Label htmlFor="allowLongerThanSizeLimit">
            {`Allow longer than ${maxOligoLength} nt`}
          </Label>
          <Checkbox
            checked={allowLongerThanSizeLimit}
            id="allowLongerThanSizeLimit"
            onCheckedChange={(v) => setallowLongerThanSizeLimit(Boolean(v))}
          />
        </div>
        <div className="flex flex-row items-center space-x-1">
          <Label htmlFor="allowLessThanPlateMin">
            {`Allow ordering on a plate with less than ${minimumOligosPerPlate} oligos`}
          </Label>
          <Checkbox
            checked={allowLessThanPlateMin}
            id="allowLessThanPlateMin"
            onCheckedChange={(v) => setAllowLessThanPlateMin(Boolean(v))}
          />
        </div>
        <div className="flex flex-row justify-end space-x-2">
          <Button
            disabled={!file || !hubspotQuoteId}
            isLoading={isPending}
            onClick={handleSubmit}
          >
            <SaveIcon />
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
