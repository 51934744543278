import type { ColumnDef, RowData } from "@tanstack/react-table";
import { ChevronDown, ChevronRight } from "lucide-react";

export function ExpandColumn<T extends RowData>(): ColumnDef<T> {
  return {
    cell: ({ row }) => {
      return row.getCanExpand() ? (
        <button
          onClick={(ev) => {
            ev.stopPropagation();
            row.getToggleExpandedHandler()();
          }}
          style={{ cursor: "pointer" }}
        >
          {row.getIsExpanded() ? <ChevronDown /> : <ChevronRight />}
        </button>
      ) : (
        ""
      );
    },
    header: ({ table }) => (
      <button
        onClick={table.getToggleAllRowsExpandedHandler()}
        style={{ cursor: "pointer" }}
      >
        {table.getIsAllRowsExpanded() ? <ChevronDown /> : <ChevronRight />}
      </button>
    ),
    id: "expander",
    size: 30,
  };
}
