import type { RowData } from "@tanstack/react-table";
import { PlusSquareIcon, Trash2Icon } from "lucide-react";
import { useMemo } from "react";

import type { Oligo } from "./types";
import UploadSequences from "./upload-sequences";
import {
  columnInitiatorDNAId,
  columnSequenceId,
  getOligoColumns,
} from "./useGetOligoColumns";
import { useScrollRowAndFocusName } from "./useScrollRowAndFocusName";

import { Button } from "../../../../../components/ui/button";
import { DataTable } from "../../../../../components/ui/data-table/data-table";
import { parseInitiatorDNASequence } from "../../../../../utils/parser";
import Templates from "../../../../request-form/templates";

declare module "@tanstack/react-table" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    deleteData?: (rowIndex: number) => void;
    placementToRows?: Map<string, number[]>;
    updateData?: (rowIndex: number, columnId: string, value: unknown) => void;
  }
}

type OligoSetProps = {
  data: Oligo[];
  isLocked?: boolean;
  setData: React.Dispatch<React.SetStateAction<Oligo[]>>;
};

export default function OligoSet({
  isLocked = false,
  data,
  setData,
}: OligoSetProps) {
  useScrollRowAndFocusName(data);

  const addRow = () => {
    setData((old) => [
      ...old,
      { locked: false, name: "", placement: null, sequence: "" },
    ]);
  };

  const deleteAll = () => {
    setData((old) => old.filter((o) => o.locked));
  };

  const containsInitiatorDNA = useMemo(
    () => data.some((oligo) => oligo.sequence.split("+").length > 1),
    [data],
  );
  const columns = useMemo(
    () => getOligoColumns(containsInitiatorDNA),
    [containsInitiatorDNA],
  );

  const placementToRows = useMemo(() => {
    const mapOfPlacements = new Map<string, number[]>();
    data.forEach((oligo, oligoIndex) => {
      const oligoHint = oligo.placement;
      if (!oligoHint) {
        return;
      }
      mapOfPlacements.set(
        oligoHint,
        (mapOfPlacements.get(oligoHint) || [])?.concat([oligoIndex]),
      );
    });
    return mapOfPlacements;
  }, [data]);

  return (
    <div className="space-y-4 [&_td]:p-1">
      <div className="flex flex-row items-center justify-end space-x-4">
        <UploadSequences
          onChange={(parsedData) =>
            setData((oldData) => [
              ...oldData,
              ...parsedData.map((row) => {
                return {
                  locked: false,
                  name: row.Name,
                  placement: row.Well || null,
                  sequence: row.Sequence,
                };
              }),
            ])
          }
        />
        <Templates
          templates={[
            {
              downloadName: "template.fasta",
              file: "/fastaTemplate.fasta",
              label: "Fasta",
            },
            {
              downloadName: "template-96.csv",
              file: "/csvTemplate.csv",
              label: "CSV",
            },
            {
              downloadName: "template-384.csv",
              file: "/csvTemplateHX.csv",
              label: "CSV 384",
            },
            {
              downloadName: "template.xlsx",
              file: "/excelTemplate.xlsx",
              label: "XLS",
            },
          ]}
        />
      </div>
      <DataTable
        autoResetPageIndex={false}
        columns={columns}
        data={data}
        meta={{
          defaultPageSize: 96,
          deleteData: (rowIndex: number) => {
            // Skip page index reset until after next rerender
            setData((old) => old.filter((_, index) => index !== rowIndex));
          },
          paginationSizes: [96, 384],
          placementToRows,
          updateData: (rowIndex: number, columnId: string, value: any) => {
            // Skip page index reset until after next rerender
            setData((old) =>
              old.map((row, index) => {
                if (index === rowIndex) {
                  if (columnId === columnInitiatorDNAId) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const [_, sequence] = row.sequence.split("+");
                    return {
                      ...old[rowIndex]!,
                      [columnSequenceId]: `${value}+${sequence}`,
                    };
                  }
                  if (columnId === columnSequenceId) {
                    const { initiatorSequence } = parseInitiatorDNASequence(
                      row.sequence,
                    );
                    return {
                      ...old[rowIndex]!,
                      [columnSequenceId]: `${initiatorSequence ? `${initiatorSequence}+` : ""}${value}`,
                    };
                  }
                  return {
                    ...old[rowIndex]!,
                    [columnId]: value,
                  };
                }
                return row;
              }),
            );
          },
        }}
      />
      <div className="flex flex-row items-center space-x-2">
        <Button
          className="space-x-2"
          disabled={isLocked}
          onClick={addRow}
          type="button"
          variant={"outline"}
        >
          <span>New oligo</span>
          <PlusSquareIcon />
        </Button>
        <Button
          className="space-x-2"
          disabled={isLocked}
          onClick={deleteAll}
          type="button"
          variant={"outline"}
        >
          <span>Delete all</span>
          <Trash2Icon />
        </Button>
      </div>
    </div>
  );
}
