import { Button } from "@frontend/components/ui/button";
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@frontend/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@frontend/components/ui/popover";
import { ChevronDown } from "lucide-react";
import { useState } from "react";

export default function SelectWithSearch({
  options,
  value,
  setValue,
  inputTitle = "Select ...",
}: {
  inputTitle?: string;
  options: { id: string; name: string }[];
  setValue: (newValue: string) => void;
  value?: string;
}) {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");

  return (
    <Popover modal onOpenChange={setOpen} open={open}>
      <PopoverTrigger asChild>
        <Button
          aria-expanded={open}
          className="h-10 w-96 max-w-[500px] justify-between p-1"
          role="combobox"
          variant="outline"
        >
          <div className="flex flex-wrap">
            {value ? (
              <div className="m-1" key={value}>
                <span>
                  {options?.find((option) => option.id === value)?.name ??
                    "Unknown"}
                </span>
              </div>
            ) : (
              <span>{inputTitle}</span>
            )}
          </div>

          <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="max-h-[300px] w-96 overflow-y-scroll p-0">
        <Command>
          <CommandInput
            onValueChange={setSearch}
            placeholder="Search..."
            value={search}
          />
          <CommandGroup className="">
            {options.map((option) => (
              <CommandItem
                key={option.id}
                onSelect={() => {
                  setValue(option.id);
                  setOpen(false);
                }}
                value={option.id}
              >
                <span>{option.name}</span>
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
