import { Normalization } from "@console/shared";

import { ConcentrationSlider } from "../../../../../../components/ui/concentration-slider";
import { Input } from "../../../../../../components/ui/input";

type TargetConcentrationProps = {
  handleApplyConcentrationChange: (
    normalization: Normalization,
    concentration?: number,
  ) => void;
  isEditable: boolean;
  localConcentration: number[];
  maxConcentration: number;
  minConcentration: number;
  setLocalConcentration: (v: number[]) => void;
};

const getConcentration = (
  v: number,
  maxConcentration: number,
  minConcentration: number,
) => {
  if (isNaN(v)) {
    return minConcentration;
  }
  if (v < minConcentration) {
    return minConcentration;
  }
  if (v > maxConcentration) {
    return maxConcentration;
  }
  return v;
};

export default function TargetConcentration({
  handleApplyConcentrationChange,
  isEditable,
  localConcentration,
  setLocalConcentration,
  maxConcentration,
  minConcentration,
}: TargetConcentrationProps) {
  const handleInputConcentrationChange = (concentration: number) => {
    handleApplyConcentrationChange(
      Normalization.Target,
      getConcentration(concentration, maxConcentration, minConcentration),
    );
  };

  return (
    <div className="grid grid-cols-4 gap-2">
      <ConcentrationSlider
        className="col-span-3"
        defaultValue={[6]}
        disabled={!isEditable}
        id="targetConcentration"
        max={maxConcentration}
        min={minConcentration}
        onValueChange={(v) => {
          setLocalConcentration(v);
        }}
        onValueCommit={(v) => {
          handleApplyConcentrationChange(Normalization.Target, v[0]);
        }}
        step={0.1}
        value={localConcentration}
      />
      <Input
        className="col-span-1"
        disabled={!isEditable}
        max={maxConcentration}
        min={minConcentration}
        onBlur={() => {
          handleInputConcentrationChange(localConcentration[0]);
        }}
        onChange={(e) => {
          setLocalConcentration([parseFloat(e.target.value)]);
        }}
        step={0.1}
        type="number"
        value={localConcentration[0]}
      />
    </div>
  );
}
